import React from 'react'
import { FAQItem } from '../FAQItem'
import { EXTERNAL_LINKS } from '../../constants/external-links'
import { useSelector } from 'react-redux'
import { selectCanSubscribeWithReferralDiscount } from '../../state/slices/account-slice.ts' // Adjust the import path as needed

export const PricingFaqItems: React.FC = () => {
  const hasReferralDiscount = useSelector(selectCanSubscribeWithReferralDiscount)

  const pricingFaqItems = [
    {
      question: 'How do I get a free trial?',
      answer: 'You can get a free trial by signing up to our Free Trial plan.',
    },
    {
      question: 'Is the payment service secure?',
      answer:
        'Yes, our payment service is secure. We use Stripe for financial processing, and we do not store any information about your card. Stripe ensures bank-level security standards.',
    },
    {
      question: 'Can I upgrade or downgrade my subscription?',
      answer:
        "Yes, you can upgrade or downgrade your subscription at any time. Go to the top right of the app when you are logged in, click on your profile image, open the Account section in the menu and click Manage Subscription, you will be redirected to Stripe's billing portal where you can switch plans.",
    },
    {
      question: 'Can I cancel my subscription anytime?',
      answer:
        "You can cancel your subscription at any time. Go to the Account section when you are logged in and click on Manage Subscription, you will be redirected to Stripe's billing portal where you can download invoices, switch plans and cancel your subscription.",
    },
    {
      question: 'Can I get a refund?',
      answer: (
        <>
          Yes, you can get a refund within 7 days for a monthly subscription, and within 21 days for a yearly
          subscription. Please read more about our refund policy here:{' '}
          <a
            href={EXTERNAL_LINKS.REFUND_POLICY}
            target='_blank'
            rel='noopener noreferrer'
            className='text-indigo-600 underline hover:text-indigo-800'
          >
            Refund Policy
          </a>
        </>
      ),
    },
  ]

  // currently people who have a referral discount are not able to see the free trial card
  const pricingFaqItemsToShow = hasReferralDiscount ? pricingFaqItems.slice(1) : pricingFaqItems

  return (
    <>
      {pricingFaqItemsToShow.map((item, index) => (
        <FAQItem
          key={index}
          question={item.question}
          answer={item.answer}
          isLast={index === pricingFaqItemsToShow.length - 1}
        />
      ))}
    </>
  )
}
