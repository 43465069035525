import { Navigate, Route, Routes } from 'react-router-dom'

import { ROUTE_PATHS } from './route-paths.ts'
import { CloneVoiceView } from '../components/onboarding/steps/clone-voice/clone-voice-view.tsx'
import { OnboardingSuccessView } from '../components/onboarding/steps/onboarding-success/onboarding-success-view.tsx'
import { SignInUp } from '../components/auth/sign-in-up/sign-in-up.tsx'
import { ProtectedRoute } from '../components/auth/protected-route.tsx'
import { ChooseMotherLanguageView } from '../components/onboarding/steps/choose-language/choose-mother-language-view.tsx'
import { ChooseStudyLanguageView } from '../components/onboarding/steps/choose-language/choose-study-language-view.tsx'
import { TermsAndConditionsView } from '../components/onboarding/steps/terms-and-conditions-view.tsx'
import { RandomExerciseView } from '../components/exercises/random-exercise-view/random-exercise-view.tsx'
import { CustomExerciseView } from '../components/exercises/custom-exercise-view/custom-exercise-view.tsx'
import { ChooseDialectView } from '../components/onboarding/steps/choose-dialect/choose-dialect-view.tsx'
import { PricingView } from '../components/pricing/pricing-view.tsx'
import { AccountRemovedSuccessView } from '../components/auth/account-removed-success-view.tsx'
import { AdminSettings } from '../components/views/admin-settings.tsx'
import { RedirectToCheckOut } from '../components/redirect-to-check-out.tsx'
import { ProgressView } from '../components/progress/progress-view.tsx'
import { NewRegistrationsClosedCurrentlyView } from '../components/onboarding/steps/new-users-not-admitted.tsx'
import { getConfig } from '../config/environment-config.ts'
import { FromLanding } from '../components/auth/from-landing.tsx'
import { FrequencyListExerciseView } from '../components/exercises/frequency-list-exercise-view/frequency-list-exercise-view.tsx'
import { SignInUpEmail } from '../components/auth/sign-in-up/sign-in-up-email.tsx'
import { SignInUpEmailVerificationSentView } from '../components/auth/sign-in-up/sign-in-up-email-verification-sent-view.tsx'
import { SignInUpEmailVerify } from '../components/auth/sign-in-up/sign-in-up-email-verify.tsx'
import { RequireCreditCardForReferralsRoute } from '../components/auth/payment/require-credit-card-for-referrals-route.tsx'
import { CheckoutSuccessView } from '../components/checkout/checkout-success-view'
import { DashboardView } from '../components/dashboard/dashboard-view.tsx'

export const Router = () => {
  return (
    <Routes>
      <Route path={ROUTE_PATHS.FROM_LANDING} element={<FromLanding />} />

      <Route path={ROUTE_PATHS.ACCOUNT_REMOVED} element={<AccountRemovedSuccessView />} />
      <Route path={ROUTE_PATHS.ADMIN_SETTINGS} element={<AdminSettings />} />

      <Route path={ROUTE_PATHS.SIGN_IN} element={<SignInUp isSignIn={true} />} />
      <Route path={ROUTE_PATHS.SIGN_UP} element={<SignInUp isSignIn={false} />} />
      <Route path={ROUTE_PATHS.SIGN_IN_EMAIL} element={<SignInUpEmail isSignIn={true} />} />
      <Route path={ROUTE_PATHS.SIGN_UP_EMAIL} element={<SignInUpEmail isSignIn={false} />} />
      <Route
        path={ROUTE_PATHS.SIGN_IN_EMAIL_VERIFICATION_SENT}
        element={<SignInUpEmailVerificationSentView isSignIn={true} />}
      />
      <Route
        path={ROUTE_PATHS.SIGN_UP_EMAIL_VERIFICATION_SENT}
        element={<SignInUpEmailVerificationSentView isSignIn={false} />}
      />
      <Route path={ROUTE_PATHS.SIGN_IN_UP_EMAIL_VERIFY} element={<SignInUpEmailVerify />} />
      <Route path={ROUTE_PATHS.CHECKOUT_SUCCESS} element={<CheckoutSuccessView />} />

      <Route element={<ProtectedRoute />}>
        <Route path={ROUTE_PATHS.PRICING} element={<PricingView />} />
        <Route path={ROUTE_PATHS.REDIRECT_TO_CHECK_OUT} element={<RedirectToCheckOut />} />
        <Route element={<RequireCreditCardForReferralsRoute />}>
          {getConfig().featureFlags.isShouldNotAdmitNewUsersEnabled() && (
            <Route
              path={ROUTE_PATHS.ONBOARDING_NEW_REGISTRATIONS_CLOSED_CURRENTLY}
              element={<NewRegistrationsClosedCurrentlyView />}
            />
          )}
          <Route path={ROUTE_PATHS.ONBOARDING_CHOOSE_MOTHER_LANGUAGE} element={<ChooseMotherLanguageView />} />
          <Route path={ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE} element={<ChooseStudyLanguageView />} />
          <Route path={ROUTE_PATHS.ONBOARDING_CHOOSE_DIALECT} element={<ChooseDialectView />} />
          <Route path={ROUTE_PATHS.ONBOARDING_TERMS_AND_CONDITIONS} element={<TermsAndConditionsView />} />
          <Route path={ROUTE_PATHS.ONBOARDING_CLONE_VOICE} element={<CloneVoiceView />} />
          <Route path={ROUTE_PATHS.ONBOARDING_SUCCESS} element={<OnboardingSuccessView />} />

          <Route path={ROUTE_PATHS.DASHBOARD} element={<DashboardView />} />
          <Route path={ROUTE_PATHS.RANDOM_EXERCISE} element={<RandomExerciseView />} />
          <Route path={ROUTE_PATHS.CUSTOM_EXERCISE} element={<CustomExerciseView />} />
          <Route path={ROUTE_PATHS.FREQUENCY_LIST_EXERCISE} element={<FrequencyListExerciseView />} />

          <Route path={ROUTE_PATHS.PROGRESS} element={<Navigate replace to={ROUTE_PATHS.PROGRESS_STREAK} />} />
          <Route path={ROUTE_PATHS.PROGRESS_STREAK} element={<ProgressView />} />
          <Route path={ROUTE_PATHS.PROGRESS_BADGES_ALL} element={<ProgressView />} />
          <Route path={ROUTE_PATHS.PROGRESS_BADGES_STREAK} element={<ProgressView />} />
          <Route path={ROUTE_PATHS.PROGRESS_BADGES_WORDS} element={<ProgressView />} />
          <Route path={ROUTE_PATHS.PROGRESS_BADGES_LANGUAGES} element={<ProgressView />} />
          <Route
            path={ROUTE_PATHS.PROGRESS_BADGES}
            element={<Navigate replace to={ROUTE_PATHS.PROGRESS_BADGES_ALL} />}
          />
          <Route path={ROUTE_PATHS.PROGRESS_STATS_LEARNED_WORDS} element={<ProgressView />} />
          <Route path={ROUTE_PATHS.PROGRESS_STATS_SAVED_WORDS} element={<ProgressView />} />
          <Route path={ROUTE_PATHS.PROGRESS_STATS} element={<ProgressView />} />

          <Route path={'/'} element={<Navigate replace to={ROUTE_PATHS.DASHBOARD} />} />
        </Route>
        <Route path={'*'} element={<Navigate replace to={ROUTE_PATHS.DASHBOARD} />} />
      </Route>
    </Routes>
  )
}
