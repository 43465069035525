export const customFetch = async (input: string | URL | globalThis.Request, init?: RequestInit): Promise<Response> => {
  const response = await fetch(input, init)
  if (!response.ok) {
    throw new ApiError(`An error occurred: ${response.status}`, response.status, await response.json())
  }
  return response
}

export class ApiError extends Error {
  status: number
  body: unknown

  constructor(message: string, status: number, body: unknown) {
    super(message)
    this.status = status
    this.name = 'ApiError'
    this.body = body
  }
}
