import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { Button } from '../design-system/button.tsx'
import { modalActions } from '../../state/slices/modal-slice.ts'
import { ACCOUNT_MODAL_ID, UPGRADE_PLAN_MODAL_ID } from '../modal/modal-ids.ts'
import { getSubscriptionDetails } from '../../transport/transport/api/payments/subscription-account.ts'
import {
  selectAccountAccessToken,
  selectCanSubscribeWithReferralDiscount,
  selectHasFinishedOnboarding,
} from '../../state/slices/account-slice.ts'
import { Clock } from 'lucide-react'
import { QueryKey } from '../../transport/transport/api/query-keys.ts'
import { getRemainingTrialDays } from '../../utils/payment-utils.ts'
import { getConfig } from '../../config/environment-config.ts'

export const TrialCountdownButton = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccountAccessToken)
  const hasFinishedOnboarding = useSelector(selectHasFinishedOnboarding)
  const canSubscribeWithReferralDiscount = useSelector(selectCanSubscribeWithReferralDiscount)

  const { data: subscriptionData } = useQuery({
    queryKey: [QueryKey.SUBSCRIPTION_DETAILS, accessToken],
    queryFn: () => getSubscriptionDetails(accessToken),
    enabled: !!accessToken && hasFinishedOnboarding,
  })

  const subscriptionInfo = subscriptionData?.data
  const subscriptionDetails = subscriptionInfo?.details
  const isSpecialUserWithFullAccess = !!subscriptionInfo?.isSpecialUserWithFullAccess
  const remainingDays = getRemainingTrialDays(subscriptionDetails?.trialEnd ?? null)
  const plan = subscriptionDetails?.lastActivePlan
  const cancelAtPeriodEnd = subscriptionDetails?.cancelAtPeriodEnd

  const handleClick = () => {
    if (plan === 'free_trial') {
      dispatch(modalActions.openModal(UPGRADE_PLAN_MODAL_ID))
    } else {
      dispatch(modalActions.openModal(ACCOUNT_MODAL_ID))
    }
  }

  if (!cancelAtPeriodEnd && plan !== 'free_trial') {
    return null
  }

  return (
    <div>
      {canSubscribeWithReferralDiscount ||
      isSpecialUserWithFullAccess ||
      remainingDays === 0 ||
      !getConfig().featureFlags.isFreeTrialEnabled() ? null : (
        <Button
          onClick={handleClick}
          className='flex h-10 items-center gap-x-2 whitespace-nowrap bg-amber-500 px-2 py-1 text-xs text-white drop-shadow-sm transition-colors duration-200 sm:px-3 sm:py-2 sm:text-sm'
        >
          <Clock size={18} className='hidden md:block' />
          <span>
            Trial ends in {remainingDays} day{remainingDays !== 1 ? 's' : ''}
          </span>
        </Button>
      )}
    </div>
  )
}
