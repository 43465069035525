import {
  DialectCode,
  LangCode,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { LOCAL_STORAGE_CONSTANTS } from './local-storage-constants.ts'

export const localStorageWrapper = {
  setStudyLanguage: (language: LangCode | null): void => {
    if (language) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.STUDY_LANGUAGE, language || '')
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.STUDY_LANGUAGE)
    }
  },
  getStudyLanguage: (): SupportedStudyLanguage | null => {
    const lang: string | null = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.STUDY_LANGUAGE)
    if (!lang) {
      return null
    }
    return lang as SupportedStudyLanguage
  },
  setMotherLanguage: (language: LangCode | null): void => {
    if (language) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.MOTHER_LANGUAGE, language || '')
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.MOTHER_LANGUAGE)
    }
  },
  getMotherLanguage: (): LangCode | null => {
    const lang: string | null = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.MOTHER_LANGUAGE)
    if (!lang) {
      return null
    }
    return lang as LangCode
  },
  setDialect: (dialect: DialectCode | null): void => {
    if (dialect) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.DIALECT, dialect || '')
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.DIALECT)
    }
  },
  getDialect: (): DialectCode | null => {
    const dialect: string | null = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.DIALECT)
    if (!dialect) {
      return null
    }
    return dialect as DialectCode
  },
  setReferral: (referral: string): void => {
    if (referral) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.REFERRAL, referral)
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.REFERRAL)
    }
  },
  getReferral: (): string => {
    const referral: string | null = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.REFERRAL)
    if (!referral) {
      return ''
    }
    return referral
  },
  setShouldShowIpa: (shouldShowIpa: boolean): void => {
    if (shouldShowIpa) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_IPA, shouldShowIpa.toString())
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_IPA)
    }
  },
  getShouldShowIpa: (): boolean => {
    const shouldShowIpa: string | null = localStorage.getItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_IPA)
    return !!shouldShowIpa
  },
  setShouldShowTransliteration: (shouldShowIpa: boolean): void => {
    if (shouldShowIpa) {
      localStorage.setItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_TRANSLITERATION, shouldShowIpa.toString())
    } else {
      localStorage.removeItem(LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_TRANSLITERATION)
    }
  },
  getShouldShowTransliteration: (): boolean => {
    const shouldShowTransliteration: string | null = localStorage.getItem(
      LOCAL_STORAGE_CONSTANTS.SHOULD_SHOW_TRANSLITERATION
    )
    return !!shouldShowTransliteration
  },
}
