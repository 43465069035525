import { useEffect, useState } from 'react'
import { Check, Circle } from 'lucide-react'
import { useSelector } from 'react-redux'
import {
  selectAccountAccessToken,
  selectCanSubscribeWithReferralDiscount,
  selectHasFinishedOnboarding,
  selectHasReferral,
  selectLifetimeDiscountPercentage,
  selectLifetimePrice,
  selectMonthlyDiscountPercentage,
  selectMonthlyDurationLimit,
  selectMonthlyPrice,
  selectYearlyDiscountPercentage,
  selectYearlyDurationLimit,
  selectYearlyPrice,
} from '../../state/slices/account-slice'
import { getConfig } from '../../config/environment-config'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createCustomerPortalSession,
  getSubscriptionDetails,
} from '../../transport/transport/api/payments/subscription-account'
import { QueryKey } from '../../transport/transport/api/query-keys.ts'
import { POSTHOG_EVENTS } from '../../analytics/posthog/posthog-events.ts'
import { useCheckoutMutation } from '../../hooks/use-checkout-mutation.ts'
import { ROUTE_PATHS } from '../../routing/route-paths'
import { PricingFaqItems } from './pricing-faq-items'
import { Button } from '../design-system/button.tsx'
import { Card } from '../design-system/card.tsx'
import { NUMBER_OF_DAYS_IN_FREE_TRIAL } from '../../constants/constants-config.ts'
import { getMonthlyDiscountString, getYearlyDiscountString } from './pricing-view-utils.ts'
import { toast } from 'sonner'
import { logWithRollbar } from '../../analytics/rollbar/log-with-rollbar.ts'
import { clsx } from 'clsx'
import { PlanType } from '@shared/frontend-and-backend/body-types/payments/subscription-account.types.ts'
import { useNavigate } from 'react-router-dom'

type PlanOption = {
  label: string
  value: PlanType
  priceMessage: string
  discountMessage: string | null
  additionalMessage?: string
  billedYearly?: string
}

const LeftPartOfButton = ({ option, isChosen }: { option: PlanOption; isChosen: boolean }) => {
  const desktopVersion = (
    <div className='hidden items-center md:flex'>
      <div className='mr-2'>
        {isChosen ? (
          <Circle className='h-5 w-5 fill-indigo-600 text-indigo-600' />
        ) : (
          <Circle className='h-5 w-5 text-gray-400' />
        )}
      </div>
      <div>
        <span>{option.label}</span>
        {option.additionalMessage && (
          <span className='ml-2 rounded-full bg-indigo-100 px-2 py-1 text-sm font-medium text-indigo-800'>
            {option.additionalMessage}
          </span>
        )}
      </div>
    </div>
  )

  const mobileVersion = (
    <div className='flex flex-col items-start gap-y-2 md:hidden'>
      <div className='flex items-center'>
        <div className='mr-2'>
          {isChosen ? (
            <Circle className='h-5 w-5 fill-indigo-600 text-indigo-600' />
          ) : (
            <Circle className='h-5 w-5 text-gray-400' />
          )}
        </div>
        <span>{option.label}</span>
      </div>
      {option.additionalMessage && (
        <span className='rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-800'>
          {option.additionalMessage}
        </span>
      )}
    </div>
  )

  return (
    <>
      {desktopVersion}
      {mobileVersion}
    </>
  )
}

export const PricingView = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const [planType, setPlanType] = useState<PlanType>('year')
  const monthlyPrice = useSelector(selectMonthlyPrice)
  const yearlyPrice = useSelector(selectYearlyPrice)
  const monthlyDiscountPercentage = useSelector(selectMonthlyDiscountPercentage)
  const yearlyDiscountPercentage = useSelector(selectYearlyDiscountPercentage)
  const lifetimePrice = useSelector(selectLifetimePrice)
  const lifetimeDiscountPercentage = useSelector(selectLifetimeDiscountPercentage)
  const canSubscribeWithReferralDiscount = useSelector(selectCanSubscribeWithReferralDiscount)
  const monthlyDurationLimit = useSelector(selectMonthlyDurationLimit)
  const yearlyDurationLimit = useSelector(selectYearlyDurationLimit)
  const hasReferral = useSelector(selectHasReferral)
  const navigate = useNavigate()
  const hasFinishedOnboarding = useSelector(selectHasFinishedOnboarding)

  const handlePlanOptionClick = (planType: PlanType) => {
    POSTHOG_EVENTS.clickPlan('plan_radio_button', planType)
    if (hasIntroducedCardDetails) {
      if (planType === 'free_trial') {
        toast.info('You have already used your free trial.')
      } else {
        toast.info('To change your current plan type, please click on "Manage Subscription".')
      }
    } else {
      setPlanType(planType)
    }
  }

  const customerPortalMutation = useMutation({
    mutationFn: ({ returnPath }: { returnPath: string }) => createCustomerPortalSession(accessToken, returnPath),
    onSuccess: (data) => {
      if (data.data?.url) {
        window.location.href = data.data.url
      }
    },
    onError: (error) => {
      toast.error(
        'Unable to access your billing section at the moment. Please try again later or contact support if the issue persists.'
      )
      logWithRollbar(`Error creating customer portal session: ${error}`)
    },
  })
  const { data: subscriptionData } = useQuery({
    queryKey: [QueryKey.SUBSCRIPTION_DETAILS, accessToken],
    queryFn: () => getSubscriptionDetails(accessToken),
    enabled: !!accessToken,
  })

  const subscriptionInfo = subscriptionData?.data
  const details = subscriptionInfo?.details
  const currentActivePlan = details?.currentActivePlan
  const hasUsedFreeTrial = currentActivePlan !== 'free_trial'

  const shouldShowStartButton = () => {
    if (hasReferral) {
      if (isSubscribedToMonthlyPlan || isSubscribedToYearlyPlan) {
        return true
      }
    } else {
      if (
        isSubscribedToMonthlyPlan ||
        isSubscribedToYearlyPlan ||
        (isSubscribedToFreeTrial && planType === 'free_trial' && getConfig().featureFlags.isFreeTrialEnabled())
      ) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (currentActivePlan) {
      if (hasReferral) {
        // referral users should never see free_trial plan
        if (currentActivePlan !== 'free_trial') {
          setPlanType(currentActivePlan)
        }
      } else {
        // non referral users should not see free_trial if the feature flag is disabled
        if (currentActivePlan === 'free_trial' && hasReferral && getConfig().featureFlags.isFreeTrialEnabled()) {
          setPlanType(currentActivePlan)
        }
      }
    }
  }, [currentActivePlan, hasReferral])

  const checkoutMutation = useCheckoutMutation(accessToken)

  const canPay = details?.canPay ?? false
  const isSubscribedToFreeTrial = currentActivePlan === 'free_trial'
  const isSubscribedToMonthlyPlan = currentActivePlan === 'month'
  const isSubscribedToYearlyPlan = currentActivePlan === 'year'
  const hasIntroducedCardDetails = !canPay

  const monthlyPriceId = getConfig().stripeMonthlyPriceId
  const yearlyPriceId = getConfig().stripeYearlyPriceId
  const lifetimePriceId = getConfig().stripeLifetimePriceId

  const handleCTAClick = () => {
    POSTHOG_EVENTS.click('subscribe_button')
    if (hasIntroducedCardDetails) {
      const currentPath = location.pathname + location.search
      customerPortalMutation.mutate({ returnPath: currentPath })
    } else {
      let priceId
      switch (planType) {
        case 'month':
          priceId = monthlyPriceId
          break
        case 'year':
          priceId = yearlyPriceId
          break
        default:
          priceId = lifetimePriceId
          break
      }
      checkoutMutation.mutate({
        priceId: priceId,
        successPathAndHash: ROUTE_PATHS.CHECKOUT_SUCCESS,
        cancelPathAndHash: ROUTE_PATHS.DASHBOARD,
      })
    }
  }

  const handleGoPracticeNowClick = () => {
    POSTHOG_EVENTS.click('go_practice_now_button')
    navigate(ROUTE_PATHS.DASHBOARD)
  }

  const getButtonText = () => {
    if (planType === 'free_trial') {
      if (canSubscribeWithReferralDiscount) {
        // in this case we consider the user to be on free trial according to our database model,
        // but we don't want him to know that, as we want him to introduce his card details before he gets free trial
        // more about it here
        // https://www.notion.so/grammarians/Why-are-the-plans-in-the-frontend-defined-like-this-628ff0b8fa66470f9eddecdd258ad44f?pvs=4
        return `START ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-DAY FREE TRIAL`
      } else {
        if (isSubscribedToFreeTrial) {
          return 'Your current plan'
        } else {
          return 'You used your free trial'
        }
      }
    }
    if (hasIntroducedCardDetails) {
      return 'Manage Subscription'
    }
    if (canSubscribeWithReferralDiscount) {
      return `START ${NUMBER_OF_DAYS_IN_FREE_TRIAL}-DAY FREE TRIAL`
    } else {
      return 'SUBSCRIBE NOW'
    }
  }

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const planOptions: PlanOption[] = []

  planOptions.push(
    {
      label: `Yearly${isSubscribedToYearlyPlan ? ' (Current)' : ''}`,
      value: 'year',
      priceMessage: `€${(yearlyPrice / 12).toFixed(2)}/month`,
      discountMessage: canSubscribeWithReferralDiscount
        ? getYearlyDiscountString(yearlyDiscountPercentage, yearlyDurationLimit)
        : null,
      additionalMessage: getConfig().featureFlags.isLifetimePricingEnabled() ? 'most popular' : 'best value',
      billedYearly: `Billed yearly at €${yearlyPrice.toFixed(2)}`,
    },
    {
      label: `Monthly${isSubscribedToMonthlyPlan ? ' (Current)' : ''}`,
      value: 'month',
      priceMessage: `€${monthlyPrice.toFixed(2)}/month`,
      discountMessage: canSubscribeWithReferralDiscount
        ? getMonthlyDiscountString(monthlyDiscountPercentage, monthlyDurationLimit)
        : null,
    }
  )

  if (!canSubscribeWithReferralDiscount && getConfig().featureFlags.isFreeTrialEnabled()) {
    planOptions.push({
      label: `Free Trial${isSubscribedToFreeTrial ? ' (Current)' : ' (Expired)'}`,
      value: 'free_trial',
      discountMessage: null,
      priceMessage: `Free for ${NUMBER_OF_DAYS_IN_FREE_TRIAL} days`,
      additionalMessage: hasUsedFreeTrial ? '' : 'no credit card required',
    })
  }

  if (getConfig().featureFlags.isLifetimePricingEnabled()) {
    planOptions.push({
      label: 'Lifetime',
      value: 'lifetime',
      priceMessage: `€${lifetimePrice.toFixed(2)} paid once`,
      discountMessage: canSubscribeWithReferralDiscount
        ? `${lifetimeDiscountPercentage}% off with your referral!`
        : null,

      additionalMessage: 'best value',
    })
  }

  return (
    <div className='mx-auto flex w-full max-w-6xl flex-col items-center px-1 py-8 md:px-4'>
      <h1 className='mb-4 text-center text-xl font-bold md:mb-8 md:text-3xl'>Want to really commit to learning?</h1>
      <Card className='max-w-2xl overflow-hidden rounded-3xl bg-white p-0'>
        <div className='bg-amber-500 px-8 py-4'>
          <h1 className='text-xl font-bold text-white'>PREMIUM</h1>
          <p className='md:blocktext-white hidden'>Perfect for committed learners</p>
        </div>
        <div className='p-2 md:p-6'>
          <div className='md:mb-6'>
            <h3 className='mb-4 w-full text-center text-lg font-semibold md:mb-6 md:text-left'>
              Simple, Transparent Pricing
            </h3>
            <ul className='hidden space-y-2 md:block'>
              {[
                'Unlimited accent practice in multiple languages',
                'Personalized learning with your own texts',
                'Detailed phonetic transcriptions and transliterations',
                'Create your custom vocabulary and phrase bank',
                'Download and share your progress recordings',
                'Advanced AI-powered pronunciation feedback',
                'Priority access to new and experimental features',
                '24/7 priority customer support',
                'After your free trial ends you can still ask for a refund!',
              ].map((feature) => (
                <li key={feature} className='flex items-center'>
                  <Check className='mr-2 h-4 w-4 text-green-500' />
                  <span className='text-sm md:text-base'>{feature}</span>
                </li>
              ))}
            </ul>
            <ul className='mb-4 space-y-2 md:hidden'>
              {[
                'Unlimited accent practice in multiple languages',
                'Priority access to new and experimental features',
                'after your free trial ends you can still ask for a refund!',
              ].map((feature) => (
                <li key={feature} className='flex items-center'>
                  <Check className='mr-2 h-4 w-4 text-green-500' />
                  <span className='text-sm md:text-base'>{feature}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className='mb-6 flex flex-col gap-y-4'>
            {planOptions.map((option) => {
              return (
                <Button
                  key={option.value}
                  onClick={() => handlePlanOptionClick(option.value as PlanType)}
                  className={clsx('flex h-auto min-h-20 justify-between rounded-lg border px-2 py-4 md:px-4', {
                    'border-indigo-600 bg-indigo-50': planType === option.value,
                    'border-gray-200': planType === option.value,
                    'opacity-60': planType !== option.value && hasIntroducedCardDetails,
                  })}
                  shouldHaveHoverAndActiveStyles={!hasIntroducedCardDetails}
                >
                  <LeftPartOfButton option={option} isChosen={planType === option.value} />
                  <div className='text-right'>
                    <div className='font-semibold'>{option.priceMessage}</div>
                    {option.discountMessage && <div className='text-sm text-green-600'>{option.discountMessage}</div>}
                    {option.billedYearly && <div className='text-sm text-gray-500'>{option.billedYearly}</div>}
                  </div>
                </Button>
              )
            })}
          </div>

          <div className='flex w-full flex-col gap-y-4'>
            <Button
              className='h-12 w-full bg-amber-500 text-lg text-white'
              onClick={handleCTAClick}
              disabled={planType === 'free_trial'}
            >
              {getButtonText()}
            </Button>
            {shouldShowStartButton() && (
              <Button className='h-12 w-full bg-indigo-600 text-lg text-white' onClick={handleGoPracticeNowClick}>
                {hasFinishedOnboarding ? 'GO PRACTICE NOW' : 'START'}
              </Button>
            )}
          </div>
        </div>
      </Card>

      <div className='mt-16'>
        <h2 className='mb-8 text-center text-3xl font-bold text-gray-900'>Frequently Asked Questions</h2>
        <div className='mx-auto max-w-3xl rounded-lg bg-white p-6 shadow-lg shadow-indigo-100/50'>
          <PricingFaqItems />
        </div>
      </div>
    </div>
  )
}
