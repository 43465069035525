import { useState } from 'react'
import { Check } from 'lucide-react'
import { useSelector } from 'react-redux'
import { Button } from '../../shadcn/button'
import { DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '../../shadcn/dialog'
import { getConfig } from '../../../config/environment-config.ts'
import { selectAccountAccessToken, selectMonthlyPrice, selectYearlyPrice } from '../../../state/slices/account-slice.ts'
import { useCheckoutMutation } from '../../../hooks/use-checkout-mutation.ts'
import { useLocation } from 'react-router-dom'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'

const features = [
  'Unlimited exercises',
  'Save your words and sentences',
  'Practice with your favorite accent',
  'Practice with your own words',
  'Download the recordings',
  'Support the developers',
]

export const UpgradePlanModalContent = () => {
  const monthlyPrice = useSelector(selectMonthlyPrice)
  const yearlyPrice = useSelector(selectYearlyPrice)
  const plans = [
    {
      id: 'monthly',
      value: 'monthly',
      label: 'Monthly',
      price: `€${monthlyPrice}`,
      priceSuffix: '/month',
      priceId: getConfig().stripeMonthlyPriceId,
    },
    {
      id: 'annually',
      value: 'annually',
      label: 'Annually',
      price: `€${yearlyPrice}`,
      priceSuffix: '/year',
      priceId: getConfig().stripeYearlyPriceId,
    },
  ]

  const [selectedPlan, setSelectedPlan] = useState(plans[0])
  const accessToken = useSelector(selectAccountAccessToken)
  const checkoutMutation = useCheckoutMutation(accessToken)
  const location = useLocation()

  const handleContinue = () => {
    const returnPath = location.pathname
    checkoutMutation.mutate({
      priceId: selectedPlan.priceId,
      successPathAndHash: ROUTE_PATHS.CHECKOUT_SUCCESS,
      cancelPathAndHash: `${returnPath}${location.hash}`,
    })
  }

  return (
    <DialogContent className='max-h-[90vh] w-11/12 overflow-y-auto rounded-xl bg-white p-6 shadow-xl sm:max-w-md'>
      <DialogHeader className='space-y-2'>
        <DialogTitle className='text-2xl font-bold'>Upgrade to Premium</DialogTitle>
        <DialogDescription className='text-gray-600'>
          Unlock premium features and enhance your experience.
        </DialogDescription>
      </DialogHeader>
      <div className='grid gap-6 py-6'>
        <div className='space-y-3'>
          {features.map((feature, index) => (
            <div key={index} className='flex items-center space-x-3'>
              <Check className='h-5 w-5 text-indigo-600' />
              <span className='text-gray-700'>{feature}</span>
            </div>
          ))}
        </div>
        <div className='grid gap-3'>
          {plans.map((plan) => (
            <Button
              key={plan.id}
              variant={'outline'}
              className={`w-full justify-between py-6 ${
                selectedPlan.id === plan.id
                  ? 'border border-indigo-400 bg-indigo-200 text-gray-800'
                  : 'bg-white text-gray-700 hover:bg-gray-100'
              }`}
              onClick={() => setSelectedPlan(plan)}
            >
              <span className='font-medium'>{plan.label}</span>
              <span>
                {plan.price}
                {plan.priceSuffix}
              </span>
            </Button>
          ))}
        </div>
      </div>
      <DialogFooter>
        <Button
          type='submit'
          className='w-full bg-indigo-600 py-6 text-lg font-medium text-white hover:bg-indigo-700'
          onClick={handleContinue}
          disabled={checkoutMutation.isPending}
        >
          {checkoutMutation.isPending ? 'Processing...' : 'Continue'}
        </Button>
      </DialogFooter>
    </DialogContent>
  )
}
