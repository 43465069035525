import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types'
import {
  GetUserSettingsResponse,
  UpdateUserSettingsRequest,
  UpdateUserSettingsResponse,
} from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types'
import { customFetch } from '../../utils'
import { userSettingsUrl } from '../backend-urls'

export const updateUserSettings = async (
  accessToken: string,
  data: UpdateUserSettingsRequest
): Promise<ResponseWrapper<UpdateUserSettingsResponse>> => {
  const response = await customFetch(userSettingsUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  })

  return await response.json()
}

export const getUserSettings = async (accessToken: string): Promise<ResponseWrapper<GetUserSettingsResponse>> => {
  const response = await customFetch(userSettingsUrl, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return await response.json()
}
