import { getConfig } from '../../config/environment-config'
import posthog from 'posthog-js'

export const initializePosthog = () => {
  posthog.init(getConfig().posthogToken, {
    api_host: 'https://us.i.posthog.com',
    autocapture: false,
    persistence: 'localStorage',
  })
  if (getConfig().featureFlags.isPosthogDebugEnabled()) {
    posthog.debug(true)
  }
}

export const identifyUserForPosthog = (userId: string, referral: string) => {
  posthog.identify(userId, { referral: referral })
}
