import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

export interface RandomExerciseState {
  wordsToInclude: string[]
}

const initialState: RandomExerciseState = {
  wordsToInclude: [],
}

export type SetWordsToIncludePayload = {
  wordsToInclude: string[]
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setWordsToInclude: (state, action: PayloadAction<SetWordsToIncludePayload>) => {
      state.wordsToInclude = action.payload.wordsToInclude
    },
  },
})

export const selectWordsToInclude = createSelector(
  [(state: RootState) => state],
  (state) => state.randomExercise.wordsToInclude
)

export const randomExerciseSliceActions = accountSlice.actions

export default accountSlice.reducer
