import { ExpectedWordProps } from './types.ts'
import { RefObject, useEffect, useState } from 'react'
import { useIsFetching } from '@tanstack/react-query'
import { QueryKey } from '../../../../transport/transport/api/query-keys.ts'
import { Play, Volume2 } from 'lucide-react'
import clsx from 'clsx'
import { MediaPlayerInstance } from '@vidstack/react'
import { AudioPlayer } from '../audio-player/audio-player.tsx'
import { PLAYER_TYPE_FOR_ANALYTICS } from '../../../../analytics/posthog/posthog-events.ts'
import { motion } from 'framer-motion'
import { WordPair } from './utils/types.ts'
import { CONFIDENCE_THRESHOLDS } from './score/constants.ts'
import { RoundedLoader } from '../../../loader/rounded-loader.tsx'
import { Button } from '../../../design-system/button.tsx'
import { cn } from '../../../design-system/style-utils.ts'

export const PlayWordNoContextButton = ({
  audioIndividualWord,
  individualWordPlayerRef,
}: {
  audioIndividualWord: string | null
  individualWordPlayerRef: RefObject<MediaPlayerInstance>
}) => {
  const isFetchingAudioIndividualWord = useIsFetching({ queryKey: [QueryKey.AUDIO_INDIVIDUAL_WORD] })
  const [playing, setPlaying] = useState(false)
  const [audioKey, setAudioKey] = useState(0)
  const [shouldPlay, setShouldPlay] = useState(false)

  const onClick = () => {
    setPlaying(true)
    setShouldPlay(true)
    setAudioKey((prevKey) => prevKey + 1)
  }

  const handleCanPlay = () => {
    if (shouldPlay && individualWordPlayerRef.current) {
      individualWordPlayerRef.current.play().then()
      setShouldPlay(false)
      setTimeout(() => {
        setPlaying(false)
      }, individualWordPlayerRef.current.duration * 1000)
    }
  }

  return (
    <div className='flex items-center'>
      <div className='relative h-10 w-10'>
        {isFetchingAudioIndividualWord ? (
          <RoundedLoader />
        ) : (
          <Button
            onClick={onClick}
            className={cn(
              'flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600 p-0',
              playing ? 'bg-indigo-500' : 'bg-indigo-600'
            )}
          >
            {playing ? (
              <Volume2 className='h-5 min-h-5 w-5 min-w-5 text-white' />
            ) : (
              <Play className='h-5 min-h-5 w-5 min-w-5 text-white' />
            )}
          </Button>
        )}
      </div>
      <AudioPlayer
        audioKey={audioKey}
        playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.SINGLE_WORD_PRONUNCIATION_WITHOUT_CONTEXT}
        title={'Headless player for individual word without context '}
        audioSource={audioIndividualWord}
        sourceType='base64'
        isHeadless={true}
        playerRef={individualWordPlayerRef}
        onCanPlay={handleCanPlay}
        playbackRate={0.9}
      />
    </div>
  )
}

export const ExpectedWord = ({ wordPairWithAlignment, onClick, generatedAudioPlayerRef }: ExpectedWordProps) => {
  const { expectedWord = '', expectedStartTime, expectedEndTime } = wordPairWithAlignment
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    if (!generatedAudioPlayerRef) return

    // Subscribe directly to the player to avoid unnecessary re-renders
    const unsubscribe = generatedAudioPlayerRef.current!.subscribe(({ currentTime }) => {
      const isWordActive =
        expectedStartTime !== null &&
        expectedEndTime !== null &&
        currentTime * 1000 >= expectedStartTime &&
        currentTime * 1000 < expectedEndTime

      if (isWordActive !== isActive) {
        setIsActive(isWordActive)
      }
    })

    return () => {
      unsubscribe()
    }
  }, [expectedStartTime, expectedEndTime, generatedAudioPlayerRef, isActive])

  const variants = {
    active: {
      color: '#111827', // tailwind's gray-900
      scale: 1.2,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 20,
      },
    },
    inactive: {
      scale: 1,
    },
  }

  const actualWordMatchedExpectedWord: boolean =
    !!wordPairWithAlignment.expectedWord && !!wordPairWithAlignment.actualWord
  const actualWordHasNotMatchedExpectedWord: boolean =
    (!!wordPairWithAlignment.expectedWord && !wordPairWithAlignment.actualWord) ||
    (!wordPairWithAlignment.expectedWord && !!wordPairWithAlignment.actualWord)
  const confidence = wordPairWithAlignment.confidence ? wordPairWithAlignment.confidence * 100 : 0

  const isExcellentPronunciation = CONFIDENCE_THRESHOLDS.EXCELLENT_CONFIDENCE <= confidence
  const isMediocrePronunciation =
    CONFIDENCE_THRESHOLDS.MEDIOCRE_CONFIDENCE <= confidence && confidence < CONFIDENCE_THRESHOLDS.EXCELLENT_CONFIDENCE
  const isBadPronunciation = confidence < CONFIDENCE_THRESHOLDS.MEDIOCRE_CONFIDENCE
  return (
    <motion.div
      initial='inactive'
      animate={isActive ? 'active' : 'inactive'}
      variants={variants}
      onClick={onClick}
      className='group relative flex h-8 items-center rounded-xl border-l border-r border-t border-gray-200 hover:bg-gray-100 active:bg-gray-200 md:h-10'
    >
      <div
        className={clsx(
          'absolute bottom-0 left-1 right-1 h-[2px] rounded-b-xl bg-green-200 transition-colors duration-100',
          {
            'bg-green-200 group-hover:bg-green-400': actualWordMatchedExpectedWord && isExcellentPronunciation,
            'bg-yellow-200 group-hover:bg-yellow-400': actualWordMatchedExpectedWord && isMediocrePronunciation,
            'bg-orange-200 group-hover:bg-orange-400': actualWordMatchedExpectedWord && isBadPronunciation,
            'bg-red-200': actualWordHasNotMatchedExpectedWord,
            'group-hover:bg-red-300': !wordPairWithAlignment.actualWord && !!wordPairWithAlignment.expectedWord,
            'cursor-pointer group-hover:text-gray-900': !!wordPairWithAlignment.expectedWord,
            'bg-gray-200': isActive,
          }
        )}
      />
      <span className='w-full px-2 text-gray-700 hover:text-gray-900 md:px-4 md:text-gray-600'>{expectedWord}</span>
    </motion.div>
  )
}

export const EmptySlotForExpectedWord = () => {
  return (
    <div className='group relative flex h-8 items-center rounded-xl border-l border-r border-t border-gray-200 md:h-10'>
      <div className='absolute bottom-0 left-1 right-1 h-[2px] rounded-b-xl bg-red-200 transition-colors duration-100' />
    </div>
  )
}

export const ActualWord = ({ pair, onClick }: { pair: WordPair; onClick: () => void }) => {
  return (
    <span
      className='flex h-6 items-center justify-center rounded px-2 pb-0 pt-0 text-sm text-gray-400 transition-colors duration-100 md:pb-1 md:pt-1'
      onClick={onClick}
    >
      {pair.actualWord}
    </span>
  )
}

export const EmptySlotForActualWord = () => {
  return <div className='h-6' />
}
