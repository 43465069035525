import { Button } from '../../../design-system/button.tsx'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountActions,
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectHasVoice,
  selectStudyLanguageOrEnglish,
} from '../../../../state/slices/account-slice.ts'
import { PLAYER_TYPE_FOR_ANALYTICS } from '../../../../analytics/posthog/posthog-events.ts'
import { useQuery } from '@tanstack/react-query'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { GetAudioResponse } from '@shared/frontend-and-backend/body-types/pronunciation/get-audio.types.ts'
import { QueryKey } from '../../../../transport/transport/api/query-keys.ts'
import { getGeneratedAudio } from '../../../../transport/transport/api/pronunciation/pronunciation.ts'
import {
  DialectCode,
  LANGUAGES_WITH_MULTIPLE_DIALECTS,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { AudioPlayer } from '../../../exercises/exercise/audio-player/audio-player.tsx'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { DemoData, demoDataObjects } from './demo-data.ts'
import { useEffect, useState } from 'react'
import { clsx } from 'clsx'
import { Skeleton } from '../../../shadcn/skeleton.tsx'
import {
  dialectCodeToDialectName,
  langCodeToEnglishLanguageName,
} from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'

type DemoCardProps = {
  demoData: DemoData
  audioData: ResponseWrapper<GetAudioResponse> | undefined
  isGeneratingAudio: boolean
  language: SupportedStudyLanguage
  dialect: DialectCode
}

const DemoCard = ({ demoData, audioData, isGeneratingAudio, language, dialect }: DemoCardProps) => (
  <div className='flex h-full max-w-screen-md flex-col items-center rounded-lg bg-white p-4 shadow-md md:p-8 md:px-16'>
    <div className='flex w-full flex-col items-center md:mb-6'>
      <h3 className='mb-2 text-xl font-semibold'>
        Your cloned{' '}
        {LANGUAGES_WITH_MULTIPLE_DIALECTS.includes(language) ? (
          <>{dialectCodeToDialectName(dialect)}</>
        ) : (
          <>{langCodeToEnglishLanguageName(language)}</>
        )}
      </h3>
      <p className='mb-4 text-lg italic text-gray-600'>"{demoData.text}"</p>
      <p className='text-md mb-4 text-gray-500'>- {demoData.author}</p>
    </div>
    {isGeneratingAudio ? (
      <div className='flex w-full flex-col justify-center gap-y-2'>
        <Skeleton className='h-4 w-full' />
        <div className='flex w-full flex-row justify-between'>
          <Skeleton className='h-4 w-20' />
          <Skeleton className='h-4 w-52' />
        </div>
      </div>
    ) : (
      <AudioPlayer
        playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.USER_CLONED_VOICE_DEMO_ON_ONBOARDING}
        title={''}
        audioSource={audioData?.data?.audio ?? null}
        sourceType='base64'
      />
    )}
  </div>
)

export const OnboardingSuccessView = () => {
  const dispatch = useDispatch()
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const dialect: DialectCode = useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect)
  const hasVoice: boolean = useSelector(selectHasVoice)

  const demoData1 = demoDataObjects[studyLanguage]

  useEffect(() => {
    setIsVisible(true)
    return () => {
      setIsVisible(false)
    }
  }, [])

  const useAudioQuery = (demoData: DemoData) =>
    useQuery({
      queryKey: [QueryKey.AUDIO_WITH_ALIGNMENT, demoData.text, accessToken, studyLanguage, dialect],
      queryFn: () => getGeneratedAudio(demoData.text, studyLanguage, dialect, accessToken),
      enabled: hasVoice,
    })

  const { data: audioData1, error: audioError1, isFetching: isGeneratingAudio1 } = useAudioQuery(demoData1)

  useApiErrorHandler(audioError1, `Error generating audio on onboarding success view: ${audioError1}`)

  const handleStartPracticing = () => {
    dispatch(accountActions.setHasJustClonedVoice(false))
  }

  return (
    <div className='flex h-full w-full flex-col items-center space-y-6 px-4 py-4 text-center transition-all md:py-8'>
      <div className='mb-32 flex flex-grow flex-col items-center'>
        <h1 className='max-w-md text-4xl font-bold leading-tight'>Successfully Cloned</h1>
        <p className='mb-6 max-w-md text-lg text-gray-500'>
          Get familiar with your new voice clone, from now on you can practice with it!
        </p>
        <div
          className={clsx('duration-2000 flex w-full justify-center gap-8 ease-in-out md:grid-cols-2 lg:grid-cols-3', {
            'translate-y-0 opacity-100': isVisible,
            'translate-y-8 opacity-0': !isVisible,
          })}
        >
          <DemoCard
            demoData={demoData1}
            audioData={audioData1}
            isGeneratingAudio={isGeneratingAudio1}
            language={studyLanguage}
            dialect={dialect}
          />
        </div>
      </div>
      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-20 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full flex-row justify-center gap-x-4 px-4 py-4 md:w-1/2 3xl:w-1/4'>
          <Button
            onClick={handleStartPracticing}
            className='text-md disabled:indigo-400 w-full max-w-md rounded-lg bg-indigo-600 p-4 text-white hover:bg-indigo-700'
          >
            Start Practicing
          </Button>
        </div>
      </div>
    </div>
  )
}
