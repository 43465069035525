import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { Card } from '../../design-system/card.tsx'
import { TitleWithGradient } from '../../design-system/typography/title-with-gradient.tsx'

export const SignInUpEmailVerificationSentView = ({ isSignIn }: { isSignIn: boolean }) => {
  const location = useLocation()
  const email = location.state?.email || 'your email address'

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  return (
    <div className='flex w-full flex-1 flex-col items-center justify-center px-2'>
      <Card className='gap-y-8'>
        <div className='flex flex-col items-center gap-y-4'>
          <div className='text-center'>
            <TitleWithGradient>Email Verification Sent</TitleWithGradient>
          </div>
          <div className='space-y-2 text-center'>
            <p className='text-gray-700'>We've sent a verification email to:</p>
            <p className='font-medium text-gray-900'>{email}</p>
          </div>
          <div className='space-y-2 text-center'>
            <p className='text-gray-700'>
              Please check your inbox and click on the
              <br />
              link to {isSignIn ? 'sign in' : 'complete your sign up'}.
            </p>
          </div>
        </div>
      </Card>
    </div>
  )
}
