import {
  langCodeToEnglishLanguageName,
  langCodeToNativeLanguageName,
  langCodeToNativeLatinLanguageName,
} from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'
import { LangCode, SUPPORTED_MOTHER_LANGUAGES } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

const LANG_CODE_ALLOWED_TYPINGS: [LangCode, string[]][] = SUPPORTED_MOTHER_LANGUAGES.map((langCode: LangCode) => [
  langCode,
  [
    langCodeToEnglishLanguageName(langCode).toLowerCase(),
    langCodeToNativeLanguageName(langCode).toLowerCase(),
    langCodeToNativeLatinLanguageName(langCode).toLowerCase(),
  ],
])

export const filterMotherLangsByInput = (userInput: string): LangCode[] => {
  if (!userInput) {
    return SUPPORTED_MOTHER_LANGUAGES
  } else {
    const result: LangCode[] = []
    const lowercaseInput = userInput.toLowerCase()

    for (let i = 0; i < LANG_CODE_ALLOWED_TYPINGS.length; i++) {
      const [langCode, allowedTypings] = LANG_CODE_ALLOWED_TYPINGS[i]
      let includesInput = false

      for (let j = 0; j < allowedTypings.length; j++) {
        if (allowedTypings[j].includes(lowercaseInput)) {
          includesInput = true
          break
        }
      }

      if (includesInput) {
        result.push(langCode)
      }
    }

    return result
  }
}
