import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types'
import {
  AddUserPronunciationsRequest,
  AddUserPronunciationsResponse,
  GetCorrectUserPronunciationsRequestParams,
  GetCorrectUserPronunciationsResponse,
  UserPronunciation,
} from '@shared/frontend-and-backend/body-types/words/words.types'
import { customFetch } from '../../utils'
import { getLearnedWordsUrl, postUserPronunciationsUrl } from '../backend-urls'
import { getConfig } from '../../../../config/environment-config'
import { LangCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const getLearnedWords = async (
  accessToken: string,
  cursor?: string,
  languageFilter?: SupportedStudyLanguage
): Promise<ResponseWrapper<GetCorrectUserPronunciationsResponse>> => {
  const params: GetCorrectUserPronunciationsRequestParams = {
    cursor,
    limit: getConfig().paginationLimit,
    language: languageFilter,
  }

  const response: Response = await customFetch(getLearnedWordsUrl(params), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const postUserPronunciations = async (
  accessToken: string,
  text: string,
  userPronunciations: UserPronunciation[],
  language: LangCode,
  score: number
): Promise<ResponseWrapper<AddUserPronunciationsResponse>> => {
  const body: AddUserPronunciationsRequest = { text, userPronunciations, language, score }
  const response: Response = await customFetch(postUserPronunciationsUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}
