import { AudioPlayer } from './audio-player/audio-player.tsx'
import { PronunciationComparisonProps } from './types.ts'
import { Skeleton } from '../../shadcn/skeleton.tsx'
import { PLAYER_TYPE_FOR_ANALYTICS } from '../../../analytics/posthog/posthog-events.ts'

export const PronunciationComparison = ({
  generatedAudio,
  recordedAudioBlob,
  generatedAudioPlayerRef,
  recordedAudioPlayerRef,
  timeSliderRef,
}: PronunciationComparisonProps) => {
  return (
    <div className='flex w-full flex-col items-center gap-5 md:gap-2 xl:gap-12 xl:px-16'>
      <AudioPlayer
        playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.USER_PRONUNCIATION}
        title='Your pronunciation'
        audioSource={recordedAudioBlob}
        sourceType='blob'
        playerRef={recordedAudioPlayerRef}
      />
      {generatedAudio ? (
        <AudioPlayer
          playerTypeForAnalytics={PLAYER_TYPE_FOR_ANALYTICS.USER_CLONED_PRONUNCIATION}
          title='Your better pronunciation'
          audioSource={generatedAudio}
          sourceType='base64'
          playerRef={generatedAudioPlayerRef}
          timeSliderRef={timeSliderRef}
        />
      ) : (
        <div className='flex w-full flex-col justify-center gap-y-2'>
          <Skeleton className='h-4 w-full' />
          <div className='flex w-full flex-row justify-between'>
            <Skeleton className='h-4 w-20' />
            <Skeleton className='h-4 w-40' />
            <Skeleton className='h-4 w-20' />
          </div>
        </div>
      )}
    </div>
  )
}
