import { LucideIcon } from 'lucide-react'
import { clsx } from 'clsx'

type ExerciseCardProps = {
  name: string
  description: string
  icon: LucideIcon
  comingSoon?: boolean
}

export const ExerciseCard = ({ name, description, icon: Icon, comingSoon }: ExerciseCardProps) => (
  <div
    className={clsx(`relative flex h-40 rounded-2xl border p-1 lg:h-60 lg:rounded-3xl lg:p-2`, {
      'opacity-50': comingSoon,
    })}
  >
    {comingSoon && (
      <span className='absolute right-3 top-3 px-2 py-0.5 text-xs font-medium text-slate-500 lg:right-4 lg:top-4'>
        Coming Soon
      </span>
    )}
    <div
      className={clsx('flex h-full w-full flex-col gap-y-3 rounded-xl bg-slate-50 p-3 lg:rounded-2xl lg:p-4', {
        'hover:bg-slate-100 active:bg-slate-200': !comingSoon,
      })}
    >
      <div className='flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-[10px] bg-white drop-shadow-sm lg:h-[72px] lg:w-[72px] lg:rounded-2xl lg:p-5'>
        <Icon className='h-5 w-5 lg:h-8 lg:w-8' />
      </div>
      <div className='flex flex-col gap-y-1 lg:gap-y-3'>
        <h2 className='text-sm font-medium lg:text-2xl'>{name}</h2>
        <p className='font-regular text-xs text-slate-400 lg:text-base'>{description}</p>
      </div>
    </div>
  </div>
)
