export const LOCAL_STORAGE_CONSTANTS = Object.freeze({
  // we need this only for onboarding, once the user clones his voice, he can even remove this from local storage
  // in which case we are just defaulting to English
  MOTHER_LANGUAGE: 'motherLanguage',
  // the below is needed at all times,
  // if the user removes it, we redirect the app to the onboarding choose study language step
  STUDY_LANGUAGE: 'studyLanguage',
  // during onboarding it is set by user only if he chooses a language that needs a dialect
  // if the user sets it from settings it's set up automatically to the default dialect
  DIALECT: 'dialect',
  REFERRAL: 'referral',
  SHOULD_SHOW_IPA: 'shouldShowIpa',
  SHOULD_SHOW_TRANSLITERATION: 'shouldShowTransliteration',

  FEATURE_FLAGS: {
    IS_POSTHOG_DEBUG_ENABLED: 'featureFlags.isPosthogDebugEnabled',
    IS_SIGN_IN_UP_WITH_EMAIL_ENABLED: 'featureFlags.isSignInUpWithEmailEnabled',
  },
})
