import { Streak } from './streak.tsx'
import {
  useCurrentStreak,
  useGetNumberOfDaysOfNextStreakBadge,
  useLongestStreak,
  useNumberOfAchievedStreakBadges,
  useTotalDaysLearned,
} from '../../../../transport/transport/api/learned-words/words-hooks.ts'
import { useEffect } from 'react'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'

export const StreakTab = () => {
  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  return (
    <div className='flex h-full w-full flex-col items-center py-4'>
      <Streak
        currentStreak={useCurrentStreak()}
        longestStreak={useLongestStreak()}
        totalDays={useTotalDaysLearned()}
        numberOfDaysOfNextStreakBadge={useGetNumberOfDaysOfNextStreakBadge()}
        numberOfAchievedStreakBadges={useNumberOfAchievedStreakBadges()}
      />
    </div>
  )
}
