import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectIsBackendUserInfoLoaded, selectMissingOnboardingStep } from '../../state/slices/account-slice.ts'
import { useLocation, useNavigate } from 'react-router-dom'
import { isOnboardingException, ONBOARDING_PATHS, ROUTE_PATHS } from '../../routing/route-paths.ts'

export const OnboardingRedirect = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const missingOnboardingStep: string | null = useSelector(selectMissingOnboardingStep)
  const isBackendUserInfoLoaded = useSelector(selectIsBackendUserInfoLoaded)

  // If user tries to explicitly enter an onboarding url, but he finished the onboarding before
  // we just redirect him to the exercises page
  useEffect(() => {
    if (isBackendUserInfoLoaded && !missingOnboardingStep && ONBOARDING_PATHS.includes(location.pathname)) {
      navigate(ROUTE_PATHS.DASHBOARD, { replace: true })
    }
  }, [missingOnboardingStep, navigate, isBackendUserInfoLoaded, location.pathname])

  // Redirect to the first missing onboarding step if the user is missing a step
  useEffect(() => {
    if (isOnboardingException(location.pathname)) {
      return
    }
    if (isBackendUserInfoLoaded && missingOnboardingStep && missingOnboardingStep !== location.pathname) {
      navigate(missingOnboardingStep, { replace: true })
    }
  }, [missingOnboardingStep, navigate, isBackendUserInfoLoaded, location.pathname])

  return <></>
}
