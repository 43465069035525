import {
  audioUrl,
  audioWordUrl,
  getRandomTextExerciseUrl,
  getSentenceFromFrequencyListUrl,
  ipaTranscriptionUrl,
  transcribeUrl,
} from '../backend-urls.ts'
import { TranscriptionResponse } from '@shared/frontend-and-backend/body-types/pronunciation/transcribe.types.ts'
import {
  GetExerciseResponse,
  GetRandomExerciseRequest,
  GetSentenceFromFrequencyListRequest,
} from '@shared/frontend-and-backend/body-types/exercise/get-text.types.ts'
import {
  GetAudioIndividualWordRequest,
  GetAudioIndividualWordResponse,
  GetAudioRequest,
  GetAudioResponse,
} from '@shared/frontend-and-backend/body-types/pronunciation/get-audio.types.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import {
  DialectCode,
  LangCode,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import {
  GetIpaRequest,
  GetIpaResponse,
} from '@shared/frontend-and-backend/body-types/pronunciation/transcribe-ipa.types.ts'
import { customFetch } from '../../utils.ts'

export const transcribeAudio = async (
  accessToken: string,
  langCode: LangCode,
  audioBlob: Blob
): Promise<ResponseWrapper<TranscriptionResponse>> => {
  const formData = new FormData()
  formData.append('audio', audioBlob, 'user-audio.mp3')

  const response: Response = await customFetch(transcribeUrl(langCode), {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const data = await response.json()
  return data
}

export const getGeneratedText = async (
  wordsToInclude: string[],
  language: LangCode,
  accessToken: string
): Promise<ResponseWrapper<GetExerciseResponse>> => {
  const body: GetRandomExerciseRequest = { wordsToInclude, language }
  const response: Response = await customFetch(getRandomTextExerciseUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}

export const getGeneratedAudio = async (
  text: string,
  language: SupportedStudyLanguage,
  dialect: DialectCode,
  accessToken: string
): Promise<ResponseWrapper<GetAudioResponse>> => {
  const body: GetAudioRequest = { text, language, dialect }
  const response = await customFetch(audioUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}

export const getGeneratedAudioIndividualWord = async (
  word: string,
  language: SupportedStudyLanguage,
  dialect: DialectCode,
  expectedWords: string[],
  selectedWordIndex: number,
  accessToken: string
): Promise<ResponseWrapper<GetAudioIndividualWordResponse>> => {
  const body: GetAudioIndividualWordRequest = { word, language, dialect, expectedWords, selectedWordIndex }
  const response = await customFetch(audioWordUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  const responseData: ResponseWrapper<GetAudioIndividualWordResponse> = await response.json()
  return responseData
}

export const getGeneratedIpaTranscription = async (
  text: string,
  language: SupportedStudyLanguage,
  dialect: DialectCode,
  accessToken: string
): Promise<ResponseWrapper<GetIpaResponse>> => {
  const body: GetIpaRequest = { text, language, dialect }
  const response = await customFetch(ipaTranscriptionUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  const responseData: ResponseWrapper<GetIpaResponse> = await response.json()
  return responseData
}

export const getSentenceFromFrequencyList = async (
  language: SupportedStudyLanguage,
  position: number,
  accessToken: string
): Promise<ResponseWrapper<GetExerciseResponse>> => {
  const body: GetSentenceFromFrequencyListRequest = { language, position }
  const response: Response = await customFetch(getSentenceFromFrequencyListUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}
