import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { QueryKey } from '../../../transport/transport/api/query-keys.ts'
import {
  selectAccountAccessToken,
  selectMotherLanguageOrEnglish,
  selectStudyLanguageOrEnglish,
} from '../../../state/slices/account-slice.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { TranslateTextResponse } from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { getTextTranslation } from '../../../transport/transport/api/translation/translation.ts'
import { Skeleton } from '../../shadcn/skeleton.tsx'
import { useApiErrorHandler } from '../../../hooks/use-api-error-handler.ts'
import { DialogContent, DialogDescription, DialogTitle } from '../../shadcn/dialog.tsx'

export const TranslateTextModalContent = () => {
  const queryClient = useQueryClient()
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: LangCode = useSelector(selectStudyLanguageOrEnglish)
  const motherLanguage: LangCode = useSelector(selectMotherLanguageOrEnglish)

  const {
    data: currentExerciseText,
    isLoading: isLoadingExerciseText,
    isFetching: isFetchingExerciseText,
  } = useQuery<string>({
    queryKey: [QueryKey.EXERCISE_TEXT],
    queryFn: () => queryClient.getQueryData<string>([QueryKey.EXERCISE_TEXT]) ?? '',
    enabled: true,
  })

  const expectedText = currentExerciseText ?? ''

  const {
    data: textTranslationData,
    isFetching: isFetchingTextTranslation,
    error: textTranslationError,
  } = useQuery<ResponseWrapper<TranslateTextResponse>>({
    // we do not need to include accessToken in the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QueryKey.TRANSLATE_TEXT, expectedText, motherLanguage, studyLanguage],
    queryFn: () => getTextTranslation(expectedText, studyLanguage, motherLanguage, accessToken),
    enabled: !!expectedText,
  })

  useApiErrorHandler(
    textTranslationError,
    `Error translating text: ${expectedText}, ${motherLanguage}, ${studyLanguage}, ${textTranslationError}`
  )

  const translatedText: string = textTranslationData?.data?.translation ?? ''

  return (
    <DialogContent className='w-11/12 rounded-xl bg-white p-8 sm:max-w-md'>
      <DialogTitle className='hidden'></DialogTitle>
      <DialogDescription className='hidden'></DialogDescription>
      <div>
        <div className='mb-2 text-lg font-semibold leading-none tracking-tight text-gray-700'>Translation</div>
        {isLoadingExerciseText || isFetchingTextTranslation || !translatedText ? (
          <div className='flex flex-col gap-y-2'>
            <Skeleton className='h-4 w-full md:max-w-4xl' />
            <Skeleton className='h-4 w-full md:max-w-4xl' />
          </div>
        ) : (
          <span className='tracking-tight'>{translatedText}</span>
        )}
      </div>
      <div>
        <div className='mb-2 text-lg font-semibold leading-none tracking-tight text-gray-700'>Original Text</div>
        {isFetchingExerciseText || !currentExerciseText ? (
          <div className='flex flex-col gap-y-2'>
            <Skeleton className='h-4 w-full md:max-w-4xl' />
            <Skeleton className='h-4 w-full md:max-w-4xl' />
          </div>
        ) : (
          <span className='tracking-tight'>{expectedText}</span>
        )}
      </div>
    </DialogContent>
  )
}
