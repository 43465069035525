import { z } from 'zod'

export const formSchema = z.object({
  username: z.string().min(1, {
    message: 'Your name must be at least 1 characters.',
  }),
  email: z.string().email({
    message: 'Please enter a valid email address.',
  }),
  message: z
    .string()
    .max(1000, {
      message: 'The message must be less than 1000 characters.',
    })
    .min(4, {
      message: 'Your message is too short, it must be at least 4 characters.',
    }),
})
