export enum QueryKey {
  TRANSCRIPTION = 'transcription',
  AUDIO_WITH_ALIGNMENT = 'audio-with-alignment',
  AUDIO_INDIVIDUAL_WORD = 'audio-individual-word',
  USER = 'user',
  EXERCISE_TEXT = 'exercise-text',
  EXERCISE_RANDOM = 'exercise-random',
  EXERCISE_FREQUENCY_LIST = 'exercise-frequency-list',
  TRANSLATE_WORD = 'translate-word',
  TRANSLATE_TEXT = 'translate-text',
  IPA_TRANSCRIPTION = 'ipa-transcription',
  TRANSLITERATION = 'transliteration',
  GET_LEARNED_WORDS = 'get-learned-words',
  USER_WORDS = 'user-words',
  LANGUAGE_DETECTION = 'language-detection',
  SUBSCRIPTION_DETAILS = 'subscription-details',
  USER_SETTINGS = 'user-settings',
  SAVED_WORDS = 'saved-words',
  SAVED_WORDS_LIST = 'saved-words-list',
}
