import { useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../../shadcn/alert-dialog.tsx'
import { Button } from '../../../shadcn/button.tsx'
import { selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'
import { logWithRollbar } from '../../../../analytics/rollbar/log-with-rollbar.ts'
import { Input } from '../../../shadcn/input.tsx'
import { ROUTE_PATHS } from '../../../../routing/route-paths.ts'
import { sendRemovalRequest } from '../../../../transport/transport/api/removals/removals.ts'

export const DeleteAccountAlertDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const accessToken: string = useSelector(selectAccountAccessToken)
  const [confirmationText, setConfirmationText] = useState('')

  const deleteMutation = useMutation({
    mutationFn: () => sendRemovalRequest('account', accessToken),
    onSuccess: async () => {
      // we can't use getSupabaseClient().auth.signOut() because at this point the user has just been deleted completely
      // from the backend. getSupabaseClient().auth.signOut() calls the supabase backend, so it would fail before
      // cleaning the local storage. The best way is to clean the local storage manually. We have to do it anyway
      // when the user is removed
      window.localStorage.clear()
      // we need this hard refresh instead of navigate(ROUTE_PATHS.ACCOUNT_REMOVED)
      // because this is a simpler way of cleaning our redux store.
      // Otherwise, synchronizers, protected route, or onboarding redirect might cause some race conditions.
      window.location.replace(window.location.origin + ROUTE_PATHS.ACCOUNT_REMOVED)
    },
    onError: (error) => {
      logWithRollbar(`Failed to send delete account request: + ${JSON.stringify(error)} ${error}`)
    },
  })

  const handleDeleteRequest = async () => {
    deleteMutation.mutate()
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
      <AlertDialogTrigger className='w-full rounded-md border border-red-200 bg-white px-4 py-2 text-red-500 transition-colors duration-200 hover:bg-red-600 hover:text-white'>
        Delete my account
      </AlertDialogTrigger>
      <AlertDialogContent className='max-w-sm rounded-lg bg-white p-6 shadow-xl md:max-w-md'>
        <AlertDialogHeader className='gap-y-2'>
          <AlertDialogTitle className='text-xl font-semibold'>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className='text-gray-500'>
            This action cannot be undone. This will permanently delete your account and related data from our servers.
            Please type "I want to delete my account" to confirm.
          </AlertDialogDescription>
          <Input
            value={confirmationText}
            onChange={(e) => {
              setConfirmationText(e.target.value)
            }}
          />
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant='destructive'
            onClick={handleDeleteRequest}
            disabled={deleteMutation.isPending || confirmationText !== 'I want to delete my account'}
            className='w-full bg-red-500 text-white hover:bg-red-600 sm:w-auto'
          >
            {deleteMutation.isPending ? (
              <>
                <Loader2 className='mr-2 h-4 w-4 animate-spin' />
                Sending...
              </>
            ) : deleteMutation.isError ? (
              'An error occurred. Please try again.'
            ) : (
              'Confirm deletion'
            )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
