import { zodResolver } from '@hookform/resolvers/zod'
import {
  dialectCodeToDialectName,
  langCodeToEnglishLanguageName,
} from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'
import {
  DEFAULT_DIALECTS,
  DialectCode,
  LangCode,
  LANGUAGES_TO_DIALECT_MAP,
  SUPPORTED_MOTHER_LANGUAGES,
  SUPPORTED_STUDY_LANGUAGES,
  SupportedMotherLanguage,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'sonner'
import { z } from 'zod'
import {
  accountActions,
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectMotherLanguageOrEnglish,
  selectStudyLanguageOrEnglish,
} from '../../state/slices/account-slice'
import { randomExerciseSliceActions } from '../../state/slices/random-exercise-slice'
import DialectSelector from './dialect-selector'
import { LanguageComboBox } from './language-combo-box'
import { SettingsFormProps } from './types'
import { useFrequencySliderPosition, useUserSettings } from '../../transport/transport/api/users/users-hooks.ts'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { cloneDeep } from 'lodash'
import { useUpdateSettingsMutation } from '../../hooks/use-update-settings-mutation.ts'
import { MAX_NUMBER_OF_WORDS_IN_FREQUENCY_LIST } from '../../constants/constants-config.ts'
import {
  preferenceActions,
  selectShouldShowIpa,
  selectShouldShowTransliteration,
} from '../../state/slices/preferences-slice.ts'
import { FormControl, FormDescription, FormItem, FormLabel } from '../shadcn/form.tsx'
import { IpaToggle } from '../exercises/exercise/controls/ipa-toggle.tsx'
import { TransliterationToggle } from '../exercises/exercise/controls/transliteration-toggle.tsx'
import { CefrLevelSelector } from '../exercises/exercise/controls/cefr-level-selector.tsx'

const FormSchema = z.object({
  studyLanguage: z.nativeEnum(LangCode, {
    required_error: 'Please select a language.',
  }),
  motherLanguage: z.nativeEnum(LangCode, {
    required_error: 'Please select a language.',
  }),
  frequencyListPosition: z.number().int().min(0).max(MAX_NUMBER_OF_WORDS_IN_FREQUENCY_LIST),
})

type FormInputs = z.infer<typeof FormSchema>

export const SettingsForm = ({ onSubmit }: SettingsFormProps) => {
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const motherLanguage: SupportedMotherLanguage = useSelector(selectMotherLanguageOrEnglish)
  const selectedDialect: DialectCode =
    useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect) || DEFAULT_DIALECTS[studyLanguage]
  const position = useFrequencySliderPosition(studyLanguage)
  const { data: userSettings } = useUserSettings()

  const { mutate } = useUpdateSettingsMutation(accessToken, studyLanguage)
  const shouldShowIpa = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration = useSelector(selectShouldShowTransliteration)
  const dispatch = useDispatch()

  const handleIpaClick = () => {
    dispatch(preferenceActions.setShouldShowIpa(!shouldShowIpa))
  }

  const handleTransliterationClick = () => {
    dispatch(preferenceActions.setShouldShowTransliteration(!shouldShowTransliteration))
  }

  const form = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      studyLanguage: studyLanguage,
      motherLanguage: motherLanguage,
    },
  })

  const dialectsForSelectedLanguage = LANGUAGES_TO_DIALECT_MAP[studyLanguage]

  useEffect(() => {
    if (!dialectsForSelectedLanguage.includes(selectedDialect)) {
      dispatch(accountActions.setDialect(DEFAULT_DIALECTS[studyLanguage]))
    }
  }, [dispatch, studyLanguage, selectedDialect, dialectsForSelectedLanguage])

  const handleDialectSelect = (value: DialectCode) => {
    if (value) {
      dispatch(accountActions.setDialect(value))
      toast.info(`Accent set to: ${dialectCodeToDialectName(value)}`)
    }
  }

  const handleMotherLanguageSelect = (value: LangCode) => {
    dispatch(accountActions.setMotherLanguage(value))
    toast.info(`Mother language set to: ${langCodeToEnglishLanguageName(value)}`)
  }

  const handleStudyLanguageSelect = (value: LangCode) => {
    dispatch(randomExerciseSliceActions.setWordsToInclude({ wordsToInclude: [] }))
    dispatch(accountActions.setStudyLanguage(value as SupportedStudyLanguage))
    toast.info(`Study language set to: ${langCodeToEnglishLanguageName(value)}`)
  }

  const updateFrequencySliderPosition = (language: LangCode, position: number) => {
    if (!userSettings) {
      return
    }
    const updatedSettings: UserSettings = cloneDeep(userSettings)
    updatedSettings.preferences.exercises.frequencyList.position.byLanguage.map((lang) => {
      if (lang.language === language) {
        lang.position = position
      }
      return lang
    })
    mutate(updatedSettings)
  }

  const handlePositionChange = (newPosition: number) => {
    updateFrequencySliderPosition(studyLanguage, newPosition)
  }

  return (
    <FormProvider {...form}>
      <form className='space-y-6' onSubmit={form.handleSubmit(onSubmit)}>
        <LanguageComboBox
          name='studyLanguage'
          label='I am learning:'
          description='This is the language that will be used in the exercises.'
          onLanguageSelect={handleStudyLanguageSelect}
          langCodes={SUPPORTED_STUDY_LANGUAGES}
        />

        {dialectsForSelectedLanguage.length > 1 && (
          <DialectSelector
            dialects={dialectsForSelectedLanguage}
            selectedDialect={selectedDialect}
            onDialectSelect={handleDialectSelect}
          />
        )}
        <LanguageComboBox
          name='motherLanguage'
          label='My native language:'
          onLanguageSelect={handleMotherLanguageSelect}
          langCodes={SUPPORTED_MOTHER_LANGUAGES}
        />
        <CefrLevelSelector initialPosition={position} onPositionCommit={handlePositionChange} />

        <FormItem className='space-y-2'>
          <div>
            <FormLabel className='text-base font-semibold'>IPA and transliteration</FormLabel>
          </div>
          <FormControl>
            <div className='space-y-3'>
              <IpaToggle shouldShowIpa={shouldShowIpa} handleIpaClick={handleIpaClick} />
              <TransliterationToggle
                shouldShowTransliteration={shouldShowTransliteration}
                handleTransliterationClick={handleTransliterationClick}
              />
            </div>
          </FormControl>
          <FormDescription className='mt-1 text-sm text-gray-400'>
            International phonetic alphabet (IPA) and transliteration will appear above the study words
          </FormDescription>
        </FormItem>
      </form>
    </FormProvider>
  )
}
