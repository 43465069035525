import { useDispatch } from 'react-redux'
import { DialogContent, DialogDescription, DialogHeader, DialogTitle } from '../../../shadcn/dialog.tsx'
import { SubscriptionContent } from './subscription-content.tsx'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { DANGER_ZONE_MODAL_ID } from '../../modal-ids.ts'
import { Button } from '../../../design-system/button.tsx'

export const AccountModalContent = () => {
  const dispatch = useDispatch()

  return (
    <DialogContent className='flex max-h-[90vh] w-11/12 flex-col overflow-y-auto rounded-xl bg-white shadow-xl sm:max-w-md'>
      <DialogHeader className='mb-6'>
        <DialogTitle className='text-2xl font-bold text-stone-900'>Account</DialogTitle>
        <DialogDescription className='text-sm text-gray-600'>Make changes to your account here.</DialogDescription>
      </DialogHeader>

      <div className='flex flex-col gap-y-4'>
        <SubscriptionContent />

        <div className='flex w-full flex-row items-center'>
          <Button
            onClick={() => dispatch(modalActions.openModal(DANGER_ZONE_MODAL_ID))}
            className='h-10 w-full border bg-white text-red-600'
          >
            <span>Danger Zone</span>
          </Button>
        </div>
      </div>
    </DialogContent>
  )
}
