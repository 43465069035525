import { DialectCode, LangCode } from './lang-codes'

const langCodeToEnglishLanguageNameMap: Record<LangCode, string> = {
  [LangCode.ENGLISH]: 'English',
  [LangCode.SPANISH]: 'Spanish',
  [LangCode.GERMAN]: 'German',
  [LangCode.FRENCH]: 'French',
  [LangCode.ITALIAN]: 'Italian',
  [LangCode.POLISH]: 'Polish',
  [LangCode.CZECH]: 'Czech',
  [LangCode.UKRAINIAN]: 'Ukrainian',
  [LangCode.RUSSIAN]: 'Russian',
  [LangCode.PORTUGUESE]: 'Portuguese',
  [LangCode.VIETNAMESE]: 'Vietnamese',
  [LangCode.CHINESE]: 'Chinese',
  [LangCode.HINDI]: 'Hindi',
  [LangCode.INDONESIAN]: 'Indonesian',
  [LangCode.MALAY]: 'Malay',
  [LangCode.JAPANESE]: 'Japanese',
  [LangCode.KOREAN]: 'Korean',
  [LangCode.TAMIL]: 'Tamil',
  [LangCode.TURKISH]: 'Turkish',
  [LangCode.ROMANIAN]: 'Romanian',
  [LangCode.SWEDISH]: 'Swedish',
  [LangCode.NORWEGIAN]: 'Norwegian',
  [LangCode.DANISH]: 'Danish',
  [LangCode.SLOVAK]: 'Slovak',
  [LangCode.DUTCH]: 'Dutch',
  [LangCode.THAI]: 'Thai',
  [LangCode.HUNGARIAN]: 'Hungarian',
  [LangCode.GREEK]: 'Greek',
  [LangCode.FINNISH]: 'Finnish',
  [LangCode.BULGARIAN]: 'Bulgarian',
  [LangCode.CATALAN]: 'Catalan',
}

export const langCodeToEnglishLanguageName = (langCode: LangCode): string => {
  return langCodeToEnglishLanguageNameMap[langCode] || 'Unknown Language'
}

const langCodeToNativeLanguageNameMap: Record<LangCode, string> = {
  [LangCode.ENGLISH]: 'English',
  [LangCode.SPANISH]: 'español',
  [LangCode.GERMAN]: 'deutsch',
  [LangCode.FRENCH]: 'français',
  [LangCode.ITALIAN]: 'italiano',
  [LangCode.POLISH]: 'polski',
  [LangCode.CZECH]: 'čeština',
  [LangCode.UKRAINIAN]: 'українська',
  [LangCode.RUSSIAN]: 'русский',
  [LangCode.PORTUGUESE]: 'português',
  [LangCode.VIETNAMESE]: 'tiếng Việt',
  [LangCode.CHINESE]: '中文',
  [LangCode.HINDI]: 'हिन्दी',
  [LangCode.INDONESIAN]: 'Bahasa Indonesia',
  [LangCode.MALAY]: 'Bahasa Melayu',
  [LangCode.JAPANESE]: '日本語',
  [LangCode.KOREAN]: '한국어',
  [LangCode.TAMIL]: 'தமிழ்',
  [LangCode.TURKISH]: 'Türkçe',
  [LangCode.ROMANIAN]: 'Română',
  [LangCode.SWEDISH]: 'svenska',
  [LangCode.NORWEGIAN]: 'norsk',
  [LangCode.DANISH]: 'dansk',
  [LangCode.SLOVAK]: 'slovenčina',
  [LangCode.DUTCH]: 'Nederlands',
  [LangCode.THAI]: 'ไทย',
  [LangCode.HUNGARIAN]: 'magyar',
  [LangCode.GREEK]: 'ελληνικά',
  [LangCode.FINNISH]: 'suomi',
  [LangCode.BULGARIAN]: 'български',
  [LangCode.CATALAN]: 'català',
}

export const langCodeToNativeLanguageName = (langCode: LangCode): string => {
  return langCodeToNativeLanguageNameMap[langCode] || 'Unknown Language'
}

const langCodeToNativeLatinLanguageNameMap: Record<LangCode, string> = {
  [LangCode.ENGLISH]: 'English',
  [LangCode.SPANISH]: 'espanol',
  [LangCode.GERMAN]: 'deutsch',
  [LangCode.FRENCH]: 'francais',
  [LangCode.ITALIAN]: 'italiano',
  [LangCode.POLISH]: 'polski',
  [LangCode.CZECH]: 'cestina',
  [LangCode.UKRAINIAN]: 'ukrainska',
  [LangCode.RUSSIAN]: 'russkiy',
  [LangCode.PORTUGUESE]: 'portugues',
  [LangCode.VIETNAMESE]: 'tieng viet',
  [LangCode.CHINESE]: 'zhong wen',
  [LangCode.HINDI]: 'hindi',
  [LangCode.INDONESIAN]: 'Bahasa Indonesia',
  [LangCode.MALAY]: 'Bahasa Melayu',
  [LangCode.JAPANESE]: 'nihongo',
  [LangCode.KOREAN]: 'Hangugeo',
  [LangCode.TAMIL]: 'tamil',
  [LangCode.TURKISH]: 'turkce',
  [LangCode.ROMANIAN]: 'romana',
  [LangCode.SWEDISH]: 'svenska',
  [LangCode.NORWEGIAN]: 'norsk',
  [LangCode.DANISH]: 'dansk',
  [LangCode.SLOVAK]: 'slovencina',
  [LangCode.DUTCH]: 'Nederlands',
  [LangCode.THAI]: 'thiy',
  [LangCode.HUNGARIAN]: 'magyar',
  [LangCode.GREEK]: 'ellinika',
  [LangCode.FINNISH]: 'suomi',
  [LangCode.BULGARIAN]: 'bulgarski',
  [LangCode.CATALAN]: 'catala',
}

export const langCodeToNativeLatinLanguageName = (langCode: LangCode): string => {
  return langCodeToNativeLatinLanguageNameMap[langCode] || 'Unknown Language'
}

const dialectCodeToDialectNameMap: Record<DialectCode, string> = {
  [DialectCode.AMERICAN_ENGLISH]: 'American English',
  [DialectCode.BRITISH_ENGLISH]: 'British English',
  [DialectCode.AUSTRALIAN_ENGLISH]: 'Australian English',
  [DialectCode.SCOTTISH_ENGLISH]: 'Scottish English',
  [DialectCode.CASTILIAN_SPANISH]: 'Castilian Spanish',
  [DialectCode.MEXICAN_SPANISH]: 'Mexican Spanish',
  [DialectCode.COLOMBIAN_SPANISH]: 'Colombian Spanish',
  [DialectCode.ARGENTINIAN_SPANISH]: 'Argentinian Spanish',
  [DialectCode.PERUVIAN_SPANISH]: 'Peruvian Spanish',
  [DialectCode.PARISIAN_FRENCH]: 'Parisian French',
  [DialectCode.BELGIAN_FRENCH]: 'Belgian French',
  [DialectCode.CANADIAN_FRENCH]: 'Canadian French',
  [DialectCode.STANDARD_GERMAN]: 'Standard German',
  [DialectCode.STANDARD_ITALIAN]: 'Standard Italian',
  [DialectCode.STANDARD_POLISH]: 'Standard Polish',
  [DialectCode.BRAZILIAN_PORTUGUESE]: 'Brazilian Portuguese',
  [DialectCode.EUROPEAN_PORTUGUESE]: 'European Portuguese',
  [DialectCode.STANDARD_RUSSIAN]: 'Standard Russian',
  [DialectCode.STANDARD_UKRAINIAN]: 'Standard Ukrainian',
  [DialectCode.STANDARD_CZECH]: 'Standard Czech',
  [DialectCode.STANDARD_DANISH]: 'Standard Danish',
  [DialectCode.STANDARD_DUTCH]: 'Standard Dutch',
  [DialectCode.STANDARD_FINNISH]: 'Standard Finnish',
  [DialectCode.STANDARD_INDONESIAN]: 'Standard Indonesian',
  [DialectCode.STANDARD_MALAY]: 'Standard Malay',
  [DialectCode.STANDARD_ROMANIAN]: 'Standard Romanian',
  [DialectCode.STANDARD_SLOVAK]: 'Standard Slovak',
  [DialectCode.STANDARD_SWEDISH]: 'Standard Swedish',
  [DialectCode.STANDARD_TURKISH]: 'Standard Turkish',
  [DialectCode.STANDARD_HUNGARIAN]: 'Standard Hungarian',
  [DialectCode.STANDARD_NORWEGIAN]: 'Standard Norwegian',
}

export const dialectCodeToDialectName = (dialectCode: DialectCode): string => {
  return dialectCodeToDialectNameMap[dialectCode] || 'Unknown Dialect'
}
