import { memo, useEffect } from 'react'
import { motion, stagger, useAnimate } from 'framer-motion'
import { cn } from '../../../../utils/shadcn-utils.ts'

export const TextGenerateEffect = memo(({ words, className }: { words: string[]; className?: string }) => {
  const [scope, animate] = useAnimate()
  useEffect(() => {
    animate(
      'span',
      {
        opacity: 1,
      },
      {
        duration: 4,
        delay: stagger(0.7),
      }
    )
  }, [animate, scope.current])

  const renderWords = () => {
    return (
      <motion.span ref={scope} className='inline'>
        {words.map((word, idx) => {
          return (
            <motion.span key={word + idx} className='text-stone-900 opacity-0'>
              {word}{' '}
            </motion.span>
          )
        })}
      </motion.span>
    )
  }

  return <span className={cn('inline', className)}>{renderWords()}</span>
})
