import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../../shadcn/form.tsx'
import { Input } from '../../../shadcn/input.tsx'
import { Textarea } from '../../../shadcn/textarea.tsx'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'
import { formSchema } from '@shared/frontend-and-backend/body-types/contact-email/form-schema.ts'
import { sendEmail } from '../../../../transport/transport/api/contact-email/contact-email.ts'
import { toast } from 'sonner'
import { logWithRollbar } from '../../../../analytics/rollbar/log-with-rollbar.ts'
import { Copy, Loader2, Mail, Send } from 'lucide-react'
import clsx from 'clsx'
import { Button } from '../../../design-system/button.tsx'

export const ContactForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      email: '',
      message: '',
    },
  })
  const accessToken: string = useSelector(selectAccountAccessToken)

  const ourEmail: string = 'contact@yourbestaccent.com'

  const copyEmailToClipboard = () => {
    navigator.clipboard.writeText(ourEmail).then()
    toast.success('Email copied to clipboard')
  }

  const sendEmailMutation = useMutation({
    mutationFn: (data: z.infer<typeof formSchema>) => sendEmail(data, accessToken),
  })

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    sendEmailMutation.mutate(values, {
      onSuccess: () => {
        onSuccess()
        form.reset()
        toast.info(`Your email has been sent!`)
      },
      onError: (error) => {
        logWithRollbar(`Failed to send email: + ${JSON.stringify(error)} ${error}`)
        toast.error('Failed to send email. Please try again.')
      },
    })
  }

  return (
    <Form {...form}>
      <div className='flex items-center justify-between rounded-md'>
        <div className='flex items-center'>
          <a
            href='mailto:contact@yourbestaccent.com'
            className='flex flex-row items-center text-sm text-gray-700 hover:underline'
          >
            <Mail className='mr-2 h-5 w-5 text-gray-500' />
            contact@yourbestaccent.com
          </a>
        </div>
        <Button className='h-10 w-10 bg-white text-gray-500' onClick={copyEmailToClipboard}>
          <Copy className='h-5 min-h-5 w-5 min-w-5' />
        </Button>
      </div>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-4'>
        <FormField
          control={form.control}
          name='username'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='text-sm font-medium text-gray-700'>Your name</FormLabel>
              <FormControl>
                <Input
                  className='mt-1 block w-full rounded-md border-gray-300 text-base shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  placeholder='Name'
                  {...field}
                />
              </FormControl>
              <FormMessage className='mt-1 text-xs text-red-500' />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='email'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='text-sm font-medium text-gray-700'>Your email</FormLabel>
              <FormControl>
                <Input
                  className='mt-1 block w-full rounded-md border-gray-300 text-base shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  placeholder='Email'
                  {...field}
                />
              </FormControl>
              <FormMessage className='mt-1 text-xs text-red-500' />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='message'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='text-sm font-medium text-gray-700'>Message</FormLabel>
              <FormControl>
                <Textarea
                  placeholder='Enter your message here...'
                  className='mt-1 block h-32 w-full resize-none rounded-md border-gray-300 text-base shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  {...field}
                />
              </FormControl>
              <FormMessage className='mt-1 text-xs text-red-500' />
            </FormItem>
          )}
        />
        <div className='pt-4'>
          <Button
            type='submit'
            className={clsx(
              'w-full rounded-xl bg-gradient-to-r from-indigo-500 to-indigo-500 px-4 py-2 font-medium text-white',
              {
                'cursor-not-allowed bg-gray-500': sendEmailMutation.isPending || sendEmailMutation.isSuccess,
                'bg-red-500 hover:bg-red-600': sendEmailMutation.isError,
              }
            )}
            disabled={sendEmailMutation.isPending || sendEmailMutation.isSuccess}
          >
            {sendEmailMutation.isPending ? (
              <>
                <Loader2 className='mr-2 inline h-4 w-4 animate-spin' />
                Sending...
              </>
            ) : sendEmailMutation.isError ? (
              'An error occurred. Please try again.'
            ) : (
              <>
                <Send className='mr-2 inline h-4 w-4' />
                Send Message
              </>
            )}
          </Button>
        </div>
      </form>
    </Form>
  )
}
