import { AccountDropdown } from './account-dropdown.tsx'
import { useSelector } from 'react-redux'
import { selectHasFinishedOnboarding, selectIsSignedIn } from '../../state/slices/account-slice.ts'
import { TrialCountdownButton } from './trial-countdown-button.tsx'
import { WordCounter } from './word-counter.tsx'
import { useNavigate } from 'react-router-dom'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'
import { Logo } from '../design-system/logo.tsx'
import { Button } from '../design-system/button.tsx'

export const NavBar = () => {
  const isSignedIn: boolean = useSelector(selectIsSignedIn)
  const hasFinishedOnboarding: boolean = useSelector(selectHasFinishedOnboarding)
  const navigate = useNavigate()

  const handleLogoClick = () => {
    navigate(ROUTE_PATHS.DASHBOARD)
  }

  return (
    <nav className='w-full px-2 md:container md:px-14'>
      <div className='flex h-16 w-full flex-row items-center justify-between rounded-3xl bg-white px-3 md:h-20 md:px-5'>
        <>
          <div>
            <Button className='flex cursor-pointer flex-row justify-start gap-x-3' onClick={handleLogoClick}>
              <div className='relative items-center text-center font-semibold text-cyan-600 md:flex lg:text-3xl'>
                <Logo size={28} />
                <span className='absolute -top-2 left-[26px] font-nunito text-xs text-indigo-600'>beta</span>
              </div>
              <span className='hidden items-center justify-end gap-x-3 text-base font-semibold text-slate-800 md:flex'>
                YourBestAccent
              </span>
            </Button>
          </div>
          <div className='flex flex-row gap-x-10'>
            <div className='flex flex-1 items-center justify-end gap-x-3'>
              <div className=''>{<TrialCountdownButton />}</div>
              {hasFinishedOnboarding && <WordCounter />}
              {isSignedIn && <AccountDropdown />}
            </div>
          </div>
        </>
      </div>
    </nav>
  )
}
