import {
  DialectCode,
  LANGUAGES_TO_DIALECT_MAP,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect, useState } from 'react'
import { accountActions, selectStudyLanguageOrEnglish } from '../../../../state/slices/account-slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { NavigationButton } from '../navigation-button.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { langCodeToEnglishLanguageName } from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'
import { DialectCard } from './dialect-card.tsx'

export const ChooseDialectView = () => {
  const dispatch = useDispatch()
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const [selectedDialect, setSelectedDialect] = useState<DialectCode | null>(null)

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onPreviousClick = () => {
    dispatch(accountActions.resetStudyLanguage())
  }

  const onNextClick = () => {
    dispatch(accountActions.setDialect(selectedDialect))
  }

  const handleDialectClick = (dialect: DialectCode) => {
    if (selectedDialect === dialect) {
      setSelectedDialect(null)
    } else {
      setSelectedDialect(dialect)
    }
  }

  return (
    <div className='mb-32 flex w-full flex-col items-center gap-y-6 px-2 pt-8'>
      <div className='flex max-w-md flex-col items-center'>
        <h1 className='max-w-xl text-center text-4xl font-bold tracking-tighter'>
          Choose the dialect of {langCodeToEnglishLanguageName(studyLanguage)} you want to study
        </h1>
        <span className='text-md max-w-xl text-center tracking-tighter text-gray-400'>
          (You can always change it later)
        </span>
      </div>
      <div className='grid w-full grid-cols-1 gap-4 md:w-1/2 md:grid-cols-2 3xl:w-1/4'>
        {LANGUAGES_TO_DIALECT_MAP[studyLanguage].map((dialect) => (
          <DialectCard
            key={dialect}
            dialect={dialect}
            handleClick={handleDialectClick}
            isSelected={selectedDialect === dialect}
          />
        ))}
      </div>
      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-16 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full flex-row justify-between gap-x-4 px-4 py-4 md:w-1/2 3xl:w-1/4'>
          <NavigationButton onClick={onPreviousClick} className='w-1/2'>
            Previous
          </NavigationButton>
          <NavigationButton
            onClick={onNextClick}
            disabled={!selectedDialect}
            className='w-1/2 bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            Next
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
