import { ToggleGroup, ToggleGroupItem } from '../shadcn/toggle-group.tsx'
import { CircleFlag } from 'react-circle-flags'
import { FormDescription, FormItem, FormLabel } from '../shadcn/form.tsx'
import { DialectCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { ShadcnTooltip } from '../tooltip.tsx'
import { dialectCodeToDialectName } from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'
import { cn } from '../../utils/shadcn-utils.ts'

interface DialectSelectorProps {
  dialects: DialectCode[]
  selectedDialect: DialectCode
  onDialectSelect: (value: DialectCode) => void
}

const getCountryCode = (dialect: DialectCode): string => {
  const index = dialect.indexOf('-')
  return dialect.slice(index + 1)
}

// we need a special function because Scottish English has the country code 'GB-SCT', which looks ugly in the UI
const getCountryAbbreviation = (dialect: DialectCode): string => {
  const parts = dialect.split('-')
  return parts[parts.length - 1]
}

const DialectSelector = ({ dialects, selectedDialect, onDialectSelect }: DialectSelectorProps) => {
  return (
    <FormItem className='space-y-2'>
      <FormLabel className='text-base font-semibold'>My preferred accent:</FormLabel>
      <ToggleGroup
        type='single'
        value={selectedDialect}
        onValueChange={onDialectSelect}
        className='flex w-full flex-wrap justify-start gap-2'
      >
        {dialects.map((dialect) => (
          <div key={dialect}>
            <ShadcnTooltip content={dialectCodeToDialectName(dialect)} side='top'>
              <ToggleGroupItem
                variant='outline'
                value={dialect}
                aria-label={`Toggle ${dialect}`}
                className={cn('flex h-9 w-20 items-center justify-center rounded-md border border-gray-300 px-3 py-2', {
                  'bg-gray-200 shadow': selectedDialect === dialect,
                })}
              >
                <CircleFlag countryCode={getCountryCode(dialect).toLowerCase()} className={'h-5 bg-transparent'} />
                <div className='ml-2 text-sm'>{getCountryAbbreviation(dialect)}</div>
              </ToggleGroupItem>
            </ShadcnTooltip>
          </div>
        ))}
      </ToggleGroup>
      <FormDescription className='text-sm text-gray-400'>
        Note: some accents are in beta. The speed of the audio might vary.
      </FormDescription>
    </FormItem>
  )
}

export default DialectSelector
