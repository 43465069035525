import { useIsFetching, useMutation, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { WordPairWithAlignment, WordPairWithAlignmentAndIpaAndTransliteration } from './utils/types.ts'
import { getWordTranslation } from '../../../../transport/transport/api/translation/translation.ts'
import { useSelector } from 'react-redux'
import {
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectHasVoice,
  selectMotherLanguageOrEnglish,
  selectStudyLanguageOrEnglish,
} from '../../../../state/slices/account-slice.ts'
import {
  DialectCode,
  LangCode,
  LANGUAGES_WITH_TRANSLITERATION,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect, useRef, useState } from 'react'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { TranslateWordResponse } from '@shared/frontend-and-backend/body-types/translation/translation.types.ts'
import { Popover, PopoverContent, PopoverTrigger } from '../../../shadcn/popover.tsx'
import { MediaPlayerInstance } from '@vidstack/react'
import { EvaluationProps } from './types.ts'
import {
  addIpaAndTransliterationToPairsThatHaveExpectedWords,
  getEvaluationScoreInPercentage,
} from './utils/evaluation-utils.ts'
import { QueryKey } from '../../../../transport/transport/api/query-keys.ts'
import { GetAudioIndividualWordResponse } from '@shared/frontend-and-backend/body-types/pronunciation/get-audio.types.ts'
import {
  getGeneratedAudioIndividualWord,
  getGeneratedIpaTranscription,
} from '../../../../transport/transport/api/pronunciation/pronunciation.ts'
import { selectShouldShowIpa, selectShouldShowTransliteration } from '../../../../state/slices/preferences-slice.ts'
import { GetIpaResponse } from '@shared/frontend-and-backend/body-types/pronunciation/transcribe-ipa.types.ts'
import {
  ActualWord,
  EmptySlotForActualWord,
  EmptySlotForExpectedWord,
  ExpectedWord,
  PlayWordNoContextButton,
} from './evaluation-atoms.tsx'
import { CopyableIpaWord, CopyableTranslation, CopyableTransliteratedWord, NarrowSkeleton } from '../exercise-atoms.tsx'
import { TransliterationResponse } from '@shared/frontend-and-backend/body-types/transliteration/transliteration.types.ts'
import { getTransliteration } from '../../../../transport/transport/api/transliteration/translation.ts'
import {
  removePunctuationFromBeginningAndEnd,
  splitByEmptySpacesAndMergeLonelyPunctuation,
} from '@shared/frontend-and-backend/utils/text-utils.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { postUserPronunciations } from '../../../../transport/transport/api/learned-words/words.ts'
import {
  AddUserPronunciationsResponse,
  UserPronunciation,
} from '@shared/frontend-and-backend/body-types/words/words.types.ts'
import { logWithRollbar } from '../../../../analytics/rollbar/log-with-rollbar.ts'
import { Download } from 'lucide-react'
import { handleDownload } from '../audio-player/audio-player-utils.ts'
import { Score } from './score/score.tsx'
import { UserWordsData } from '../../../../transport/transport/api/learned-words/words-hooks.ts'
import { AddOrDeleteFromSavedWordsSection } from './add-or-delete-from-saved-words-section.tsx'
import { Separator } from '../../../design-system/separator.tsx'

// we want to be sure that the user actually pronounced something. This protects us from saving tons of bad pronunciations
// in the case users just click on "record" button just to listen to their clone
// an equivalent restriction exists in the backend
const MINIMUM_SCORE_FOR_STORING_PRONUNCIATIONS = 0.4

const useAddUserPronunciations = (
  accessToken: string,
  text: string,
  userPronunciations: UserPronunciation[],
  studyLanguage: LangCode,
  score: number
) => {
  const queryClient = useQueryClient()
  return useMutation<ResponseWrapper<AddUserPronunciationsResponse>, Error>({
    mutationFn: () => postUserPronunciations(accessToken, text, userPronunciations, studyLanguage, score),
    onError: (error: Error) => {
      logWithRollbar(`useAddWordsMutation error, studyLanguage - ${studyLanguage}, error - ${error}`)
    },
    onSuccess: (data) => {
      queryClient.setQueryData<UserWordsData>([QueryKey.USER_WORDS], data.data)
    },
  })
}

export const Evaluation = ({
  wordPairsWithAlignment,
  generatedAudioPlayerRef,
  timeSliderRef,
  text,
}: EvaluationProps) => {
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: SupportedStudyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const motherLanguage: LangCode = useSelector(selectMotherLanguageOrEnglish)
  const hasVoice: boolean = useSelector(selectHasVoice)
  const dialect: DialectCode = useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect)

  const [textToTranslate, setTextToTranslate] = useState<string | null>(null)
  const individualWordPlayerRef = useRef<MediaPlayerInstance>(null)
  const shouldShowIpa = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration =
    useSelector(selectShouldShowTransliteration) && LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage)

  const scoreInPercentage: number = getEvaluationScoreInPercentage(wordPairsWithAlignment)
  const scoreAsNumberFrom0To1: number = scoreInPercentage / 100
  const expectedWordsWithAlignment: WordPairWithAlignment[] = wordPairsWithAlignment.filter(
    (pair) => pair.expectedWord !== null
  )
  const contextWords: string[] = expectedWordsWithAlignment.map((pair) => pair.expectedWord as string)
  const [selectedWordIndex, setSelectedWordIndex] = useState<number>(0)

  const { data: translationData, error: translationError } = useQuery<ResponseWrapper<TranslateWordResponse>>({
    queryKey: [
      QueryKey.TRANSLATE_WORD,
      textToTranslate,
      motherLanguage,
      studyLanguage,
      contextWords,
      selectedWordIndex,
      accessToken,
    ],
    queryFn: () =>
      getWordTranslation(textToTranslate!, studyLanguage, motherLanguage, contextWords, selectedWordIndex, accessToken),
    enabled: !!textToTranslate,
    staleTime: Infinity,
  })

  const {
    data: audioIndividualWordData,
    error: audioIndividualWordError,
  }: UseQueryResult<ResponseWrapper<GetAudioIndividualWordResponse>> = useQuery({
    queryKey: [
      QueryKey.AUDIO_INDIVIDUAL_WORD,
      hasVoice,
      textToTranslate,
      accessToken,
      studyLanguage,
      dialect,
      contextWords,
      selectedWordIndex,
    ],
    queryFn: () =>
      getGeneratedAudioIndividualWord(
        textToTranslate!,
        studyLanguage,
        dialect,
        contextWords,
        selectedWordIndex,
        accessToken
      ),
    enabled: hasVoice && !!textToTranslate && !!studyLanguage,
  })

  const userPronunciations: UserPronunciation[] = wordPairsWithAlignment
    .filter((pair) => pair.expectedWord !== null && pair.confidence !== null)
    .map((pair) => ({
      wordWithoutPunctuation: removePunctuationFromBeginningAndEnd(pair.expectedWord as string),
      confidence: pair.confidence as number,
    }))

  const { mutate: addWords } = useAddUserPronunciations(
    accessToken,
    text,
    userPronunciations,
    studyLanguage,
    scoreAsNumberFrom0To1
  )

  // todo: This is one of the most questionable parts of our code.
  // the correct solution is to add user pronunciations on the backend, right after deepgram transcription
  // I did it this way because it does not delay the user's experience, and it's easier to test a standalone
  // POST api/v1/words endpoint
  // There are at least two problems with this approach:
  // 1. if one day wee have a bug and call this endpoint to many times (for example, we fuck up the dependency array in the useEffect hook),
  //    we'll have a lot of words saved in the db.
  //    There are some checks in place on the backend to prevent this, i.e. we rate limit this with 1 request per second
  // 2. it's easy to call this endpoint programmatically, a hacker could raise the number of words. This might be a problem,
  //    once we have a leaderboard
  useEffect(() => {
    if (text && scoreAsNumberFrom0To1 >= MINIMUM_SCORE_FOR_STORING_PRONUNCIATIONS) {
      addWords()
    }
  }, [text])

  const {
    data: ipaTranscriptionData,
    isFetching: isFetchingIpa,
    error: ipaTranscriptionError,
  } = useQuery<ResponseWrapper<GetIpaResponse>>({
    // we do not need to include accessToken in the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QueryKey.IPA_TRANSCRIPTION, text, motherLanguage, studyLanguage, dialect],
    queryFn: () => getGeneratedIpaTranscription(text, studyLanguage, dialect, accessToken),
    enabled: !!text,
    staleTime: Infinity,
  })

  const {
    data: transliterationData,
    isFetching: isFetchingTransliteration,
    error: transliterationError,
  } = useQuery<ResponseWrapper<TransliterationResponse>>({
    // we do not need to include accessToken in the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QueryKey.TRANSLITERATION, text, motherLanguage, studyLanguage, dialect],
    queryFn: () => getTransliteration(text, studyLanguage, accessToken),
    enabled: !!text && LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage),
    staleTime: Infinity,
  })

  const audioIndividualWord: string | null = audioIndividualWordData?.data?.audio ?? null

  useApiErrorHandler(
    translationError,
    `Translation error in evaluation component: ${studyLanguage}, ${motherLanguage}, ${textToTranslate}, ${translationError}`,
    'There was a translation error. Please try again.'
  )
  useApiErrorHandler(
    audioIndividualWordError,
    `Individual audio generation error in evaluation component: ${studyLanguage}, ${motherLanguage}, ${textToTranslate}, ${audioIndividualWordError}`,
    'There was an error when generating the audio of the word. Please try again.'
  )
  useApiErrorHandler(
    ipaTranscriptionError,
    `IPA transcription error in evaluation component: ${studyLanguage}, ${motherLanguage}, ${textToTranslate}, ${ipaTranscriptionError}`
  )
  useApiErrorHandler(
    transliterationError,
    `Transliteration error in evaluation component: ${studyLanguage}, ${motherLanguage}, ${textToTranslate}, ${transliterationError}`
  )

  const translation: string | undefined = translationData?.data?.translation

  const handleTap = (text: string | null, wordIndex: number) => {
    if (text) {
      setTextToTranslate(text)
      setSelectedWordIndex(wordIndex)
    }
  }

  const isFetchingAudioIndividualWord = useIsFetching({ queryKey: [QueryKey.AUDIO_INDIVIDUAL_WORD] })

  const onDownload = () => {
    if (audioIndividualWord) {
      handleDownload(audioIndividualWord, 'slow_individual_word_audio')
    }
  }

  const ipaTranscriptionWords: string[] = ipaTranscriptionData?.data?.ipaTranscription || []
  const transliterationWords: string[] | undefined = splitByEmptySpacesAndMergeLonelyPunctuation(
    transliterationData?.data?.transliteration || ''
  )

  const wordPairsWithAlignmentAndIpa: WordPairWithAlignmentAndIpaAndTransliteration[] =
    addIpaAndTransliterationToPairsThatHaveExpectedWords(
      wordPairsWithAlignment,
      ipaTranscriptionWords,
      transliterationWords
    )
  return (
    <div className='flex w-full flex-col items-center gap-2 md:max-w-4xl md:gap-4 lg:max-w-6xl'>
      <Score scoreInPercentage={scoreInPercentage} />
      <div className='mb-4 flex flex-wrap justify-center gap-x-2 gap-y-2 px-1 md:max-w-3xl md:gap-x-3 md:gap-y-4'>
        {wordPairsWithAlignmentAndIpa.map((w: WordPairWithAlignmentAndIpaAndTransliteration, index: number) => (
          <div key={index} className='flex flex-col gap-y-0'>
            {/*to understand this hell have a look at:*/}
            {/*https://www.notion.so/grammarians/Tons-of-divs-for-below-and-above-the-words-21efda77261a4161b2018f6470ff7803*/}
            {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) && !shouldShowTransliteration && (
              <div className='h-6' />
            )}
            {!shouldShowIpa && <div className='h-6' />}
            {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) && (
              <>
                {shouldShowTransliteration && w.transliteration && (
                  <CopyableTransliteratedWord text={w.transliteration} />
                )}
                {shouldShowTransliteration && !w.transliteration && <div className='h-6' />}
                {shouldShowTransliteration && isFetchingTransliteration && <NarrowSkeleton />}
              </>
            )}
            {shouldShowIpa && w.ipa && <CopyableIpaWord text={w.ipa} />}
            {shouldShowIpa && !w.ipa && <div className='h-6' />}
            {shouldShowIpa && isFetchingIpa && <NarrowSkeleton />}
            {w.expectedWord ? (
              <Popover>
                <PopoverTrigger>
                  <ExpectedWord
                    wordPairWithAlignment={w}
                    onClick={() =>
                      handleTap(
                        w.expectedWord,
                        expectedWordsWithAlignment.findIndex((pair) => pair === w)
                      )
                    }
                    generatedAudioPlayerRef={generatedAudioPlayerRef}
                    timeSliderRef={timeSliderRef}
                  />
                </PopoverTrigger>

                <PopoverContent className='min-w-[19rem] max-w-[24rem] bg-white shadow-lg'>
                  <div className='flex flex-col items-start gap-2 p-2'>
                    <CopyableTranslation translation={translation} />
                    <Separator className='my-2' />
                    <div className='flex w-full items-center justify-between gap-2'>
                      <div className='flex items-center gap-2'>
                        <PlayWordNoContextButton
                          audioIndividualWord={audioIndividualWord}
                          individualWordPlayerRef={individualWordPlayerRef}
                        />
                        <span className='whitespace-nowrap'>Slow pronunciation</span>
                      </div>
                      <button
                        onClick={onDownload}
                        disabled={!!isFetchingAudioIndividualWord}
                        className='flex h-10 w-10 items-center justify-center rounded-full hover:bg-gray-200 active:bg-gray-300 active:text-stone-900 disabled:cursor-not-allowed disabled:opacity-50'
                      >
                        <Download className='h-6 w-6 text-stone-700 hover:text-stone-900 active:text-stone-900' />
                      </button>
                    </div>
                    <AddOrDeleteFromSavedWordsSection
                      language={studyLanguage}
                      contextWords={contextWords}
                      wordIndex={selectedWordIndex}
                    />
                  </div>
                </PopoverContent>
              </Popover>
            ) : (
              <EmptySlotForExpectedWord />
            )}
            {w.actualWord ? (
              <ActualWord pair={w} onClick={() => handleTap(w.actualWord, index)} />
            ) : (
              <EmptySlotForActualWord />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
