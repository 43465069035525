import { NewUsersNotAllowedMessage } from '../../auth/sign-in-up/new-users-not-allowed-message.tsx'

export const NewRegistrationsClosedCurrentlyView = () => {
  return (
    <div className='mb-40 flex h-full w-full flex-col items-center justify-center gap-y-4 px-4 py-12 text-center sm:px-6 md:max-w-sm'>
      <div className='space-y-2'>
        <h1 className='text-3xl font-bold text-gray-800'>Registrations Closed</h1>
      </div>
      <NewUsersNotAllowedMessage />
    </div>
  )
}
