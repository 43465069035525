export const getMonthlyDiscountString = (discountPercentage: number, durationLimit: number | null): string | null => {
  if (!discountPercentage) {
    return null
  }
  if (!durationLimit) {
    return `${discountPercentage}% off with your referral forever!`
  }
  const pluralSuffix = durationLimit > 1 ? 's' : ''
  return `${discountPercentage}% off with your referral for ${durationLimit} month${pluralSuffix}!`
}

export const getYearlyDiscountString = (discountPercentage: number, durationLimit: number | null): string | null => {
  if (!discountPercentage) {
    return null
  }
  if (!durationLimit) {
    return `${discountPercentage}% off with your referral forever!`
  }

  if (durationLimit === 1) {
    return `${discountPercentage}% off with your referral for 1 year!`
  }

  return `${discountPercentage}% off with your referral for ${durationLimit} years!`
}
