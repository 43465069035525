import { useDispatch, useSelector } from 'react-redux'
import { selectAccountAccessToken, selectHasFinishedOnboarding } from '../../../../state/slices/account-slice.ts'
import { Alert, AlertDescription, AlertTitle } from '../../../shadcn/alert.tsx'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  createCustomerPortalSession,
  getSubscriptionDetails,
} from '../../../../transport/transport/api/payments/subscription-account.ts'
import { useApiErrorHandler } from '../../../../hooks/use-api-error-handler.ts'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { UPGRADE_PLAN_MODAL_ID } from '../../modal-ids.ts'
import { Skeleton } from '../../../shadcn/skeleton.tsx'
import { Badge } from '../../../shadcn/badge.tsx'
import { logWithRollbar } from '../../../../analytics/rollbar/log-with-rollbar.ts'
import { toast } from 'sonner'
import { useLocation } from 'react-router-dom'
import { QueryKey } from '../../../../transport/transport/api/query-keys.ts'
import { getRemainingTrialDays } from '../../../../utils/payment-utils.ts'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { Button } from '../../../design-system/button.tsx'
import { Separator } from '../../../design-system/separator.tsx'

const getStatusDisplay = (status: string, plan: string) => {
  switch (status) {
    case 'active':
      return { text: 'Active', color: 'bg-green-100 text-green-800' }
    case 'trialing':
      return plan === 'free_trial'
        ? { text: 'Free Trial', color: 'bg-blue-100 text-blue-800' }
        : { text: 'Active (Trial)', color: 'bg-green-100 text-green-800' }
    case 'past_due':
      return { text: 'Past Due', color: 'bg-red-100 text-red-800' }
    case 'canceled':
      return { text: 'Canceled', color: 'bg-gray-100 text-gray-800' }
    case 'unpaid':
      return { text: 'Unpaid', color: 'bg-red-100 text-red-800' }
    case 'incomplete_expired':
      return { text: 'Incomplete', color: 'bg-orange-100 text-orange-800' }
    case 'incomplete':
      return { text: 'Incomplete', color: 'bg-orange-100 text-orange-800' }
    case 'paused':
      return { text: 'Paused', color: 'bg-purple-100 text-purple-800' }
    default:
      return { text: 'Unknown', color: 'bg-gray-100 text-gray-800' }
  }
}

const formatPlanDisplay = (plan: string): string => {
  switch (plan) {
    case 'year':
      return 'Annual Premium Plan'
    case 'month':
      return 'Monthly Premium Plan'
    case 'free_trial':
      return 'Free Trial'
    default:
      return 'Premium Plan'
  }
}

const formatDate = (dateString: string | null): string => {
  if (!dateString) {
    return 'N/A'
  }
  return new Date(dateString).toLocaleDateString()
}

const getCancellationMessage = (endDate: string | null): string => {
  if (!endDate) return 'Your subscription is canceled.'
  const now = new Date()
  const endDateTime = new Date(endDate)
  if (now > endDateTime) {
    return `Your subscription is canceled. Access ended on: ${formatDate(endDate)}`
  } else {
    return `Your subscription is canceled. Access ends on: ${formatDate(endDate)}`
  }
}

export const SubscriptionContent = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const dispatch = useDispatch()
  const location = useLocation()
  const hasFinishedOnboarding = useSelector(selectHasFinishedOnboarding)

  const {
    data: subscriptionData,
    isLoading,
    error,
  } = useQuery({
    queryKey: [QueryKey.SUBSCRIPTION_DETAILS, accessToken],
    queryFn: () => getSubscriptionDetails(accessToken),
    enabled: !!accessToken && hasFinishedOnboarding,
  })
  const status = subscriptionData?.data?.details?.status
  const plan = subscriptionData?.data?.details?.lastActivePlan
  const subscriptionInfo = subscriptionData?.data
  const isSpecialUserWithFullAccess = !!subscriptionInfo?.isSpecialUserWithFullAccess
  const statusDisplay = getStatusDisplay(
    subscriptionInfo?.details?.status ?? '',
    subscriptionInfo?.details?.lastActivePlan ?? ''
  )

  useApiErrorHandler(error, 'Error loading subscription details')

  const customerPortalMutation = useMutation({
    mutationFn: ({ returnPath }: { returnPath: string }) => createCustomerPortalSession(accessToken, returnPath),
    onSuccess: (data) => {
      if (data.data?.url) {
        window.location.href = data.data.url
      }
    },
    onError: (error) => {
      toast.error(
        'Unable to access your billing section at the moment. Please try again later or contact support if the issue persists.'
      )
      logWithRollbar(`Error creating customer portal session: ${error}`)
    },
  })

  const handleManageSubscription = () => {
    const currentPath = location.pathname + location.search
    if (plan === 'free_trial' || status === 'canceled') {
      POSTHOG_EVENTS.click('upgrade_plan_button')
      dispatch(modalActions.openModal(UPGRADE_PLAN_MODAL_ID))
    } else {
      POSTHOG_EVENTS.click('manage_subscription_button')
      customerPortalMutation.mutate({ returnPath: currentPath })
    }
  }

  const renderSpecialUserInfo = () => {
    if (!subscriptionInfo) {
      return null
    }
    return (
      <>
        <p className='text-sm text-gray-600'>You have a special access to all the premium features.</p>
        <Button
          onClick={() => {
            POSTHOG_EVENTS.click('upgrade_plan_button')
            dispatch(modalActions.openModal(UPGRADE_PLAN_MODAL_ID))
          }}
          className='h-10 w-full bg-indigo-600 py-2 font-medium text-white'
          disabled={customerPortalMutation.isPending}
        >
          {customerPortalMutation.isPending ? 'Loading...' : 'Upgrade Plan'}
        </Button>
      </>
    )
  }

  const renderSubscriptionInfo = () => {
    if (!subscriptionInfo?.details) {
      return null
    }

    const { lastActivePlan, status, currentPeriodEnd, cancelAtPeriodEnd, trialEnd } = subscriptionInfo.details
    const remainingTrialDays = getRemainingTrialDays(trialEnd)

    return (
      <>
        <div className='flex items-center space-x-3'>
          <Badge variant='secondary' className={`rounded-xl text-sm font-medium ${statusDisplay.color}`}>
            {statusDisplay.text}
          </Badge>
          <span className='text-base font-medium text-stone-900'>{formatPlanDisplay(lastActivePlan)}</span>
        </div>
        {lastActivePlan === 'free_trial' && remainingTrialDays > 0 && (
          <p className='text-sm text-gray-600'>
            You have {remainingTrialDays} day{remainingTrialDays !== 1 ? 's' : ''} left in your free trial.
          </p>
        )}
        {lastActivePlan !== 'free_trial' && (
          <>
            {cancelAtPeriodEnd ? (
              <p className='text-sm text-red-600'>{getCancellationMessage(currentPeriodEnd)}</p>
            ) : currentPeriodEnd ? (
              <p className='text-sm text-gray-600'>Next billing date: {formatDate(currentPeriodEnd)}</p>
            ) : null}
          </>
        )}
        <Button
          onClick={handleManageSubscription}
          className='h-10 w-full bg-indigo-600 py-2 font-medium text-white'
          disabled={customerPortalMutation.isPending}
        >
          {customerPortalMutation.isPending
            ? 'Loading...'
            : lastActivePlan === 'free_trial' || status === 'canceled'
              ? 'Upgrade Plan'
              : 'Manage Subscription'}
        </Button>
      </>
    )
  }

  return (
    <>
      <div className='space-y-4'>
        <h3 className='text-xl font-semibold text-stone-900'>Subscription Status</h3>
        {isLoading ? (
          <>
            <Skeleton className='h-8 w-full' />
            <Skeleton className='h-6 w-3/4' />
            <Skeleton className='h-10 w-full' />
          </>
        ) : error ? (
          <Alert variant='destructive'>
            <AlertTitle>Error</AlertTitle>
            <AlertDescription>Failed to load subscription details. Please try again later.</AlertDescription>
          </Alert>
        ) : isSpecialUserWithFullAccess ? (
          renderSpecialUserInfo()
        ) : (
          renderSubscriptionInfo()
        )}
      </div>
      <Separator className='my-1' />
    </>
  )
}
