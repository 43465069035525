import { LangCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types'
import {
  DeleteFromSavedWordsRequest,
  DeleteFromSavedWordsResponse,
  GetSavedWordsResponse,
  PutSavedWordRequest,
  PutSavedWordResponse,
  GetSavedWordsRequestParams,
} from '@shared/frontend-and-backend/body-types/saved-words/saved-words.types'
import { customFetch } from '../../utils.ts'
import { getSavedWordsUrl, savedWordsUrl } from '../backend-urls.ts'
import { getConfig } from '../../../../config/environment-config.ts'

export const putSavedWord = async (
  accessToken: string,
  language: LangCode,
  contextWords: string[],
  wordIndex: number
): Promise<ResponseWrapper<PutSavedWordResponse>> => {
  const body: PutSavedWordRequest = { contextWords, wordIndex, language }
  const response: Response = await customFetch(savedWordsUrl, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const deleteSavedWord = async (
  accessToken: string,
  language: LangCode,
  contextWords: string[],
  wordIndex: number
): Promise<ResponseWrapper<DeleteFromSavedWordsResponse>> => {
  const body: DeleteFromSavedWordsRequest = { language, contextWords, wordIndex }
  const response: Response = await customFetch(savedWordsUrl, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const deleteSavedWordByProvidingItsOrthographicForm = async (
  accessToken: string,
  orthographicForm: string,
  language: LangCode
): Promise<ResponseWrapper<DeleteFromSavedWordsResponse>> => {
  const body: DeleteFromSavedWordsRequest = { language, orthographicForm }
  const response: Response = await customFetch(savedWordsUrl, {
    method: 'DELETE',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}

export const getSavedWords = async (
  accessToken: string,
  cursor?: string,
  languageFilter?: SupportedStudyLanguage
): Promise<ResponseWrapper<GetSavedWordsResponse>> => {
  const params: GetSavedWordsRequestParams = {
    cursor,
    limit: getConfig().paginationLimit,
    language: languageFilter,
  }

  const response: Response = await customFetch(getSavedWordsUrl(params), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}
