import { GetExerciseResponse } from '@shared/frontend-and-backend/body-types/exercise/get-text.types.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { useApiErrorHandler } from '../../../hooks/use-api-error-handler.ts'
import {
  selectAccountAccessToken,
  selectDialectOrDefaultDialectOrEnglishDefaultDialect,
  selectStudyLanguageOrEnglish,
} from '../../../state/slices/account-slice.ts'
import { getSentenceFromFrequencyList } from '../../../transport/transport/api/pronunciation/pronunciation.ts'
import { QueryKey } from '../../../transport/transport/api/query-keys.ts'
import { Skeleton } from '../../shadcn/skeleton.tsx'
import { Exercise } from '../exercise/exercise.tsx'
import { TextForExercise } from '../exercise/text-for-exercise.tsx'
import { useFrequencySliderPosition } from '../../../transport/transport/api/users/users-hooks.ts'
import { FrequencyListExerciseControls } from '../exercise/controls/frequency-list-exercise-controls.tsx'
import { BigCard } from '../../design-system/big-card.tsx'

export const FrequencyListExerciseView = () => {
  const accessToken: string = useSelector(selectAccountAccessToken)
  const studyLanguage: LangCode = useSelector(selectStudyLanguageOrEnglish)
  const dialect = useSelector(selectDialectOrDefaultDialectOrEnglishDefaultDialect)
  const frequencyListPosition = useFrequencySliderPosition(studyLanguage)
  const queryClient = useQueryClient()

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const {
    data: textData,
    isFetching: isFetchingText,
    refetch: refetchText,
    error: textError,
  }: UseQueryResult<ResponseWrapper<GetExerciseResponse>> = useQuery({
    queryKey: [QueryKey.EXERCISE_FREQUENCY_LIST, studyLanguage, accessToken, dialect, frequencyListPosition],
    queryFn: () => getSentenceFromFrequencyList(studyLanguage, frequencyListPosition, accessToken),
    enabled: true,
    staleTime: 0,
  })

  const expectedText: string = textData?.data?.text ?? ''

  useEffect(() => {
    if (expectedText) {
      queryClient.setQueryData<string>([QueryKey.EXERCISE_TEXT], expectedText)
    }
  }, [expectedText, queryClient])

  const data = textData?.data
  if (data) {
    const savedWords: { word: string; language: LangCode }[] = data.wordsFromExerciseThatAreSaved
    savedWords.forEach(({ word, language }) => {
      if (word) {
        queryClient.setQueryData<boolean>([QueryKey.SAVED_WORDS, word, language], true)
      }
    })
  }

  useApiErrorHandler(
    textError,
    `Error generating text for frequency list exercise: ${studyLanguage}, ${dialect}, ${textError}`
  )

  return (
    <div className='flex w-full flex-1 flex-col items-center pt-2 md:p-8 2xl:p-16'>
      <BigCard className='container flex flex-1 flex-col items-center'>
        <FrequencyListExerciseControls />
        {isFetchingText ? (
          <div className='mt-8 flex flex-col items-center justify-center md:max-w-3xl'>
            <h1 className='text-center text-3xl font-bold text-gray-600'>Getting your next exercise ready...</h1>
            <div className='mt-8 flex w-full flex-col items-center justify-center gap-y-2 px-2'>
              <Skeleton className='h-8 w-full md:max-w-4xl' />
              <Skeleton className='h-8 w-full md:max-w-4xl' />
              <Skeleton className='h-8 w-full md:max-w-4xl' />
              <Skeleton className='mt-10 h-20 w-20 rounded-full' />
            </div>
          </div>
        ) : (
          <Exercise
            expectedText={expectedText}
            onTryAnotherTextClick={() => {
              refetchText().then(() => {})
            }}
            textOnTryAnotherTextButton='Try another sentence'
          >
            <TextForExercise text={expectedText} />
          </Exercise>
        )}
      </BigCard>
    </div>
  )
}
