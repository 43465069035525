import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectIsUserInfoFromAllSourcesLoaded, selectReferral, selectUserId } from '../state/slices/account-slice.ts'
import { getConfig } from '../config/environment-config.ts'
import { initializeRollbar } from './rollbar/rollbar-initializer.ts'
import { initializeFullstory } from './fullstory/fullstory-initializer.ts'
import { initializeAmplitude } from './amplitude/amplitude-initializer.ts'
import { identifyUserForPosthog, initializePosthog } from './posthog/posthog-initializer.ts'
import { initializeSentry } from './sentry/sentry-initializer.ts'
import * as Sentry from '@sentry/react'

export const AnalyticsInitializer = () => {
  const userId = useSelector(selectUserId)
  const iIsUserInfoFromAllSourcesLoaded = useSelector(selectIsUserInfoFromAllSourcesLoaded)
  const referral = useSelector(selectReferral)

  useEffect(() => {
    if (getConfig().posthogToken) {
      initializePosthog()
    }
  }, [])

  useEffect(() => {
    // todo eventually all analytics and error logging sdks should have two steps
    // initialization and identification just like posthog, this way we can log/analyze errors even if the user is
    // not logged in yet
    if (userId && iIsUserInfoFromAllSourcesLoaded) {
      if (getConfig().posthogToken) {
        identifyUserForPosthog(userId, referral)
      }
    }
  }, [iIsUserInfoFromAllSourcesLoaded, userId, referral])

  useEffect(() => {
    if (userId) {
      if (getConfig().rollbarPostClientItemAccessToken) {
        initializeRollbar(userId)
      }
      if (getConfig().fullstoryOrganizationId) {
        initializeFullstory(userId)
      }
      if (getConfig().amplitudeApiKey) {
        initializeAmplitude(userId)
      }
    }
    // initializing sentry twice throws an error
    if (getConfig().sentryDsn && !Sentry.isInitialized()) {
      initializeSentry()
    }
    if (userId) {
      Sentry.setUser({ id: userId })
    }
  }, [userId])

  return <></>
}
