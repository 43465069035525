import { Exercise } from '../exercise/exercise.tsx'
import { ChangeEvent, useEffect, useState } from 'react'
import { Textarea } from '../../shadcn/textarea.tsx'
import { Check, ChevronDown, CircleHelp, GraduationCap } from 'lucide-react'
import { toast } from 'sonner'
import { Button as DesignSystemButton } from '../../design-system/button.tsx'
import { ROUTE_PATHS } from '../../../routing/route-paths.ts'
import { useNavigate } from 'react-router-dom'
import { TextForExercise } from '../exercise/text-for-exercise.tsx'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { QueryKey } from '../../../transport/transport/api/query-keys.ts'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountActions,
  selectAccountAccessToken,
  selectStudyLanguageOrEnglish,
} from '../../../state/slices/account-slice.ts'
import { getTextLanguage } from '../../../transport/transport/api/language-detection/language-detection.ts'
import { DetectStudyLanguageResponse } from '@shared/frontend-and-backend/body-types/language-detection/language-detection.types.ts'
import {
  LangCode,
  SUPPORTED_STUDY_LANGUAGES,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { langCodeToEnglishLanguageName } from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'
import { Popover, PopoverContent, PopoverTrigger } from '../../shadcn/popover.tsx'
import { FormControl, FormItem } from '../../shadcn/form.tsx'
import { cn } from '../../../utils/shadcn-utils.ts'
import { CircleFlagLanguage } from 'react-circle-flags'
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '../../shadcn/command.tsx'
import { Button } from '../../shadcn/button.tsx'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { CustomExerciseControls } from '../exercise/controls/custom-exercise-controls.tsx'
import { BigCard } from '../../design-system/big-card.tsx'

const studyLanguageOptions = SUPPORTED_STUDY_LANGUAGES.map((langCode: SupportedStudyLanguage) => ({
  label: langCodeToEnglishLanguageName(langCode),
  value: langCode,
}))

const FormSchema = z.object({
  studyLanguage: z.nativeEnum(LangCode, {
    required_error: 'Please select a language.',
  }),
})

type FormInputs = z.infer<typeof FormSchema>

export const CustomExerciseView = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [text, setText] = useState('')
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const accessToken = useSelector(selectAccountAccessToken)
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const queryClient = useQueryClient()

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value)
  }

  useEffect(() => {
    if (text && hasSubmitted) {
      queryClient.setQueryData([QueryKey.EXERCISE_TEXT], text)
    }
  }, [text, hasSubmitted])

  const { data: languageDetectionData, isFetching: isFetchingLanguageDetection } = useQuery<
    ResponseWrapper<DetectStudyLanguageResponse>
  >({
    // we do not need to include accessToken in the query key
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [QueryKey.LANGUAGE_DETECTION, text],
    queryFn: () => getTextLanguage(text, accessToken),
    enabled: !!text,
    staleTime: Infinity,
  })

  const onSubmitClick = () => {
    if (text.length < 2) {
      toast.warning('Text to practice must be at least 2 characters long')
    } else if (text.length >= 750) {
      toast.warning('Text to practice must be shorter than 750 characters')
    } else {
      setHasSubmitted(true)
    }
  }

  const hasDetectedAStudyLanguage: boolean = languageDetectionData?.data?.hasDetectedAStudyLanguage ?? false
  const confidence: number = languageDetectionData?.data?.confidence ?? 0
  const detectedStudyLanguage: SupportedStudyLanguage | undefined = languageDetectionData?.data?.studyLanguage

  const form = useForm<FormInputs>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      studyLanguage: studyLanguage,
    },
  })

  const shouldProposeToSwitchLanguage =
    !isFetchingLanguageDetection &&
    hasDetectedAStudyLanguage &&
    confidence > 0.3 &&
    detectedStudyLanguage &&
    detectedStudyLanguage !== studyLanguage
  return (
    // <div className='mt-8 flex w-full flex-1 flex-col items-center p-1 md:p-2'>
    <div className='flex w-full flex-1 flex-col items-center pt-2 md:p-8 2xl:p-16'>
      <BigCard className='container flex flex-1 flex-col items-center'>
        {!hasSubmitted && (
          <>
            <div className='flex h-full w-full flex-col gap-y-4 md:w-[450px]'>
              <h1 className='mb-4 mt-10 text-center text-4xl font-bold tracking-tighter text-stone-900 md:mt-12'>
                Enter text to practice
              </h1>
              <div className='flex w-full flex-row items-center justify-between gap-x-2'>
                <div className='flex items-center gap-x-2'>
                  <FormProvider {...form}>
                    <form className='space-y-8' onSubmit={() => {}}>
                      <Controller
                        name='studyLanguage'
                        render={({ field }) => (
                          <FormItem className='flex flex-row items-center gap-x-4'>
                            <div className='flex flex-col items-start gap-3'>
                              <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={true}>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant='outline'
                                      role='combobox'
                                      className={cn(
                                        'w-[200px] justify-between rounded-xl',
                                        !field.value && 'text-muted-foreground',
                                        'text-gray-500'
                                      )}
                                    >
                                      <div className='flex flex-row items-center gap-2'>
                                        <CircleFlagLanguage
                                          languageCode={studyLanguage}
                                          className={'h-5 bg-transparent'}
                                        />
                                        {langCodeToEnglishLanguageName(studyLanguage)}
                                      </div>
                                      <ChevronDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent className='h-[250px] w-[200px] p-0' side='bottom'>
                                  <Command className='bg-white'>
                                    <CommandInput placeholder='Search language...' className='h-9' />
                                    <CommandEmpty>No language found.</CommandEmpty>
                                    <CommandList>
                                      <CommandGroup>
                                        {studyLanguageOptions.map(({ label, value }) => (
                                          <CommandItem
                                            className={cn('cursor-pointer hover:bg-gray-100')}
                                            value={label}
                                            key={value}
                                            onSelect={() => {
                                              field.onChange(value)
                                              dispatch(accountActions.setStudyLanguageAndDefaultDialect(value))
                                              setIsPopoverOpen(false)
                                            }}
                                          >
                                            <div className='flex flex-row items-center gap-2'>
                                              <CircleFlagLanguage
                                                languageCode={value}
                                                className={'h-5 bg-transparent'}
                                              />
                                              {label}
                                            </div>
                                            <Check
                                              className={cn(
                                                'ml-auto h-4 w-4',
                                                value === field.value ? 'opacity-100' : 'opacity-0'
                                              )}
                                            />
                                          </CommandItem>
                                        ))}
                                      </CommandGroup>
                                    </CommandList>
                                  </Command>
                                </PopoverContent>
                              </Popover>
                            </div>
                          </FormItem>
                        )}
                      />
                    </form>
                  </FormProvider>
                  <Popover>
                    <PopoverTrigger>
                      <CircleHelp className='h-4 w-4 text-stone-400' />
                    </PopoverTrigger>
                    <PopoverContent className='bg-white text-center text-sm shadow-lg'>
                      We will try to detect the language of your text automatically but it's better if you select it
                      manually.
                    </PopoverContent>
                  </Popover>
                  <div className='flex h-8 items-center'>
                    {shouldProposeToSwitchLanguage && (
                      <span className='text-sm text-gray-400'>
                        switch to:{' '}
                        <a
                          className='cursor-pointer text-indigo-600'
                          onClick={() => {
                            dispatch(accountActions.setStudyLanguageAndDefaultDialect(detectedStudyLanguage))
                          }}
                        >
                          {langCodeToEnglishLanguageName(detectedStudyLanguage)}
                        </a>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <Textarea
                value={text}
                onChange={handleInputChange}
                className='h-60 w-full rounded-xl'
                placeholder='Type your text to practice here. Example: "Betty Botter bought some butter but she said the butter’s bitter."'
              />
              <DesignSystemButton
                onClick={onSubmitClick}
                className='bg-gradient-to-r from-indigo-500 to-indigo-500 text-white hover:from-indigo-600 hover:to-indigo-600 disabled:opacity-50'
              >
                <span>Start practicing</span>
                <GraduationCap className='ml-2 h-5' />
              </DesignSystemButton>
              <div className='h-8' />
            </div>
            <div className='h-8 flex-shrink-0' />
            <div className='flex w-full flex-col gap-y-4 md:w-[450px]'>
              <DesignSystemButton
                onClick={() => {
                  navigate(ROUTE_PATHS.DASHBOARD)
                }}
                className='border'
              >
                <GraduationCap className='mr-1 h-5' />
                <span>Try a different exercise</span>
              </DesignSystemButton>
            </div>
          </>
        )}
        {hasSubmitted && (
          <>
            <CustomExerciseControls />
            <Exercise
              expectedText={text}
              onTryAnotherTextClick={() => {
                setText('')
                setHasSubmitted(false)
              }}
              textOnTryAnotherTextButton='Try a different text'
            >
              <TextForExercise text={text} />
            </Exercise>
          </>
        )}
      </BigCard>
    </div>
  )
}
