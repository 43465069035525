import {
  GetUserResponse,
  PutUserRequest,
  UpdateUserResponse,
} from '@shared/frontend-and-backend/body-types/users/users.types.ts'
import { getCloneVoiceUrl, userUrl } from '../backend-urls.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { customFetch } from '../../utils.ts'
import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const cloneVoice = async (
  langCode: LangCode,
  accessToken: string,
  audioBlob: Blob
): Promise<ResponseWrapper<UpdateUserResponse>> => {
  const formData: FormData = new FormData()
  formData.append('audio', audioBlob, 'user-audio.mp3')

  const response = await customFetch(getCloneVoiceUrl(langCode), {
    method: 'PATCH',
    body: formData,
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const data = await response.json()
  return data
}

export const getOrCreateUser = async (
  accessToken: string,
  referral?: string
): Promise<ResponseWrapper<GetUserResponse>> => {
  const body: PutUserRequest = { referral }
  const response = await customFetch(userUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })
  const json = await response.json()
  return json
}
