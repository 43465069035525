import { Link } from 'react-router-dom'
import { Dice5, FileText, Layers, BookmarkIcon, VolumeX, Headphones, Globe, Timer, LucideIcon } from 'lucide-react'
import { ROUTE_PATHS } from '../../routing/route-paths.ts'
import { ExerciseCard } from './exercise-card.tsx'

type Exercise = {
  name: string
  description: string
  icon: LucideIcon
  route?: string
  comingSoon?: boolean
}

export const ExercisesList = () => {
  const exercises: Exercise[] = [
    {
      name: 'Level-based',
      description: 'Practice with words suited to your level',
      icon: Layers,
      route: ROUTE_PATHS.FREQUENCY_LIST_EXERCISE,
    },
    {
      name: 'Random',
      description: 'Practice with randomly selected sentences',
      icon: Dice5,
      route: ROUTE_PATHS.RANDOM_EXERCISE,
    },
    {
      name: 'Custom',
      description: 'Use your own text for targeted practice',
      icon: FileText,
      route: ROUTE_PATHS.CUSTOM_EXERCISE,
    },
    {
      name: 'Saved Words',
      description: 'Practice pronunciation of your saved words',
      icon: BookmarkIcon,
      comingSoon: true,
    },
    { name: 'Difficult Words', description: 'Focus on words you find challenging', icon: VolumeX, comingSoon: true },
    {
      name: 'Zen Mode',
      description: 'Listen and repeat sentences for relaxed practice',
      icon: Headphones,
      comingSoon: true,
    },
    { name: 'Theme-based', description: 'Practice vocabulary on specific topics', icon: Globe, comingSoon: true },
    { name: 'Speed Challenge', description: 'Improve your pronunciation speed', icon: Timer, comingSoon: true },
  ]

  return (
    <div className='grid w-full grid-cols-2 gap-1'>
      {exercises.map((exercise, index) =>
        exercise.route ? (
          <Link key={index} to={exercise.route} className='w-full'>
            <ExerciseCard name={exercise.name} description={exercise.description} icon={exercise.icon} />
          </Link>
        ) : (
          <ExerciseCard
            key={index}
            name={exercise.name}
            description={exercise.description}
            icon={exercise.icon}
            comingSoon={exercise.comingSoon}
          />
        )
      )}
    </div>
  )
}
