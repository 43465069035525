import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { accountActions, selectStudyLanguageOrEnglish } from '../../../state/slices/account-slice.ts'
import { Logo } from '../../design-system/logo'
import { Checkbox } from '../../shadcn/checkbox.tsx'
import { Mic, UserCheck } from 'lucide-react'
import { EXTERNAL_LINKS } from '../../../constants/external-links.ts'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { NavigationButton } from './navigation-button.tsx'
import { LANGUAGES_WITH_MULTIPLE_DIALECTS } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const TermsAndConditionsView = () => {
  const dispatch = useDispatch()
  const [isConsentChecked, setIsConsentChecked] = useState(false)
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)

  const handleStartCloning = () => {
    if (isConsentChecked) {
      dispatch(accountActions.setHasAcceptedTermsAndConditionsAndClickedNext(true))
    }
  }

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onPreviousClick = () => {
    if (studyLanguage && LANGUAGES_WITH_MULTIPLE_DIALECTS.includes(studyLanguage)) {
      dispatch(accountActions.setDialect(null))
    } else {
      dispatch(accountActions.resetStudyLanguage())
    }
  }

  return (
    <div className='flex flex-col'>
      <div className='md:mb-16'>
        <div className='mx-auto flex max-w-lg flex-col gap-y-4 px-4 py-2 md:gap-y-6 md:py-8'>
          <div className='hidden items-center space-x-4 md:flex'>
            <div className='flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600'>
              <Logo size={40} inverted={true} />
            </div>
            <h1 className='text-3xl font-bold leading-tight'>
              Get ready
              <br />
              to clone your voice
            </h1>
          </div>

          <div className='flex w-full justify-center md:hidden'>
            <h1 className='text-center text-3xl font-bold leading-tight'>
              Get ready
              <br />
              to clone your voice
            </h1>
          </div>

          <p className='text-gray-600'>
            You can <span className='font-bold text-indigo-600'>always</span> remove your voice clone later. It's not
            used by anyone outside the app.
          </p>

          <div className='space-y-4'>
            <div className='flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm'>
              <div className='flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100'>
                <Mic className='h-5 w-5 text-indigo-600' />
              </div>
              <div>
                <h2 className='text-lg font-semibold'>Step 1</h2>
                <p className='text-sm text-gray-600'>
                  In the next step, you'll read a short text aloud. For best results, ensure you're in a quiet
                  environment. You can make mistakes, they will not affect the voice cloning.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3 rounded-lg bg-white p-4 shadow-sm'>
              <div className='flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full bg-indigo-100'>
                <UserCheck className='h-5 w-5 text-indigo-600' />
              </div>
              <div>
                <h2 className='text-lg font-semibold'>Step 2</h2>
                <p className='text-sm text-gray-600'>
                  Your voice clone will be integrated into your learning experience, tailoring exercises just for you.
                </p>
              </div>
            </div>
          </div>

          <div className='flex items-start space-x-2'>
            <Checkbox
              id='consent'
              checked={isConsentChecked}
              onCheckedChange={(checked) => setIsConsentChecked(checked as boolean)}
            />
            <label htmlFor='consent' className='cursor-pointer text-sm text-gray-600'>
              I consent to having my voice cloned for personalized learning experiences within YourBestAccent.com, and I
              agree to the{' '}
              <a
                href={EXTERNAL_LINKS.TERMS_OF_SERVICE_URL}
                className='text-indigo-600 hover:underline'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={EXTERNAL_LINKS.PRIVACY_POLICY_URL}
                className='text-indigo-600 hover:underline'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy Policy
              </a>
              .
            </label>
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 left-0 right-0 mx-auto max-w-lg bg-gray-50 p-4'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-16 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='flex w-full flex-row items-center gap-x-4 md:gap-y-2 lg:max-w-xl'>
          <NavigationButton className='w-1/2' onClick={onPreviousClick}>
            Previous
          </NavigationButton>
          <NavigationButton
            onClick={handleStartCloning}
            disabled={!isConsentChecked}
            className='w-1/2 bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            Go to Voice Cloning
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
