import { ButtonHTMLAttributes, ReactNode } from 'react'
import { cn } from './style-utils'

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick?: () => void
  children: ReactNode
  className?: string
  shouldHaveHoverAndActiveStyles?: boolean
}

export const Button = ({
  onClick = () => {},
  children,
  className,
  shouldHaveHoverAndActiveStyles = true,
  ...rest
}: ButtonProps) => {
  return (
    <button
      {...rest}
      onClick={onClick}
      className={cn(
        'flex h-12 items-center justify-center rounded-xl px-2 transition-colors duration-200 disabled:cursor-auto disabled:opacity-50 md:px-4',
        {
          'cursor-pointer hover:brightness-95 active:brightness-90 disabled:hover:brightness-100 disabled:active:brightness-100':
            shouldHaveHoverAndActiveStyles,
        },
        {
          'cursor-not-allowed': !shouldHaveHoverAndActiveStyles,
        },
        className
      )}
    >
      {children}
    </button>
  )
}
