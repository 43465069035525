import { Button } from '../../design-system/button.tsx'
import { POSTHOG_EVENTS } from '../../../analytics/posthog/posthog-events.ts'
import { toast } from 'sonner'

export const NewUsersNotAllowedMessage = () => {
  const handleContactClick = () => {
    POSTHOG_EVENTS.click('contact_button')
    const emailBody = `Hi there, please let me know when the registrations are resumed.`
    const subject = 'Let me know when the registrations are resumed'
    const email = `yourbestaccent@contact.com`
    const mailToUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(emailBody || '')}`
    window.location.href = mailToUrl
  }

  const handleMessageClick = () => {
    POSTHOG_EVENTS.click('no_registrations_accepted_message')
    navigator.clipboard.writeText('contact@yourbestaccent.com').then(() => {
      toast.info(`'contact@yourbestaccent.com' copied to clipboard`)
    })
  }

  return (
    <>
      <div className='mb-4 w-full space-y-2'>
        <div
          className='w-full cursor-pointer rounded-md border border-blue-200 bg-red-100 p-4'
          onClick={handleMessageClick}
        >
          <p className='text-xs text-stone-900'>
            We are currently not accepting new users due to high demand. We will resume registrations around August
            27th. If you'd like to be notified when we reopen, please contact us at contact@yourbestaccent.com
          </p>
        </div>
        <Button onClick={handleContactClick} className='h-10'>
          Email Us
        </Button>
      </div>
      <p className='text-sm text-stone-900'>Or if you're already a user you can sign in below.</p>
    </>
  )
}
