import { type AudioSrc, MediaPlayer, MediaPlayerInstance, MediaProvider } from '@vidstack/react'
import { useEffect, useState } from 'react'
import { base64ToBlob, handleDownload } from './audio-player-utils.ts'
import { AudioPlayerProps } from './types.ts'
import '@vidstack/react/player/styles/default/theme.css'
import '@vidstack/react/player/styles/default/layouts/audio.css'
import { AudioLayout } from './audio-layout.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'

interface AudioPlayEvent extends CustomEvent<MediaPlayerInstance> {
  detail: MediaPlayerInstance
}

export const AudioPlayer = ({
  title,
  playerTypeForAnalytics,
  audioSource,
  sourceType,
  playerRef,
  timeSliderRef,
  isHeadless,
  audioKey,
  onCanPlay,
  playbackRate,
}: AudioPlayerProps) => {
  const [source, setSource] = useState<AudioSrc | null>(null)

  useEffect(() => {
    const initializeAudioSource = async () => {
      if (!audioSource) {
        setSource(null)
        return
      }
      let blob: Blob
      if (sourceType === 'base64' && typeof audioSource === 'string') {
        blob = base64ToBlob(audioSource)
      } else if (sourceType === 'blob' && audioSource instanceof Blob) {
        blob = audioSource
      } else {
        setSource(null)
        return
      }
      const url: string = URL.createObjectURL(blob)
      setSource({ src: url, type: 'audio/mpeg' })
      return () => {
        URL.revokeObjectURL(url)
      }
    }
    initializeAudioSource().then()
  }, [audioSource, sourceType, audioKey])

  useEffect(() => {
    const handleAudioPlayEvent = (event: AudioPlayEvent) => {
      if (playerRef?.current && playerRef?.current !== event.detail) {
        playerRef?.current.pause()
      }
    }

    window.addEventListener('audioPlay', handleAudioPlayEvent as EventListener)

    return () => {
      window.removeEventListener('audioPlay', handleAudioPlayEvent as EventListener)
    }
  }, [playerRef])

  const handlePlay = () => {
    POSTHOG_EVENTS.playAudio(playerTypeForAnalytics)
    window.dispatchEvent(new CustomEvent('audioPlay', { detail: playerRef?.current }))
  }

  const onDownload = () => {
    if (audioSource) {
      handleDownload(audioSource, 'sentence_audio')
    }
  }

  if (!source) {
    return null
  }

  return (
    <>
      {isHeadless ? (
        <MediaPlayer
          title={title}
          src={source}
          ref={playerRef}
          viewType='audio'
          onPlay={handlePlay}
          onCanPlay={onCanPlay}
          className='w-0'
          playbackRate={playbackRate}
        >
          <MediaProvider />
        </MediaPlayer>
      ) : (
        <MediaPlayer
          title={title}
          src={source}
          ref={playerRef}
          viewType='audio'
          className='ring-media-focus flex w-full flex-col gap-y-0 rounded-md data-[focus]:ring-4'
          onCanPlay={onCanPlay}
          onPlay={handlePlay}
          playbackRate={playbackRate}
        >
          <MediaProvider />
          <AudioLayout timeSliderRef={timeSliderRef} title={title} onDownload={onDownload} />
        </MediaPlayer>
      )}
    </>
  )
}
