import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ACCOUNT_MODAL_ID, DANGER_ZONE_MODAL_ID, UPGRADE_PLAN_MODAL_ID } from '../../components/modal/modal-ids.ts'
import { isHashEnabledModalId } from '../../components/modal/modal-utils.ts'

export interface ModalState {
  modalId: string
  isOpen: boolean
  isHashLoaded: boolean
}

const initialModalState: ModalState = {
  modalId: '',
  isOpen: false,
  isHashLoaded: false,
}

type OpenTranslationModalPayload = {
  modalId: string
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      state.isOpen = true
      state.modalId = action.payload
    },
    openTranslationModal: (state, action: PayloadAction<OpenTranslationModalPayload>) => {
      state.isOpen = true
      state.modalId = action.payload.modalId
    },
    closeModal: (state) => {
      if ([UPGRADE_PLAN_MODAL_ID, DANGER_ZONE_MODAL_ID].includes(state.modalId)) {
        state.modalId = ACCOUNT_MODAL_ID
      } else {
        state.isOpen = false
      }
    },
    setIsHashLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.isHashLoaded = payload
    },
  },
})
export const modalActions = modalSlice.actions

export const selectModalId = (state: RootState): string => state.modal.modalId
export const selectShouldUrlHaveModalHash = (state: RootState): boolean => {
  return state.modal.isOpen && isHashEnabledModalId(state.modal.modalId)
}
export const selectIsOpen = (state: RootState): boolean => state.modal.isOpen

export const selectIsHashLoaded = (state: RootState): boolean => state.modal.isHashLoaded

export default modalSlice.reducer
