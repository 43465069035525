import { LangCode, SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export type DemoData = {
  text: string
  author: string
  language: SupportedStudyLanguage
}

export const demoDataObjects: Record<SupportedStudyLanguage, DemoData> = {
  [LangCode.ENGLISH]: {
    text: "To be, or not to be, that is the question: Whether 'tis nobler in the mind to suffer The slings and arrows of outrageous fortune, Or to take arms against a sea of troubles And by opposing end them. To die: to sleep; No more; and by a sleep to say we end The heart-ache and the thousand natural shocks That flesh is heir to, 'tis a consummation Devoutly to be wish'd.",
    author: 'William Shakespeare, Hamlet',
    language: LangCode.ENGLISH,
  },
  [LangCode.SPANISH]: {
    text: 'En un lugar de la Mancha, de cuyo nombre no quiero acordarme, no ha mucho tiempo que vivía un hidalgo de los de lanza en astillero, adarga antigua, rocín flaco y galgo corredor. Una olla de algo más vaca que carnero, salpicón las más noches, duelos y quebrantos los sábados, lantejas los viernes, algún palomino de añadidura los domingos, consumían las tres partes de su hacienda.',
    author: 'Miguel de Cervantes, Don Quixote',
    language: LangCode.SPANISH,
  },
  [LangCode.FRENCH]: {
    text: "Tous pour un, un pour tous. C'est notre devise, n'est-ce pas? Nous allons vivre par elle et mourir par elle. Maintenant, à l'œuvre ! Le roi nous attend, la France nous attend. Nous avons perdu peut-être une heure, mais nous avons gagné un jour. En avant, et vive la France !",
    author: 'Alexandre Dumas, The Three Musketeers',
    language: LangCode.FRENCH,
  },
  [LangCode.GERMAN]: {
    text: 'Übung macht den Meister. Das ist eine alte Weisheit, die sich in vielen Lebensbereichen bewahrheitet. Ob es nun um das Erlernen einer neuen Sprache, das Beherrschen eines Musikinstruments oder die Verfeinerung einer sportlichen Fähigkeit geht - nur durch regelmäßige Praxis und Wiederholung können wir unsere Fähigkeiten verbessern und letztendlich Exzellenz erreichen.',
    author: 'German Proverb and Explanation',
    language: LangCode.GERMAN,
  },
  [LangCode.ITALIAN]: {
    text: "Nel mezzo del cammin di nostra vita mi ritrovai per una selva oscura, ché la diritta via era smarrita. Ahi quanto a dir qual era è cosa dura esta selva selvaggia e aspra e forte che nel pensier rinova la paura! Tant'è amara che poco è più morte; ma per trattar del ben ch'i' vi trovai, dirò de l'altre cose ch'i' v'ho scorte.",
    author: 'Dante Alighieri, Divine Comedy',
    language: LangCode.ITALIAN,
  },
  [LangCode.POLISH]: {
    text: 'Litwo! Ojczyzno moja! Ty jesteś jak zdrowie. Ile cię trzeba cenić, ten tylko się dowie, kto cię stracił. Dziś piękność twą w całej ozdobie widzę i opisuję, bo tęsknię po tobie. Panno Święta, co Jasnej bronisz Częstochowy i w Ostrej świecisz Bramie! Ty, co gród zamkowy nowogródzki ochraniasz z jego wiernym ludem!',
    author: 'Adam Mickiewicz, Pan Tadeusz',
    language: LangCode.POLISH,
  },
  [LangCode.PORTUGUESE]: {
    text: 'Tudo vale a pena se a alma não é pequena. Quem quer passar além do Bojador tem que passar além da dor. Deus ao mar o perigo e o abismo deu, mas nele é que espelhou o céu. Valeu a pena? Tudo vale a pena se a alma não é pequena. Quem quer passar além do Bojador tem que passar além da dor.',
    author: 'Fernando Pessoa',
    language: LangCode.PORTUGUESE,
  },
  [LangCode.RUSSIAN]: {
    text: 'Все счастливые семьи похожи друг на друга, каждая несчастливая семья несчастлива по-своему. Все смешалось в доме Облонских. Жена узнала, что муж был в связи с бывшею в их доме француженкою-гувернанткой, и объявила мужу, что не может жить с ним в одном доме. Положение это продолжалось уже третий день и мучительно чувствовалось и самими супругами, и всеми членами семьи, и домочадцами.',
    author: 'Leo Tolstoy, Anna Karenina',
    language: LangCode.RUSSIAN,
  },
  [LangCode.UKRAINIAN]: {
    text: "Садок вишневий коло хати, Хрущі над вишнями гудуть, Плугатарі з плугами йдуть, Співають ідучи дівчата, А матері вечерять ждуть. Сім'я вечеря коло хати, Вечірня зіронька встає. Дочка вечерять подає, А мати хоче научати, Так соловейко не дає.",
    author: 'Taras Shevchenko',
    language: LangCode.UKRAINIAN,
  },
  [LangCode.CZECH]: {
    text: 'Být či nebýt - to je otázka. Zda je důstojnější mlčky trpět surovost osudu, nebo se vzepřít moři trápení a rázným odporem je ukončit. Zemřít - spát - nic víc - a vědět, že tím spánkem skončí strasti srdce a tisícerá soužení, jež jsou údělem těla - toť zajisté cíl vroucně žádoucí. Zemřít - spát - spát - snad snít!',
    author: 'William Shakespeare, Hamlet (Czech translation)',
    language: LangCode.CZECH,
  },
  [LangCode.DANISH]: {
    text: 'At være eller ikke være, det er spørgsmålet. Om det er ædlere i sindet at tåle skæbnens stenkast og pileskud eller at tage våben op mod et hav af kvaler og end dem med et slag. At dø, at sove, intet mere, og ved en søvn at sige, at vi ender hjertets smerte og de tusind stød, som kødet har i arv, det er en ende, som man inderligt må ønske sig.',
    author: 'William Shakespeare, Hamlet (Danish translation)',
    language: LangCode.DANISH,
  },
  [LangCode.DUTCH]: {
    text: 'In het begin schiep God de hemel en de aarde. De aarde nu was woest en ledig, en duisternis lag op de vloed, en de Geest Gods zweefde over de wateren. En God zeide: Er zij licht; en er was licht. En God zag, dat het licht goed was, en God maakte scheiding tussen het licht en de duisternis. En God noemde het licht dag, en de duisternis noemde Hij nacht. Toen was het avond geweest en het was morgen geweest: de eerste dag.',
    author: 'Bible, Genesis 1:1-5 (Dutch translation)',
    language: LangCode.DUTCH,
  },
  [LangCode.FINNISH]: {
    text: 'Maamme, isänmaamme, soi, sana kultainen! Ei laaksoa, ei kukkulaa, ei vettä, rantaa rakkaampaa kuin kotimaa tää pohjoinen, maa kallis isien. Sun kukoistukses kuorestaan kerrankin puhkeaa; viel lempemme saa nousemaan sun toivos, riemus loistossaan, ja kerran laulus, synnyinmaa, korkeemman kaiun saa.',
    author: 'Johan Ludvig Runeberg, Finnish National Anthem',
    language: LangCode.FINNISH,
  },
  [LangCode.INDONESIAN]: {
    text: 'Merdeka atau mati! Kemerdekaan adalah hak segala bangsa, maka penjajahan di atas dunia harus dihapuskan karena tidak sesuai dengan perikemanusiaan dan perikeadilan. Dan perjuangan pergerakan kemerdekaan Indonesia telah sampailah kepada saat yang berbahagia dengan selamat sentosa mengantarkan rakyat Indonesia ke depan pintu gerbang kemerdekaan negara Indonesia, yang merdeka, bersatu, berdaulat, adil dan makmur.',
    author: 'Indonesian Independence Proclamation and Preamble to the Constitution',
    language: LangCode.INDONESIAN,
  },
  [LangCode.MALAY]: {
    text: 'Bersekutu bertambah mutu. Peribahasa ini mencerminkan semangat kerjasama dalam masyarakat Malaysia. Dalam kepelbagaian etnik dan budaya kita, perpaduan adalah kunci kejayaan. Dengan bersatu, kita dapat mengatasi cabaran dan mencapai matlamat bersama. Marilah kita terus mengamalkan semangat muhibah dan toleransi untuk membina negara yang lebih maju dan harmoni.',
    author: 'Malaysian Proverb and Commentary',
    language: LangCode.MALAY,
  },
  [LangCode.ROMANIAN]: {
    text: 'Limba noastră-i o comoară în adâncuri înfundată, un şirag de piatră rară pe moşie revărsată. Limba noastră-i foc ce arde într-un neam, ce fără veste s-a trezit din somn de moarte ca viteazul din poveste. Limba noastră-i numai cântec, doina dorurilor noastre, roi de fulgere ce spintec nouri negri, zări albastre.',
    author: 'Alexei Mateevici, "Limba noastră"',
    language: LangCode.ROMANIAN,
  },
  [LangCode.SLOVAK]: {
    text: 'Nad Tatrou sa blýska, hromy divo bijú. Zastavme ich, bratia, veď sa ony stratia, Slováci ožijú. To Slovensko naše posiaľ tvrdo spalo. Ale blesky hromu vzbudzujú ho k tomu, aby sa prebralo. Už Slovensko vstáva, putá si strháva. Hej, rodina milá, hodina odbila, žije matka Sláva!',
    author: 'Janko Matúška, Slovak National Anthem',
    language: LangCode.SLOVAK,
  },
  [LangCode.SWEDISH]: {
    text: 'Du gamla, du fria, du fjällhöga nord, du tysta, du glädjerika sköna! Jag hälsar dig, vänaste land uppå jord, din sol, din himmel, dina ängder gröna. Du tronar på minnen från fornstora dar, då ärat ditt namn flög över jorden. Jag vet att du är och du blir vad du var. Ja, jag vill leva, jag vill dö i Norden.',
    author: 'Richard Dybeck, Swedish National Anthem',
    language: LangCode.SWEDISH,
  },
  [LangCode.TURKISH]: {
    text: 'Bir elin nesi var, iki elin sesi var. Bu atasözü, birlik ve beraberliğin önemini vurgular. Tek başımıza yapamayacağımız şeyleri, birlikte çalışarak başarabiliriz. Toplumumuzda dayanışma ve işbirliği ruhu, zorlukları aşmamıza ve daha güçlü bir gelecek inşa etmemize yardımcı olur. Birlikte çalışarak, sesimizi duyurabilir ve büyük değişimler yaratabiliriz.',
    author: 'Turkish Proverb and Commentary',
    language: LangCode.TURKISH,
  },
  [LangCode.HUNGARIAN]: {
    text: 'A patakban két gyermek fürdik: egy fiú meg egy leány. Nem illik tán, hogy együtt fürödnek, de ők ezt nem tudják: a fiú alig hétesztendős, a leány két évvel fiatalabb.',
    author: 'Géza Gárdonyi, Egri csillagok',
    language: LangCode.HUNGARIAN,
  },
  [LangCode.NORWEGIAN]: {
    text: 'Det var i den Tid, jeg gik omkring og sulted i Kristiania, denne forunderlige By, som ingen forlader, før han har faaet Mærker af den.',
    author: 'Knut Hamsun, Sult',
    language: LangCode.NORWEGIAN,
  },
}
