import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../shadcn/table.tsx'
import { CircleFlagLanguage } from 'react-circle-flags'
import { langCodeToEnglishLanguageName } from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'
import { CorrectUserPronunciation } from '@shared/frontend-and-backend/body-types/words/words.types.ts'
import { LanguageFilterValue } from './language-filter.tsx'

const columnHelper = createColumnHelper<CorrectUserPronunciation>()

const columns = [
  columnHelper.accessor('word', {
    header: 'Word',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('language', {
    header: 'Language',
    cell: (info) => (
      <div className='flex items-center justify-center space-x-2'>
        <CircleFlagLanguage languageCode={info.getValue()} className='h-5 w-5' />
        <span className='text-sm text-gray-700'>{langCodeToEnglishLanguageName(info.getValue())}</span>
      </div>
    ),
  }),
  columnHelper.accessor('dateOfFirstTimePronouncedCorrectly', {
    header: 'Learned on',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  }),
]

export const LearnedWordsTable = ({
  data,
  languageFilter,
}: {
  data: CorrectUserPronunciation[]
  languageFilter: LanguageFilterValue
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className='w-full overflow-hidden rounded-lg border border-gray-200 shadow-sm'>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id} className='flex bg-gray-50'>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} className='flex-1 px-4 py-3 text-left text-sm font-semibold text-gray-900'>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} className='flex hover:bg-gray-50'>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className='flex flex-1 items-center px-4 py-3'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className='flex'>
              <TableCell colSpan={columns.length} className='flex-1 px-4 py-8 text-center text-gray-500'>
                No words learned for{' '}
                {languageFilter === undefined ? 'any language' : langCodeToEnglishLanguageName(languageFilter)} yet.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
