import { z } from 'zod'
import { emailUrl } from '../backend-urls.ts'
import { formSchema } from '@shared/frontend-and-backend/body-types/contact-email/form-schema.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { ContactEmailResponse } from '@shared/frontend-and-backend/body-types/contact-email/contact-email.types.ts'
import { customFetch } from '../../utils.ts'

export const sendEmail = async (
  data: z.infer<typeof formSchema>,
  accessToken: string
): Promise<ResponseWrapper<ContactEmailResponse>> => {
  const response = await customFetch(emailUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(data),
  })

  return response.json()
}
