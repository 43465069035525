import { useState } from 'react'
import { Check, ChevronDown } from 'lucide-react'
import { langCodeToEnglishLanguageName } from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils'
import { CircleFlagLanguage } from 'react-circle-flags'
import { Popover, PopoverContent, PopoverTrigger } from '../../../../../shadcn/popover.tsx'
import { Button } from '../../../../../shadcn/button.tsx'
import { cn } from '../../../../../../utils/shadcn-utils.ts'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '../../../../../shadcn/command.tsx'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes.ts'

export type LanguageFilterValue = SupportedStudyLanguage | undefined

type LanguageFilterProps = {
  label: string
  onLanguageSelect: (value: LanguageFilterValue) => void
  langCodes: LanguageFilterValue[]
  defaultValue?: LanguageFilterValue
}

export const LanguageFilter = ({ label, onLanguageSelect, langCodes, defaultValue = undefined }: LanguageFilterProps) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageFilterValue>(defaultValue)

  const languageOptions = langCodes.map((langCode) => ({
    label: langCode === undefined ? 'All languages' : langCodeToEnglishLanguageName(langCode),
    value: langCode,
  }))

  const handleLanguageChange = (value: LanguageFilterValue) => {
    setSelectedLanguage(value)
    onLanguageSelect(value)
    setIsPopoverOpen(false)
  }

  return (
    <div className='flex flex-col items-start space-y-2'>
      <label className='text-base font-semibold'>{label}</label>
      <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen} modal={true}>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            role='combobox'
            className={cn(
              'w-full justify-between rounded-lg border border-gray-300 bg-white px-3 py-2 text-left text-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
              !selectedLanguage && 'text-muted-foreground'
            )}
          >
            <div className='flex flex-row items-center gap-2'>
              {selectedLanguage !== undefined && (
                <CircleFlagLanguage languageCode={selectedLanguage} className='h-5 w-5 bg-transparent' />
              )}
              {languageOptions.find((option) => option.value === selectedLanguage)?.label}
            </div>
            <ChevronDown className='h-4 w-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-[--radix-popover-trigger-width] p-0' align='start'>
          <Command className='bg-white'>
            <CommandInput placeholder='Search language...' className='h-9' />
            <CommandEmpty>No language found.</CommandEmpty>
            <CommandList className='max-h-[200px]'>
              <CommandGroup>
                {languageOptions.map(({ label, value }) => (
                  <CommandItem
                    className={cn('cursor-pointer px-3 py-2 hover:bg-gray-100')}
                    value={label}
                    key={value === undefined ? 'all' : value}
                    onSelect={() => handleLanguageChange(value)}
                  >
                    <div className='flex flex-row items-center gap-2'>
                      {value !== undefined && <CircleFlagLanguage languageCode={value} className='h-5 w-5 bg-transparent' />}
                      {label}
                    </div>
                    <Check
                      className={cn('ml-auto h-4 w-4', value === selectedLanguage ? 'opacity-100' : 'opacity-0')}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  )
}
