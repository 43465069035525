import { transliterationUrl } from '../backend-urls.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { SupportedStudyLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import {
  TransliterationRequest,
  TransliterationResponse,
} from '@shared/frontend-and-backend/body-types/transliteration/transliteration.types.ts'
import { customFetch } from '../../utils.ts'

export const getTransliteration = async (
  text: string,
  language: SupportedStudyLanguage,
  accessToken: string
): Promise<ResponseWrapper<TransliterationResponse>> => {
  const body: TransliterationRequest = { text, language }
  const response: Response = await customFetch(transliterationUrl, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  })

  const json = await response.json()
  return json
}
