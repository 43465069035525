import { removalsUrl } from '../backend-urls.ts'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { customFetch } from '../../utils.ts'
import { RemovalRequest, RemovalResponse } from '@shared/frontend-and-backend/body-types/removals/removals.types.ts'

export const sendRemovalRequest = async (
  type: 'account' | 'voice',
  accessToken: string
): Promise<ResponseWrapper<RemovalResponse>> => {
  const body: RemovalRequest = { type }
  const response = await customFetch(removalsUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })

  return response.json()
}
