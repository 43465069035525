import { LangCode, SupportedMotherLanguage } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { ChangeEvent, useEffect, useState } from 'react'
import { accountActions } from '../../../../state/slices/account-slice.ts'
import { useDispatch } from 'react-redux'
import { LanguageCard } from './language-card.tsx'
import { NavigationButton } from '../navigation-button.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import clsx from 'clsx'
import { filterMotherLangsByInput } from './mother-lang-filter-utils.ts'
import { Input } from '../../../shadcn/input.tsx'

export const ChooseMotherLanguageView = () => {
  const dispatch = useDispatch()
  const [selectedMotherLanguage, setSelectedMotherLanguage] = useState<SupportedMotherLanguage | null>(null)
  const [hasSelectedOther, setHasSelectedOther] = useState<boolean>(false)
  const [text, setText] = useState('')
  const filteredLangs = filterMotherLangsByInput(text)

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const onSkipClick = () => {
    dispatch(accountActions.setHasChosenNoneOfPossibleMotherLanguages())
  }

  const onNextClick = () => {
    if (hasSelectedOther) {
      dispatch(accountActions.setMotherLanguage(LangCode.ENGLISH))
    } else {
      dispatch(accountActions.setMotherLanguage(selectedMotherLanguage))
    }
  }

  const handleLangClick = (lang: SupportedMotherLanguage) => {
    if (selectedMotherLanguage === lang) {
      setSelectedMotherLanguage(null)
    } else {
      setSelectedMotherLanguage(lang)
      setHasSelectedOther(false)
    }
  }

  const handleHasSelectedOther = () => {
    setHasSelectedOther(!hasSelectedOther)
    setSelectedMotherLanguage(null)
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value)
  }

  return (
    <div className='flex w-full flex-col'>
      <div className='mb-52 flex justify-center'>
        <div className='flex w-full flex-col items-center gap-y-4 px-4 py-6 pt-8 md:w-1/2 3xl:w-1/4'>
          <div className='flex max-w-md flex-col items-center'>
            <h1 className='max-w-xl text-center text-4xl font-bold tracking-tighter'>Choose your mother language:</h1>
            <span className='text-md max-w-xl text-center tracking-tighter text-gray-400'>
              (You can always change it later)
            </span>
          </div>
          <Input
            autoFocus={true}
            value={text}
            onChange={handleInputChange}
            className='h-14 w-full rounded-xl bg-white text-lg'
            placeholder='type your mother language'
          />
          <div className='grid w-full grid-cols-2 gap-4'>
            {filteredLangs.map((lang) => (
              <LanguageCard
                key={lang}
                lang={lang}
                handleClick={() => handleLangClick(lang)}
                isSelected={selectedMotherLanguage === lang}
              />
            ))}
            <button
              className={clsx(
                'flex h-12 items-center justify-center rounded-xl border bg-white shadow focus:outline-none',
                { 'bg-gradient-to-r from-orange-300 to-yellow-300 text-white': hasSelectedOther },
                { 'hover:bg-gray-100': !hasSelectedOther }
              )}
              onClick={handleHasSelectedOther}
            >
              <span
                className={clsx('text-xl text-gray-500', {
                  'text-gray-700': hasSelectedOther,
                  'text-gray-500': !hasSelectedOther,
                })}
              >
                Other
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-40 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full flex-row justify-between gap-x-4 px-4 py-4 md:w-1/2 3xl:w-1/4'>
          <NavigationButton onClick={onSkipClick} className='w-1/2'>
            Skip
          </NavigationButton>
          <NavigationButton
            onClick={onNextClick}
            disabled={!selectedMotherLanguage && !hasSelectedOther}
            className='w-1/2 bg-gradient-to-r from-indigo-500 to-indigo-600 text-white'
          >
            Next
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
