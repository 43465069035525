export const base64ToBlob = (base64String: string, contentType: string = 'audio/mpeg') => {
  // Decode the Base64 string to a binary string
  const binaryString = window.atob(base64String)

  const len = binaryString.length
  const bytes = new Uint8Array(len)

  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return new Blob([bytes], { type: contentType })
}

export const getBlobDuration = async (blob: Blob): Promise<number> => {
  const audioContext: AudioContext = new AudioContext()
  const arrayBuffer: ArrayBuffer = await blob.arrayBuffer()
  const audioBuffer: AudioBuffer = await audioContext.decodeAudioData(arrayBuffer)
  const duration: number = audioBuffer.duration
  await audioContext.close()
  return duration
}

export const handleDownload = (audio: string | Blob, filename: string = 'audio'): void => {
  let blob: Blob
  let fileExtension: string
  if (typeof audio === 'string') {
    blob = base64ToBlob(audio)
    fileExtension = 'mp3'
  } else {
    blob = audio
    fileExtension = 'webm'
  }

  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${filename}.${fileExtension}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
  URL.revokeObjectURL(url)
}
