import { LangCode } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const LANG_TO_TEXT_FOR_CLONING: Record<LangCode, string> = {
  [LangCode.ENGLISH]:
    'The evening sky was ablaze with vibrant hues as the sun dipped below the horizon, transforming the heavens into a breathtaking canvas reminiscent of an exquisite painting. Spectators from all walks of life were drawn to this natural spectacle, halting their daily routines to capture the moment with their cameras or simply to gaze in awe. ',
  [LangCode.SPANISH]:
    'El cielo nocturno ardía con tonos vibrantes mientras el sol se sumergía bajo el horizonte, transformando los cielos en un lienzo impresionante que recordaba a una pintura exquisita. Los espectadores de todos los ámbitos de la vida se sintieron atraídos por este espectáculo natural, deteniendo sus rutinas diarias para capturar el momento con sus cámaras o simplemente para contemplar con asombro. ',
  [LangCode.GERMAN]:
    'Der Abendhimmel leuchtete in lebendigen Farben, als die Sonne unter dem Horizont verschwand und den Himmel in eine atemberaubende Leinwand verwandelte, die an ein exquisites Gemälde erinnerte. Zuschauer aus allen Lebensbereichen wurden von diesem natürlichen Spektakel angezogen und unterbrachen ihre täglichen Routinen, um den Moment mit ihren Kameras festzuhalten oder einfach nur staunend zu betrachten. ',
  [LangCode.FRENCH]:
    "Le ciel du soir était embrasé de teintes vibrantes alors que le soleil plongeait sous l'horizon, transformant les cieux en une toile à couper le souffle, rappelant une peinture exquise. Des spectateurs de tous horizons étaient attirés par ce spectacle naturel, interrompant leurs routines quotidiennes pour capturer ce moment avec leurs appareils photo ou simplement pour contempler avec admiration.",
  [LangCode.ITALIAN]:
    "Il cielo serale era infuocato da tonalità vibranti mentre il sole scendeva sotto l'orizzonte, trasformando i cieli in una tela mozzafiato che ricordava un dipinto squisito. Spettatori di ogni estrazione sociale erano attratti da questo spettacolo naturale, interrompendo le loro routine quotidiane per catturare il momento con le loro fotocamere o semplicemente per guardare con stupore.",
  [LangCode.POLISH]:
    'Wieczorne niebo płonęło żywymi barwami, gdy słońce zniknęło za horyzontem, zamieniając niebiosa w zapierające dech w piersiach płótno przypominające wykwintny obraz. Widzowie z różnych środowisk zostali przyciągnięci przez ten naturalny spektakl, przerywając swoje codzienne rutyny, aby uchwycić chwilę aparatem lub po prostu patrzeć z zachwytem.',
  [LangCode.CZECH]:
    'Večerní obloha zářila zářivými odstíny, když se slunce ponořilo pod obzor a proměnilo nebesa v dechberoucí plátno připomínající nádherný obraz. Tato přirozená podívaná přitahovala diváky ze všech společenských vrstev, kteří zastavili své každodenní rutiny, aby zachytili okamžik pomocí svých fotoaparátů nebo prostě jen s úžasem zírali.',
  [LangCode.UKRAINIAN]:
    'Вечірнє небо горіло яскравими відтінками, коли сонце заходило за горизонт, перетворюючи небеса на дихляве полотно, нагадуючи вишукану картину. Глядачі з усіх сфер життя були приваблені цим природним видовищем, припиняючи свої щоденні рутини, щоб зафіксувати момент своїми камерами або просто дивуватися.',
  [LangCode.RUSSIAN]:
    'Вечернее небо горело яркими оттенками, когда солнце погружалось за горизонт, превращая небеса в захватывающее полотно, напоминающее изысканную картину. Зрители из всех слоев общества были привлечены этим естественным зрелищем, прерывая свои повседневные рутины, чтобы запечатлеть момент на своих камерах или просто восхищаться.',
  [LangCode.PORTUGUESE]:
    'O céu da noite estava em chamas com matizes vibrantes enquanto o sol mergulhava abaixo do horizonte, transformando os céus em uma tela de tirar o fôlego, reminiscente de uma pintura requintada. Espectadores de todos os cantos foram atraídos por esse espetáculo natural, pausando suas rotinas diárias para capturar o momento com suas câmeras ou simplesmente para observar em admiração."',
  [LangCode.VIETNAMESE]:
    'Bầu trời đêm rực sáng với những sắc màu rực rỡ khi mặt trời lặn xuống dưới đường chân trời, biến bầu trời thành một bức tranh ngoạn mục gợi nhớ đến một bức tranh tinh xảo. Khán giả từ xa đến bị thu hút bởi cảnh tượng thiên nhiên này, tạm dừng công việc hàng ngày của họ để ghi lại khoảnh khắc trên máy ảnh hoặc đơn giản là để ngắm nhìn trong sự kinh ngạc.',

  // the words are tokenized manually with the help of google translate, spaces are added to divide the text into words
  // this is needed when we show the text word by word in the clone voice viewX
  [LangCode.CHINESE]:
    '夕阳 西 下，夜空 绚烂 夺目，万里 无 云，犹如 一 幅 绝 美 的 油画。来自 各 行 各 业 的 游客 都 被 这 自然 奇观 所 吸引，纷纷 放下 手中 的 日常 事务，用 相机 捕捉 这 一 瞬间，或 只是 惊叹 地 凝视。',
  [LangCode.HINDI]:
    'शाम का आसमान चमकीले रंगों से जगमगा उठा, क्योंकि सूरज क्षितिज के नीचे डूब रहा था, आसमान एक शानदार पेंटिंग की याद दिलाने वाले लुभावने कैनवास में बदल गया। सभी क्षेत्रों के दर्शक इस प्राकृतिक नज़ारे को देखने के लिए आकर्षित हुए, अपने दैनिक कामों को रोककर इस पल को अपने कैमरों में कैद करने या बस विस्मय में देखने के लिए।',
  [LangCode.INDONESIAN]:
    'Langit malam bersinar dengan warna cerah saat matahari terbenam di bawah cakrawala, mengubah langit menjadi kanvas menakjubkan yang mengingatkan kita pada lukisan indah. Penonton dari berbagai lapisan masyarakat tertarik pada tontonan alam ini, menghentikan rutinitas sehari-hari mereka untuk mengabadikan momen dengan kamera atau sekadar menatap dengan kagum.',
  [LangCode.MALAY]:
    'Langit petang berkobar-kobar dengan warna-warna cerah ketika matahari terbenam di bawah ufuk, mengubah langit menjadi kanvas yang menakjubkan mengingatkan lukisan yang indah. Penonton dari semua lapisan masyarakat tertarik dengan tontonan semula jadi ini, menghentikan rutin harian mereka untuk merakam detik itu dengan kamera mereka atau sekadar untuk menatap dengan kagum.',
  // the words are tokenized manually with the help of google translate, spaces are added to divide the text into words
  // this is needed when we show the text word by word in the clone voice view
  [LangCode.JAPANESE]:
    '太陽 が 地平線 の下に 沈む と、夕方 の空 は 鮮やかな 色彩 に 輝き 、天空 は 美しい 絵画 を 思わ せる 息 を 呑む ような キャンバス に 変貌 しました 。あらゆる 階層 の 観客 が この 自然 の 光景 に 魅了 さ れ 、日常 の 仕事 を 中断 して カメラ で その 瞬間 を 捉え たり 、ただ 畏敬 の 念 を 抱いて 眺め たり して いました。',
  [LangCode.KOREAN]:
    '저녁 하늘은 태양이 수평선 아래로 지면서 선명한 색상으로 불타올랐고, 하늘은 절묘한 그림을 연상시키는 숨막히는 캔버스로 변했습니다. 각계각층의 관중들은 이 자연의 광경에 이끌려 일상을 멈추고 카메라로 그 순간을 포착하거나 단순히 경외감을 느끼며 바라보았습니다.',
  [LangCode.TAMIL]:
    'சூரியன் அடிவானத்திற்குக் கீழே மூழ்கி, வானத்தை ஒரு அழகிய ஓவியத்தை நினைவூட்டும் ஒரு மூச்சடைக்கக்கூடிய கேன்வாஸாக மாற்றியதால் மாலை வானம் துடிப்பான சாயல்களால் எரிந்தது. அனைத்து தரப்பு பார்வையாளர்களும் இந்த இயற்கையான காட்சிக்கு ஈர்க்கப்பட்டனர், இந்த தருணத்தை தங்கள் கேமராக்கள் மூலம் படம்பிடிக்க அல்லது வெறுமனே பிரமிப்புடன் பார்க்க தங்கள் அன்றாட நடைமுறைகளை நிறுத்தினர்.',
  [LangCode.TURKISH]:
    'Güneş ufkun altına inerken, akşam gökyüzü canlı tonlarla parlıyordu ve gökyüzünü nefis bir tabloyu anımsatan nefes kesici bir tuvale dönüştürüyordu. Toplumun her kesiminden seyirciler bu doğal manzaraya ilgi duydu; günlük rutinlerine ara vererek o anı kameralarıyla yakaladılar ya da sadece hayranlıkla izlediler.',
  [LangCode.ROMANIAN]:
    'Cerul de seară era în flăcări cu nuanțe vibrante în timp ce soarele cobora sub orizont, transformând cerurile într-o pânză uluitoare care amintește de o pictură rafinată. Spectatorii din toate categoriile sociale au fost atrași de acest spectacol natural, oprindu-și rutina zilnică pentru a surprinde momentul cu camerele lor sau pur și simplu pentru a privi cu uimire.',
  [LangCode.SWEDISH]:
    'Kvällshimlen brann av livfulla nyanser när solen doppade under horisonten och förvandlade himlen till en hisnande duk som påminde om en utsökt målning. Åskådare från alla samhällsskikt drogs till detta naturliga skådespel och stoppade sina dagliga rutiner för att fånga ögonblicket med sina kameror eller helt enkelt för att titta i vördnad.',
  [LangCode.NORWEGIAN]:
    'Kveldshimmelen brant med livlige fargetoner da solen senket seg under horisonten, og forvandlet himmelen til et fantastisk lerret som minner om et utsøkt maleri. Tilskuere fra alle samfunnslag ble tiltrukket av dette naturlige opptoget, og stoppet sine daglige rutiner for å fange øyeblikket med kameraene sine eller bare for å se i ærefrykt.',
  [LangCode.DANISH]:
    'Aftenhimlen brændte med livlige nuancer, da solen dykkede ned under horisonten og forvandlede himlen til et betagende lærred, der minder om et udsøgt maleri. Tilskuere fra alle samfundslag blev tiltrukket af dette naturlige skue, og stoppede deres daglige rutiner for at fange øjeblikket med deres kameraer eller blot for at se i ærefrygt.',
  [LangCode.SLOVAK]:
    'Večerná obloha žiarila žiarivými odtieňmi, keď sa slnko ponorilo pod horizont a premenilo oblohu na úchvatné plátno pripomínajúce nádherný obraz. Diváci zo všetkých spoločenských vrstiev boli priťahovaní k tejto prírodnej podívanej, zastavili svoje každodenné rutiny, aby zachytili okamih pomocou svojich fotoaparátov alebo sa jednoducho pozreli s úžasom.',
  [LangCode.DUTCH]:
    "De avondlucht stond in vuur en vlam met levendige tinten toen de zon onder de horizon zakte, waardoor de hemel veranderde in een adembenemend canvas dat deed denken aan een prachtig schilderij. Toeschouwers uit alle lagen van de bevolking werden aangetrokken door dit natuurspektakel, waarbij ze hun dagelijkse routine onderbraken om het moment vast te leggen met hun camera's of gewoon vol ontzag te kijken.",
  [LangCode.THAI]:
    'ท้องฟ้ายามเย็นสว่างไสวด้วยเฉดสีสดใสขณะที่ดวงอาทิตย์ลับขอบฟ้า เปลี่ยนท้องฟ้าให้กลายเป็นผืนผ้าใบอันน่าทึ่งชวนให้นึกถึงภาพวาดอันวิจิตรงดงาม ผู้ชมจากทุกสาขาอาชีพต่างหลงใหลในปรากฏการณ์ทางธรรมชาตินี้ โดยหยุดกิจวัตรประจำวันเพื่อบันทึกช่วงเวลานั้นด้วยกล้องหรือเพียงแค่จ้องมองด้วยความตกตะลึง',
  [LangCode.HUNGARIAN]:
    'Az esti égbolt élénk színekben pompázott, ahogy a nap a látóhatár alá süllyedt, és az eget lélegzetelállító vászonná változtatta, amely egy gyönyörű festményre emlékeztet. Az élet minden területéről érkező nézőket vonzotta ez a természetes látvány, felhagyva a napi rutinnal, hogy megörökítsék a pillanatot kameráikkal, vagy egyszerűen ámulattal nézzenek.',
  [LangCode.GREEK]:
    'Ο απογευματινός ουρανός φλεγόταν από έντονες αποχρώσεις καθώς ο ήλιος βυθιζόταν κάτω από τον ορίζοντα, μεταμορφώνοντας τους ουρανούς σε έναν συναρπαστικό καμβά που θύμιζε έναν εξαίσιο πίνακα ζωγραφικής. Θεατές από όλα τα κοινωνικά στρώματα προσελκύθηκαν από αυτό το φυσικό θέαμα, σταματώντας την καθημερινότητά τους για να απαθανατίσουν τη στιγμή με τις κάμερές τους ή απλώς να κοιτάξουν με δέος.',
  [LangCode.FINNISH]:
    'Iltataivas leimahti eloisista sävyistä auringon laskeutuessa horisontin alapuolelle ja muuttaen taivaan henkeäsalpaavaksi kankaaksi, joka muistuttaa hienoa maalausta. Katsojat kaikilta elämänaloilta vetivät puoleensa tämä luonnollinen spektaakkeli, joka keskeytti päivittäisen rutiininsa vangitakseen hetken kameroillaan tai vain katsoakseen hämmästyneenä.',
  [LangCode.BULGARIAN]:
    'Вечерното небе пламтеше с живи нюанси, когато слънцето се скри зад хоризонта, превръщайки небесата в спиращо дъха платно, напомнящо изящна картина. Зрители от всички слоеве на обществото бяха привлечени от този естествен спектакъл, спирайки ежедневието си, за да уловят момента с фотоапаратите си или просто да гледат със страхопочитание.',
  [LangCode.CATALAN]:
    "El cel del vespre s'encén de tonalitats vibrants mentre el sol va caure sota l'horitzó, transformant el cel en un llenç impressionant que recordava una pintura exquisida. Espectadors de tots els àmbits de la vida es van sentir atrets per aquest espectacle natural, aturant les seves rutines diàries per capturar el moment amb les seves càmeres o simplement per contemplar amb admiració.",
}
