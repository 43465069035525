import { LabelList, Pie, PieChart } from 'recharts'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '../../../../../shadcn/card.tsx'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '../../../../../shadcn/chart.tsx'

import colors from 'tailwindcss/colors'
import { useGetWordsPronouncedCorrectlyCounters } from '../../../../../../transport/transport/api/learned-words/words-hooks.ts'
import { WordsInLanguageCounter } from '@shared/frontend-and-backend/body-types/words/words.types.ts'
import { langCodeToEnglishLanguageName } from '@shared/frontend-and-landing-and-backend/constants/lang-code-utils.ts'

const chartColors = [
  colors.green[400],
  colors.green[500],
  colors.green[600],
  colors.green[700],
  colors.green[800],
  colors.green[900],
]

export const LanguageCountersPieChart = () => {
  const counters: WordsInLanguageCounter[] = useGetWordsPronouncedCorrectlyCounters()
  const chartData = counters
    .sort((c1, c2) => {
      return c1.wordsPronouncedCorrectlyCount - c2.wordsPronouncedCorrectlyCount
    })
    .map((counter, index) => ({
      language: langCodeToEnglishLanguageName(counter.language),
      wordsLearned: counter.wordsPronouncedCorrectlyCount,
      fill: chartColors[index],
    }))
  const chartConfig: ChartConfig = {
    wordsLearned: {
      label: 'words learned',
    },
    ...Object.fromEntries(
      chartData.map(({ language }) => [
        language,
        {
          label: language,
          color: colors.stone[950],
        },
      ])
    ),
  }

  return (
    <div className='flex w-full flex-col items-center'>
      <Card className='flex w-full max-w-4xl flex-col'>
        <CardHeader className='items-center pb-0'>
          <CardTitle>Words learned</CardTitle>
          <CardDescription>grouped by language</CardDescription>
        </CardHeader>
        <CardContent className='flex-1 pb-0'>
          <ChartContainer config={chartConfig} className='mx-auto aspect-square max-h-[600px]'>
            <PieChart innerRadius={300}>
              <ChartTooltip content={<ChartTooltipContent nameKey='wordsLearned' hideLabel />} />
              <Pie data={chartData} dataKey='wordsLearned'>
                <LabelList
                  dataKey='language'
                  fontSize={12}
                  formatter={(value: keyof typeof chartConfig) => chartConfig[value]?.label}
                />
              </Pie>
            </PieChart>
          </ChartContainer>
        </CardContent>
      </Card>
    </div>
  )
}
