export const ROUTE_PATHS = {
  ONBOARDING_NEW_REGISTRATIONS_CLOSED_CURRENTLY: '/onboarding/new-registrations-closed-currently',
  ONBOARDING_CHOOSE_MOTHER_LANGUAGE: '/onboarding/mother-language',
  ONBOARDING_CHOOSE_STUDY_LANGUAGE: '/onboarding/study-language',
  ONBOARDING_CHOOSE_DIALECT: '/onboarding/dialect',
  ONBOARDING_TERMS_AND_CONDITIONS: '/onboarding/terms-and-conditions',
  ONBOARDING_CLONE_VOICE: '/onboarding/clone-voice',
  ONBOARDING_SUCCESS: '/onboarding/success',

  DASHBOARD: '/dashboard',
  RANDOM_EXERCISE: '/exercises/random',
  CUSTOM_EXERCISE: '/exercises/custom',
  FREQUENCY_LIST_EXERCISE: '/exercises/frequency-list',
  PROGRESS: '/progress',
  PROGRESS_STREAK: '/progress/streak',
  PROGRESS_BADGES: '/progress/badges',
  PROGRESS_BADGES_ALL: '/progress/badges/all',
  PROGRESS_BADGES_STREAK: '/progress/badges/streak',
  PROGRESS_BADGES_WORDS: '/progress/badges/words',
  PROGRESS_BADGES_LANGUAGES: '/progress/badges/languages',
  PROGRESS_STATS: '/progress/stats',
  PROGRESS_STATS_LEARNED_WORDS: '/progress/stats/learned-words',
  PROGRESS_STATS_SAVED_WORDS: '/progress/stats/saved-words',
  PRICING: '/pricing',

  SIGN_IN: '/sign-in',
  SIGN_UP: '/sign-up',
  SIGN_IN_EMAIL: '/sign-in/email',
  SIGN_UP_EMAIL: '/sign-up/email',
  SIGN_IN_EMAIL_VERIFICATION_SENT: '/sign-in/email/verification-sent',
  SIGN_UP_EMAIL_VERIFICATION_SENT: '/sign-up/email/verification-sent',
  SIGN_IN_UP_EMAIL_VERIFY: '/sign-in-up/email/verify',

  ACCOUNT_REMOVED: '/account/removed',
  ADMIN_SETTINGS: '/top-secret-admin-settings',
  REDIRECT_TO_CHECK_OUT: '/redirect-to-check-out/:priceId',
  FROM_LANDING: '/from-landing',
  CHECKOUT_SUCCESS: '/checkout-success',
}

// we should not push the user to the onboarding if he decided to pay without free trial
export const isOnboardingException = (pathname: string) => {
  if ([ROUTE_PATHS.ADMIN_SETTINGS, ROUTE_PATHS.PRICING, ROUTE_PATHS.CHECKOUT_SUCCESS].includes(pathname)) {
    return true
  }
  return pathname.startsWith('/redirect-to-check-out')
}

export const ONBOARDING_PATHS = [
  ROUTE_PATHS.ONBOARDING_NEW_REGISTRATIONS_CLOSED_CURRENTLY,
  ROUTE_PATHS.ONBOARDING_CHOOSE_MOTHER_LANGUAGE,
  ROUTE_PATHS.ONBOARDING_CHOOSE_STUDY_LANGUAGE,
  ROUTE_PATHS.ONBOARDING_CHOOSE_DIALECT,
  ROUTE_PATHS.ONBOARDING_TERMS_AND_CONDITIONS,
  ROUTE_PATHS.ONBOARDING_CLONE_VOICE,
  ROUTE_PATHS.ONBOARDING_SUCCESS,
] as const

export const buildCheckOutRightAfterSignUpPath = (priceId: string) => {
  return `/redirect-to-check-out/${priceId}`
}
