import {
  SUPPORTED_STUDY_LANGUAGES,
  SupportedStudyLanguage,
} from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { useEffect, useState } from 'react'
import { accountActions, selectHasVoice } from '../../../../state/slices/account-slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { LanguageCard } from './language-card.tsx'
import { NavigationButton } from '../navigation-button.tsx'
import { POSTHOG_EVENTS } from '../../../../analytics/posthog/posthog-events.ts'
import { clsx } from 'clsx'

export const ChooseStudyLanguageView = () => {
  const dispatch = useDispatch()
  const [selectedStudyLanguage, setSelectedStudyLanguage] = useState<SupportedStudyLanguage | null>(null)
  const hasVoice = useSelector(selectHasVoice)

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const handleLangClick = (lang: SupportedStudyLanguage) => {
    if (selectedStudyLanguage === lang) {
      setSelectedStudyLanguage(null)
    } else {
      setSelectedStudyLanguage(lang)
    }
  }

  const onPreviousClick = () => {
    dispatch(accountActions.resetMotherLanguage())
  }

  const onNextClick = () => {
    dispatch(accountActions.setStudyLanguage(selectedStudyLanguage as SupportedStudyLanguage))
  }

  return (
    <div className='flex w-full flex-col items-center gap-y-6 px-4 pt-4 md:pt-8'>
      <div className='flex max-w-md flex-col items-center'>
        <h1 className='max-w-xl text-center text-4xl font-bold tracking-tighter'>
          Choose the language you want to study
        </h1>
        <span className='text-md max-w-xl text-center tracking-tighter text-gray-400'>
          (You can always change it later)
        </span>
      </div>
      <div className='mb-52 grid w-full grid-cols-2 gap-4 md:w-1/2 3xl:w-1/4'>
        {SUPPORTED_STUDY_LANGUAGES.map((lang) => (
          <LanguageCard
            key={lang}
            lang={lang}
            handleClick={() => handleLangClick(lang)}
            isSelected={selectedStudyLanguage === lang}
          />
        ))}
      </div>
      <div className='fixed bottom-0 w-full bg-gray-50'>
        <div className='pointer-events-none absolute bottom-full left-0 right-0 h-40 bg-gradient-to-b from-transparent to-gray-50' />
        <div className='mx-auto flex w-full flex-row justify-between gap-x-4 px-4 py-4 md:w-1/2 3xl:w-1/4'>
          {!hasVoice && (
            <NavigationButton onClick={onPreviousClick} className='w-1/2'>
              Previous
            </NavigationButton>
          )}
          <NavigationButton
            onClick={onNextClick}
            disabled={!selectedStudyLanguage}
            className={clsx('w-1/2 bg-gradient-to-r from-indigo-500 to-indigo-600 text-white', { 'w-full': hasVoice })}
          >
            Next
          </NavigationButton>
        </div>
      </div>
    </div>
  )
}
