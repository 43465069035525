export const SOMETHING_WENT_WRONG_MODAL_ID = 'something-went-wrong-modal-id'
export const PROFILE_SETTINGS_MODAL_ID = 'profile-settings-modal-id'
export const ABOUT_MODAL_ID = 'about-modal-id'
export const ACCOUNT_MODAL_ID = 'account-modal-id'
export const DANGER_ZONE_MODAL_ID = 'danger-zone-modal-id'
export const TRANSLATE_TEXT_MODAL_ID = 'translate-text-modal-id'
export const CONTACT_MODAL_ID = 'contact-modal-id'
export const AUDIO_TOO_SHORT_FOR_CLONING_MODAL_ID = 'audio-too-short-for-cloning-modal-id'
export const AUDIO_WITH_NOT_ENOUGH_WORDS_FOR_CLONING_MODAL_ID = 'audio-with-not-enough-words-for-cloning-modal-id'
export const UPGRADE_PLAN_MODAL_ID = 'upgrade-plan-modal-id'
export const VOICE_REMOVAL_SUCCESS_MODAL_ID = 'voice-removal-success-modal-id'

export const HASH_ENABLED_MODAL_IDS: string[] = [
  PROFILE_SETTINGS_MODAL_ID,
  ABOUT_MODAL_ID,
  ACCOUNT_MODAL_ID,
  DANGER_ZONE_MODAL_ID,
  CONTACT_MODAL_ID,
  UPGRADE_PLAN_MODAL_ID,
] as const
