import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { useState } from 'react'
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../../shadcn/alert-dialog.tsx'
import { Button } from '../../../shadcn/button.tsx'
import { accountActions, selectAccountAccessToken } from '../../../../state/slices/account-slice.ts'
import { logWithRollbar } from '../../../../analytics/rollbar/log-with-rollbar.ts'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { VOICE_REMOVAL_SUCCESS_MODAL_ID } from '../../modal-ids.ts'
import { sendRemovalRequest } from '../../../../transport/transport/api/removals/removals.ts'

export const DeleteVoiceAlertDialog = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dispatch = useDispatch()
  const accessToken: string = useSelector(selectAccountAccessToken)

  const deleteMutation = useMutation({
    mutationFn: () => sendRemovalRequest('voice', accessToken),
    onSuccess: async () => {
      // removing voice id from redux will trigger an onboarding redirect
      dispatch(accountActions.setHasNoVoice())
      dispatch(modalActions.openModal(VOICE_REMOVAL_SUCCESS_MODAL_ID))
    },
    onError: (error) => {
      logWithRollbar(`Failed to send delete voice request: + ${JSON.stringify(error)} ${error}`)
    },
  })

  const handleDeleteRequest = async () => {
    deleteMutation.mutate()
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={(isOpen) => setIsOpen(isOpen)}>
      <AlertDialogTrigger className='w-full rounded-md border border-red-200 bg-white px-4 py-2 text-red-500 transition-colors duration-200 hover:bg-red-600 hover:text-white'>
        Delete my voice
      </AlertDialogTrigger>
      <AlertDialogContent className='max-w-sm rounded-lg bg-white p-6 shadow-xl md:max-w-md'>
        <AlertDialogHeader className='gap-y-2'>
          <AlertDialogTitle className='text-xl font-semibold'>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className='text-sm text-gray-500'>
            This action cannot be undone. This will permanently delete your voice and related data from our servers.{' '}
            <u>After deleting your voice you will be redirected to recreate your voice.</u>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button
            variant='destructive'
            onClick={handleDeleteRequest}
            disabled={deleteMutation.isPending}
            className='w-full bg-red-500 text-white hover:bg-red-600 sm:w-auto'
          >
            {deleteMutation.isPending ? (
              <>
                <Loader2 className='mr-2 h-4 w-4 animate-spin' />
                Sending...
              </>
            ) : deleteMutation.isError ? (
              'An error occurred. Please try again.'
            ) : (
              'Confirm deletion'
            )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
