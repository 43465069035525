import { useEffect, useMemo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryResult, useQueryClient } from '@tanstack/react-query'
import { ResponseWrapper } from '@shared/frontend-and-backend/body-types/response-wrapper.types.ts'
import { GetCorrectUserPronunciationsResponse } from '@shared/frontend-and-backend/body-types/words/words.types.ts'
import { selectAccountAccessToken } from '../../../../../../state/slices/account-slice.ts'
import { useApiErrorHandler } from '../../../../../../hooks/use-api-error-handler.ts'
import { getLearnedWords } from '../../../../../../transport/transport/api/learned-words/words.ts'
import { QueryKey } from '../../../../../../transport/transport/api/query-keys.ts'
import { useLoadMoreOnScroll } from '../use-load-more-on-scroll.tsx'
import {
  useGetNumberOfLanguagesLearned,
  useNumberOfLearnedWords,
} from '../../../../../../transport/transport/api/learned-words/words-hooks.ts'
import { Button } from '../../../../../design-system/button.tsx'
import { ROUTE_PATHS } from '../../../../../../routing/route-paths.ts'
import { useLocation, useNavigate } from 'react-router-dom'
import { LearnedWordsTable } from './learned-words-table.tsx'
import { POSTHOG_EVENTS } from '../../../../../../analytics/posthog/posthog-events.ts'
import { SquaresLoader } from '../../../../../loader/squares-loader.tsx'
import { LanguageCountersPieChart } from './language-counters-pie-chart.tsx'
import { LanguageFilter, LanguageFilterValue } from './language-filter.tsx'
import { SUPPORTED_STUDY_LANGUAGES } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'

export const LearnedWordsSubTab = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const accessToken = useSelector(selectAccountAccessToken)
  const numberOfLearnedWords = useNumberOfLearnedWords()
  const languagesLearned = useGetNumberOfLanguagesLearned()
  const [languageFilter, setLanguageFilter] = useState<LanguageFilterValue>(undefined)

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
  }: UseInfiniteQueryResult<
    InfiniteData<ResponseWrapper<GetCorrectUserPronunciationsResponse>>,
    Error
  > = useInfiniteQuery({
    queryKey: [QueryKey.GET_LEARNED_WORDS, accessToken],
    queryFn: useCallback(
      async ({ pageParam }: { pageParam: string | undefined }) => {
        return await getLearnedWords(accessToken, pageParam, languageFilter)
      },
      [accessToken, languageFilter]
    ),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage.data?.nextCursor,
    refetchOnWindowFocus: 'always',
    refetchOnMount: 'always',
  })

  useEffect(() => {
    refetch()
  }, [languageFilter, refetch])

  useEffect(() => {
    POSTHOG_EVENTS.viewPage()
  }, [])

  const { resetLoading } = useLoadMoreOnScroll({
    loadMore: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage().then(() => {})
      }
    },
    hasMore: hasNextPage,
  })

  useEffect(() => {
    const refetchData = async () => {
      await queryClient.resetQueries({ queryKey: [QueryKey.GET_LEARNED_WORDS] })
      refetch().then(() => {})
    }

    if (data?.pages && data?.pages.length > 0) {
      refetchData().then()
    }
  }, [location.pathname, queryClient, refetch])

  useApiErrorHandler(error, `Error when retrieving words: ${error}`)

  useEffect(() => {
    if (!isFetchingNextPage) {
      resetLoading()
    }
  }, [isFetchingNextPage, resetLoading])

  const handleClick = () => {
    navigate(ROUTE_PATHS.DASHBOARD)
  }

  const filteredData = useMemo(() => {
    return (
      data?.pages
        .flatMap((page) => page.data?.userPronunciations || [])
        .filter((row) => languageFilter === undefined || row.language === languageFilter) || []
    )
  }, [data, languageFilter])

  const handleLanguageChange = (value: LanguageFilterValue) => {
    setLanguageFilter(value)
  }

  if (status === 'pending') {
    return (
      <div className='mt-8 flex w-full justify-center'>
        <SquaresLoader />
      </div>
    )
  }

  return (
    <div className='flex h-full w-full flex-col items-center py-4'>
      {data && numberOfLearnedWords > 0 ? (
        <div className='flex h-full w-full flex-col items-center justify-between gap-y-8 p-1 md:gap-y-32 md:p-2'>
          {languagesLearned > 1 && <LanguageCountersPieChart />}
          <div className='flex w-full flex-col gap-y-4 pb-4 md:max-w-4xl lg:max-w-6xl'>
            <div className='w-full lg:w-1/4'>
              <LanguageFilter
                label='Filter by language'
                onLanguageSelect={handleLanguageChange}
                langCodes={[undefined, ...SUPPORTED_STUDY_LANGUAGES]}
                defaultValue={undefined}
              />
            </div>
            <LearnedWordsTable data={filteredData} languageFilter={languageFilter} />
            {isFetching && (
              <div className='flex h-full w-full justify-center'>
                <SquaresLoader />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className='flex h-full w-full flex-col justify-center gap-y-4 px-2 py-4 pb-20 md:max-w-screen-xs md:gap-y-8 md:pb-40'>
          <h1 className='text-center text-4xl font-bold tracking-tighter text-stone-800'>
            You don't have any words learned yet
          </h1>
          <h2 className='text-center text-2xl font-bold tracking-tighter text-stone-800'>
            Go to exercises to learn new words
          </h2>
          <Button
            onClick={handleClick}
            className='border-white bg-gradient-to-r from-indigo-500 to-indigo-400 text-white hover:from-indigo-600 hover:to-indigo-600'
          >
            GO TO EXERCISES
          </Button>
        </div>
      )}
    </div>
  )
}
