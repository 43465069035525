import { Reducer } from 'redux'
import { AppReducer } from './app-reducer'
import { AccountState } from './slices/account-slice'
import { ModalState } from './slices/modal-slice.ts'
import { RandomExerciseState } from './slices/random-exercise-slice.ts'
import { PreferencesState } from './slices/preferences-slice.ts'

export class ReducerBuilder {
  private account?: Reducer<AccountState>
  private modal?: Reducer<ModalState>
  private preferences?: Reducer<PreferencesState>
  private randomExercise?: Reducer<RandomExerciseState>

  setAccountReducer(account: Reducer<AccountState>) {
    this.account = account
    return this
  }

  setModalReducer(modal: Reducer<ModalState>) {
    this.modal = modal
    return this
  }

  setPreferencesReducer(preferences: Reducer<PreferencesState>) {
    this.preferences = preferences
    return this
  }

  setRandomExerciseReducer(modal: Reducer<RandomExerciseState>) {
    this.randomExercise = modal
    return this
  }

  build() {
    const result: AppReducer = {}
    if (this.account) {
      result.account = this.account
    }
    if (this.modal) {
      result.modal = this.modal
    }
    if (this.randomExercise) {
      result.randomExercise = this.randomExercise
    }
    if (this.preferences) {
      result.preferences = this.preferences
    }
    return result
  }
}
