import { useEffect, useState } from 'react'

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false)

  useEffect(() => {
    const matchMedia = window.matchMedia("(pointer: coarse)")
    setIsTouchDevice(matchMedia.matches)

    const handleChange = (e: MediaQueryListEvent) => {
      setIsTouchDevice(e.matches)
    }

    matchMedia.addEventListener('change', handleChange)

    return () => {
      matchMedia.removeEventListener('change', handleChange)
    }
  }, [])

  return isTouchDevice
}

export default useIsTouchDevice
