import { BrowserRouter } from 'react-router-dom'
import { Router } from './routing/router.tsx'
import { Provider } from 'react-redux'
import { store } from './state/store'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { NavBar } from './components/navbar/navbar.tsx'
import { SilentSignInOut } from './components/auth/silent-sign-in-out.tsx'
import { UserRetrieving } from './components/auth/user-retrieving.tsx'
import { Modal } from './components/modal/modal.tsx'
import { Toaster } from 'sonner'
import { AnalyticsInitializer } from './analytics/analytics-initializer.tsx'
import { StateAndLocalStorageSynchronizer } from './components/synchronizers/state-local-storage-synchronizers/state-and-local-storage-synchronizer.tsx'
import { OnboardingRedirect } from './components/onboarding/onboarding-redirect.tsx'
import { StateAndHashSynchronizer } from './components/synchronizers/hash-synchronizer/state-and-hash-synchronizer.tsx'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      retry: 0,
    },
  },
})

export const App = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Modal />
        <AnalyticsInitializer />
        <Toaster />
        <SilentSignInOut />
        <StateAndLocalStorageSynchronizer />
        <StateAndHashSynchronizer />
        <UserRetrieving />
        <OnboardingRedirect />
        <div className='flex w-full flex-1 flex-col items-center bg-gradient-to-br from-indigo-50 to-white pt-2 font-inter text-slate-800 md:pt-4 3xl:pt-12'>
          <NavBar />
          <main className='flex w-full flex-1 flex-col items-center'>
            <Router />
          </main>
        </div>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
)
