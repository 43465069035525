import '@vidstack/react/player/styles/base.css'

import { Controls } from '@vidstack/react'

import * as Buttons from './components/buttons.tsx'
import * as Sliders from './components/sliders.tsx'
import { TimeIndicator } from './components/time-indicator.tsx'
import { useMediaQuery } from 'usehooks-ts'
import { AudioLayoutProps } from './types.ts'
import { Download } from 'lucide-react'

export const AudioLayout = ({ timeSliderRef, title, onDownload }: AudioLayoutProps) => {
  const isSmOrLarger: boolean = useMediaQuery('(min-width: 640px)')
  return (
    <>
      <Controls.Root className='inset-0 z-10 flex h-full w-full flex-col media-controls:opacity-100'>
        <Controls.Group className='flex w-full items-center sm:px-2'>
          <Sliders.Time timeSliderRef={timeSliderRef} />
        </Controls.Group>
        <Controls.Group className='flex w-full items-center justify-between px-2'>
          <div className='flex flex-1 items-center justify-start'>
            <Buttons.Play side='top' />
            <TimeIndicator />
          </div>
          <div className='flex-0 text-sm text-gray-500 md:text-lg'>{title}</div>
          <div className='flex flex-1 items-center justify-end'>
            <Buttons.Speed isSmOrLarger={isSmOrLarger} />
            {isSmOrLarger && <Buttons.Mute side='top' />}
            {isSmOrLarger ? <Sliders.Volume /> : null}
            <button onClick={onDownload} className='ml-2 text-stone-700 hover:text-stone-900 active:text-stone-900'>
              <Download className='h-6 w-6' />
            </button>
          </div>
        </Controls.Group>
      </Controls.Root>
    </>
  )
}
