import {
  preferenceActions,
  selectShouldShowIpa,
  selectShouldShowTransliteration,
} from '../../../../state/slices/preferences-slice.ts'
import { useDispatch, useSelector } from 'react-redux'
import { Languages, Settings } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from '../../../shadcn/popover.tsx'
import { selectAccountAccessToken, selectStudyLanguageOrEnglish } from '../../../../state/slices/account-slice.ts'
import { LangCode, LANGUAGES_WITH_TRANSLITERATION } from '@shared/frontend-and-landing-and-backend/constants/lang-codes'
import { modalActions } from '../../../../state/slices/modal-slice.ts'
import { TRANSLATE_TEXT_MODAL_ID } from '../../../modal/modal-ids.ts'
import { ShadcnTooltip } from '../../../tooltip.tsx'
import { useFrequencySliderPosition, useUserSettings } from '../../../../transport/transport/api/users/users-hooks.ts'
import { useUpdateSettingsMutation } from '../../../../hooks/use-update-settings-mutation.ts'
import { UserSettings } from '@shared/frontend-and-backend/body-types/user-settings/user-settings.types.ts'
import { cloneDeep } from 'lodash'
import { cn } from '../../../../utils/shadcn-utils.ts'
import { IpaToggle } from './ipa-toggle.tsx'
import { TransliterationToggle } from './transliteration-toggle.tsx'
import { useMediaQuery } from 'usehooks-ts'
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle, DrawerTrigger } from '../../../shadcn/drawer.tsx'
import { getCurrentLevel } from './cefr-level-selector-utils.ts'
import { CefrLevelSelector } from './cefr-level-selector.tsx'

export const FrequencyListExerciseControls = () => {
  const shouldShowIpa = useSelector(selectShouldShowIpa)
  const shouldShowTransliteration = useSelector(selectShouldShowTransliteration)
  const studyLanguage = useSelector(selectStudyLanguageOrEnglish)
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccountAccessToken)
  const position = useFrequencySliderPosition(studyLanguage)
  const { data: userSettings } = useUserSettings()
  const { mutate } = useUpdateSettingsMutation(accessToken, studyLanguage)
  const currentLevel = getCurrentLevel(position)

  const updateFrequencySliderPosition = (language: LangCode, position: number) => {
    if (!userSettings) {
      return
    }
    const updatedSettings: UserSettings = cloneDeep(userSettings)
    updatedSettings.preferences.exercises.frequencyList.position.byLanguage.map((lang) => {
      if (lang.language === language) {
        lang.position = position
      }
      return lang
    })
    mutate(updatedSettings)
  }

  const handlePositionChange = (newPosition: number) => {
    updateFrequencySliderPosition(studyLanguage, newPosition)
  }

  const handleIpaClick = () => {
    dispatch(preferenceActions.setShouldShowIpa(!shouldShowIpa))
  }

  const handleTransliterationClick = () => {
    dispatch(preferenceActions.setShouldShowTransliteration(!shouldShowTransliteration))
  }

  const handleLanguagesClick = () => {
    dispatch(
      modalActions.openTranslationModal({
        modalId: TRANSLATE_TEXT_MODAL_ID,
      })
    )
  }

  const isSmOrLarger = useMediaQuery('(min-width: 640px)')

  const settingsContent = (
    <>
      <CefrLevelSelector initialPosition={position} onPositionCommit={handlePositionChange} />
      <div className='space-y-4'>
        <h3 className='text-base font-semibold text-gray-800'>IPA and transliteration</h3>
        <div className='space-y-3'>
          <IpaToggle shouldShowIpa={shouldShowIpa} handleIpaClick={handleIpaClick} />
          {LANGUAGES_WITH_TRANSLITERATION.includes(studyLanguage) && (
            <TransliterationToggle
              shouldShowTransliteration={shouldShowTransliteration}
              handleTransliterationClick={handleTransliterationClick}
            />
          )}
        </div>
        <p className='text-sm text-gray-400'>
          International phonetic alphabet (IPA) and transliteration will appear above the study words
        </p>
      </div>
    </>
  )

  return (
    <div className='mt-8 flex w-full flex-row justify-center gap-x-2 md:gap-x-4'>
      <ShadcnTooltip content='Translation' side='top' sideOffset={5}>
        <div className='flex' onClick={handleLanguagesClick}>
          <div className='cursor-pointer rounded p-1.5 text-gray-700 transition-colors duration-100 hover:bg-gray-600 hover:text-white active:bg-gray-700 active:text-white'>
            <Languages className='h-5' />
          </div>
        </div>
      </ShadcnTooltip>
      {isSmOrLarger ? (
        <Popover>
          <PopoverTrigger>
            <div className='flex items-center justify-center gap-2'>
              <div className='cursor-pointer rounded p-1.5 text-gray-700 transition-colors duration-100 hover:bg-gray-600 hover:text-white active:bg-gray-700 active:text-white'>
                <Settings className='h-5' />
              </div>
              <div className='text-lg font-semibold text-slate-800'>{`Level: ${currentLevel.name}`}</div>
            </div>
          </PopoverTrigger>
          <PopoverContent className={cn('flex flex-col gap-4 bg-white p-6', 'w-[400px] max-w-[90vw]')}>
            {settingsContent}
          </PopoverContent>
        </Popover>
      ) : (
        <Drawer>
          <DrawerTrigger asChild>
            <div className='flex items-center justify-center gap-2'>
              <div className='cursor-pointer rounded p-1.5 text-gray-700 transition-colors duration-100 hover:bg-gray-600 hover:text-white active:bg-gray-700 active:text-white'>
                <Settings className='h-5' />
              </div>
              <div className='text-lg font-semibold text-slate-800'>{`Level: ${currentLevel.name}`}</div>
            </div>
          </DrawerTrigger>
          <DrawerContent className='bg-white pb-10'>
            <div className='mx-auto mt-4 h-2 w-[100px] rounded-full bg-muted' />
            <DrawerHeader>
              <DrawerTitle>Exercise Settings</DrawerTitle>
            </DrawerHeader>
            <div className='mx-auto w-11/12 px-4'>{settingsContent}</div>
          </DrawerContent>
        </Drawer>
      )}
    </div>
  )
}
