export const ALLOWED_REFERRALS: string[] = ['sound_like_a_russian', 'finnishwithheidi', 'davide', 'plapla']

export type Discounts = {
  areActive: boolean
  monthly: {
    discountAsPercentage: number
    durationLimit: number | null // for example: 3 means the discount works for 3 months, null means it works forever as long as the user is subscribed
  }
  yearly: {
    discountAsPercentage: number
    durationLimit: number | null // for example: 2 means the discount works for 2 years, null means it works forever as long as the user is subscribed
  }
  lifetime?: {
    // the field is not required because during our first campaign with sound_like_a_russian we didn't even have the lifetime plan
    discountAsPercentage: number
  }
}

export const referralToDiscounts = (referral: string): Discounts => {
  // the below values have to correspond to the coupons' ids in stripe here: https://dashboard.stripe.com/coupons
  // do not remove discounts of past campaigns neither here nor on stripe, it's great for documentation
  const discount: Discounts | undefined = {
    sound_like_a_russian: {
      areActive: false,
      monthly: {
        discountAsPercentage: 20,
        durationLimit: null,
      },
      yearly: {
        discountAsPercentage: 40,
        durationLimit: null,
      },
    },
    finnishwithheidi: {
      areActive: true,
      monthly: {
        discountAsPercentage: 20,
        durationLimit: 3,
      },
      yearly: {
        discountAsPercentage: 40,
        durationLimit: 1,
      },
      lifetime: {
        discountAsPercentage: 50,
      },
    },
    plapla: {
      areActive: true,
      monthly: {
        discountAsPercentage: 20,
        durationLimit: 3,
      },
      yearly: {
        discountAsPercentage: 40,
        durationLimit: 1,
      },
      lifetime: {
        discountAsPercentage: 50,
      },
    },
    davide: {
      areActive: false,
      monthly: {
        discountAsPercentage: 20,
        durationLimit: null,
      },
      yearly: {
        discountAsPercentage: 40,
        durationLimit: null,
      },
    },
  }[referral]
  if (!discount) {
    return {
      areActive: false,
      monthly: {
        discountAsPercentage: 0,
        durationLimit: null,
      },
      yearly: {
        discountAsPercentage: 0,
        durationLimit: null,
      },
    }
  }
  return discount
}
